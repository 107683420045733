import Cookies from 'js-cookie';
import { getCookieDomain } from '../utils/urls';
import { sha256 } from 'js-sha256';

export interface customerCookiesInformations {
  IsLogged: boolean;
  IDClientContact?: string;
  Email?: string;
  Nom?: string;
  Prenom?: string;
  TelephoneContact?: string;
  TelephoneContactPortable?: string;
  Locale: string;
}

export const setCustomerInformationsCookie = async (customerCookiesInformations: customerCookiesInformations) => {
  const { IsLogged, IDClientContact, Email, Nom, Prenom, TelephoneContact, TelephoneContactPortable } = customerCookiesInformations;
  const expirationDate = new Date();
  expirationDate.setMonth(expirationDate.getMonth() + 6);

  if (IsLogged) {
    IDClientContact && setCookie('idClientInfo', IDClientContact, expirationDate);
    Email && setCookie('emailClientInfo', Email, expirationDate);
    Nom && setCookie('lastNameClientInfo', Nom, expirationDate);
    Prenom && setCookie('firstNameClientInfo', Prenom, expirationDate);

    if (TelephoneContact || TelephoneContactPortable) {
      const phone = TelephoneContact ?? TelephoneContactPortable ?? '';
      const formatedPhoneNumber = formatPhoneNumber(phone, customerCookiesInformations.Locale);
      setCookie('phoneClientInfo', sha256(formatedPhoneNumber), expirationDate);
      setCookie('phoneClientInfoWithPrefix', sha256('+' + formatedPhoneNumber), expirationDate);
    }
  } else {
    removeCookie('idClientInfo');
    removeCookie('emailClientInfo');
    removeCookie('lastNameClientInfo');
    removeCookie('firstNameClientInfo');
    removeCookie('phoneClientInfo');
    removeCookie('phoneClientInfoWithPrefix');
  }
};

const setCookie = (name: string, value: string, expires: Date) => {
  Cookies.set(name, value, { domain: `${getCookieDomain()}`, expires: expires });
};

const removeCookie = (name: string) => {
  Cookies.remove(name, { domain: `${getCookieDomain()}` });
};

const formatPhoneNumber = (phone: string, locale: string): string => {
  const formattedNumber = phone.replace(/\s/g, '');
  const countryCode = locale === 'fr_FR' ? '33' : '34';

  return formattedNumber.startsWith('0') ? countryCode + formattedNumber.substring(1) : formattedNumber;
};
