import { gql } from '@apollo/client';

export const CART_PRICE_FRAGMENT = gql`
  fragment CartPrice on Price {
    value {
      centAmount
      currencyCode
    }
  }
`;
