import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { FunnelExit } from '../components/menu/components/FunnelExit';

interface Props {
  href: string;
  centered?: boolean;
  isFunnel?: boolean;
}

export const Logo: FunctionComponent<Props> = ({ children, href, centered, isFunnel }) => {
  const [isToolboxDisplayed, setIsToolboxDisplayed] = useState(false);
  const options = !isFunnel ? { href: href } : {};

  const handleLogoClick = () => {
    if (!isFunnel) {
      return;
    }
    setIsToolboxDisplayed(true);
  };

  const closeToolbox = () => setIsToolboxDisplayed(false);

  return (
    <>
      <StyledLogoContainer isFunnel={isFunnel} centered={centered}>
        <StyledLogoLink aria-label="Exaprint homepage" {...options} onClick={handleLogoClick}>
          <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 241.8 54.7">
            <g>
              <path
                className="st01"
                d="M14.6,15C6.2,15,0,21.4,0,30.1c0,8.4,6.2,15,15.1,15c9.6,0,13.3-6.4,13.7-8.9h-5.2c-0.5,0.9-2.8,4-8.5,4
              c-5.6,0-9.5-4.2-9.7-9.5h23.8C29.2,21.3,23,15,14.6,15 M14.6,19.6c4.4,0,7.4,2.4,8.8,6.7H5.7C6.8,22.1,9.9,19.6,14.6,19.6
               M91.3,44.4h5.3V15.7h-5.3v4.8c-0.8-1.6-3.8-5.5-10.2-5.5c-7.5,0-14,5.7-14,15c0,9.5,6.4,15,14,15c6.6,0,9.3-4.2,10.2-5.6
              L91.3,44.4z M91.5,30.1c0,5.7-3.7,10.2-9.3,10.2c-5.8,0-9.5-4.4-9.5-10.2s3.7-10.2,9.5-10.2C87.7,19.9,91.5,24.3,91.5,30.1
               M110.7,39.8c0.6,1.4,4.2,5.3,10.4,5.3c7.7,0,14.1-5.5,14.1-15s-6.4-15-14.1-15c-6.4,0-9.8,4.2-10.4,5.6v-4.9h-5.2v39h5.2V39.8z
               M110.6,30.1c0-5.7,3.8-10.2,9.6-10.2c5.8,0,9.6,4.4,9.6,10.2s-3.8,10.2-9.6,10.2S110.6,35.8,110.6,30.1 M169.9,44.4h5.3V15.7h-5.3
              L169.9,44.4z M190,28.9c0-5.2,3.4-8.9,8.5-8.9c4.9,0,8.2,3.5,8.2,8.9v15.5h5.3V27.9c0-7.4-5-12.8-12.2-12.8c-4.1,0-7.9,2.3-9.7,5.9
              v-5.3h-5.3v28.7h5.3L190,28.9z"
              />
              <rect x="36.9" y="14.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.4021 34.0735)" className="st1" width="5" height="13" />
              <rect x="53.8" y="31.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.3982 50.9579)" className="st1" width="5" height="13" />
              <rect x="49.8" y="18.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 1.5399 46.0129)" className="st1" width="13" height="5" />
              <rect x="32.9" y="35.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -15.3432 39.0198)" className="st1" width="13" height="5" />
              <rect x="148.8" y="15.7" className="st2" width="14.7" height="5.1" />
              <rect x="141.6" y="23.4" className="st2" width="5.1" height="14.7" />
              <path className="st01" d="M219.7,23v11.9c0,6.4,3.4,9.6,10,9.6h11.8v-4.9h-10.6c-4.6,0-5.9-1.7-5.9-5.9V23H219.7z" />
              <rect x="219.7" className="st3" width="5.1" height="13.4" />
              <rect x="227.5" y="15.8" className="st3" width="14.4" height="5.1" />
              <rect x="169.8" y="0" className="st01" width="5.5" height="10.2" />
            </g>
          </svg>
        </StyledLogoLink>
        {children}
      </StyledLogoContainer>
      {isToolboxDisplayed && isFunnel && <FunnelExit href={href} onClose={closeToolbox} />}
    </>
  );
};

interface ContainerProps {
  centered?: boolean;
  isFunnel?: boolean;
}

// top right bottom left
const StyledLogoContainer = styled.div<ContainerProps>`
  ${props => props.centered && `margin: 20px auto;`}
  ${props => (props.isFunnel ? 'margin-left: 25px;' : '')}
`;

const StyledLogoLink = styled.a`
  display: block;

  rect {
    transition: fill 300ms linear;
  }

  .st01 {
    fill: #323232;
  }

  .st1 {
    fill: #54c3ef;
  }

  .st2 {
    fill: #e84179;
  }

  .st3 {
    fill: #fcc443;
  }

  &:hover .st1,
  &:hover .st2,
  &:hover .st3 {
    fill: #323232;
  }

  & svg {
    @media (max-width: 768px) {
      width: 145px;
    }
    width: 180px;
  }
`;
