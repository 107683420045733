import { CONTENTBOX_PATCH } from '../../common/constants';
import { gtmPushEvent, GTMPushEvent } from '../../common/gtm';
import { SimpleContent } from '../../generated/contentful-generated-types';
import { PatchAndDiscount } from '../../utils/contentBox';
import { showForUserMultiple } from '../../utils/contentful';

// Key mapping of specials menu items
export const ROOT_PRODUCTS_KEY = 'rootProducts';
export const ROOT_PROMO_KEY = 'rootPromo';
export const ROOT_THEMATICS_KEY = 'rootThematics';
export const ROOT_THEMATIC_FILTERS_KEY = 'rootThematicAxis';
export const ROOT_GOODIES_KEY = 'goodiesMainMenu';

export interface MenuItem {
  label: string;
  key?: string;
  children?: MenuItem[];
  href?: string;
  img?: string;
  patch?: string;
  discountPercentage?: number;
  showOnlyFor?: string;
  showOnlyForMultiple?: string[];
  external?: boolean;
  targetBlank?: boolean;
  icon?: string;
  thematicFilters?: string[];
  showCampaignOnlyFor?: string;
  showCampaignOnlyForMultiple?: string[];
  patchAndDiscount?: PatchAndDiscount[];
  hideChildren?: boolean;
  menuHighlight?: SimpleContent;
}

export interface SelectableMenuItem extends MenuItem {
  selected?: boolean;
}

export const cloneMenuWithFiltering = (filterFunction: (item: MenuItem) => boolean, items?: MenuItem[]): MenuItem[] | undefined => {
  return items
    ?.map(item => ({ ...item, children: cloneMenuWithFiltering(filterFunction, item.children) } as MenuItem))
    .filter(filterFunction)
    .filter((item): item is MenuItem => item !== undefined);
};

export const generatePromoSubMenu = (
  rootMenuItems: MenuItem[],
  currentUserReseller: boolean,
  currentUserLoggedIn: boolean,
  currentUserRegisteredNonBuyer: boolean
) => {
  //Promo sub-memu is the same as Products but only with items in Promo
  const productsSubMenu = rootMenuItems.find(item => item.key === ROOT_PRODUCTS_KEY);

  if (productsSubMenu && productsSubMenu.children) {
    return cloneMenuWithFiltering(
      (item: MenuItem) =>
        item.patchAndDiscount?.some(
          item =>
            (item.patch === CONTENTBOX_PATCH.PROMO || item.patch === CONTENTBOX_PATCH.FLASH) &&
            item?.discountPercentage &&
            item?.discountPercentage > 0 &&
            item?.discountPercentage < 100 &&
            showForUserMultiple(item.showCampaignOnlyForMultiple, currentUserReseller, currentUserLoggedIn, currentUserRegisteredNonBuyer)
        ) ||
        (item.children != undefined && item.children.length > 0),
      productsSubMenu.children
    );
  }
  // In case PromoSubMenu could not be generated we return empty children
  return [];
};

export enum SortType {
  natural_sorting,
  alphabetical_asc,
  alphabetical_desc
}

export const sortItems = (sorting: SortType, items: MenuItem[]) => {
  switch (sorting) {
    case SortType.alphabetical_asc:
      return [...items].sort((a: MenuItem, b: MenuItem) => a.label.localeCompare(b.label));
    case SortType.alphabetical_desc:
      return [...items].sort((a: MenuItem, b: MenuItem) => a.label.localeCompare(b.label) * -1);
  }
  return items;
};

export const countLeaf = (item: MenuItem): number => {
  // It this is a leaf, we count 1
  if (!item.children || item.children.length == 0) {
    return 1;
  }

  // Otherwise we browse children to count leaves
  return item.children.reduce((acc, child) => {
    return acc + countLeaf(child);
  }, 0);
};

export const sortThematics = (rootMenuItems: MenuItem[]) =>
  rootMenuItems.find(item => item.key === ROOT_THEMATICS_KEY)?.children?.sort((a, b) => (a.label < b.label ? -1 : 1));

export const isSameMenuPath = (path1: MenuItem[], path2: MenuItem[]) => {
  const flatPath1 = path1.map(item => item.label).join('|');
  const flatPath2 = path2.map(item => item.label).join('|');
  return flatPath1 === flatPath2;
};

type SendClickOnMenuEvent = ({ index, label }: { index: number; label: string }) => void;

export const sendClickOnMenuEvent_ =
  (gtmPushEvent: GTMPushEvent): SendClickOnMenuEvent =>
  ({ index, label }) => {
    gtmPushEvent({
      event: 'menu',
      category: 'Clicks Interaction',
      action: `menu_level${index + 1}`,
      label
    });
  };

export const sendClickOnMenuEvent: SendClickOnMenuEvent = sendClickOnMenuEvent_(gtmPushEvent);

export const DEFAULT_PANEL_MENU_INDEX = 0;
