import React, { Component, ChangeEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components';

const search = require('../../assets/icons/search.svg');

interface SearchComponenttState {
  searchInput: string;
}

class SearchComponent extends Component<WithTranslation, SearchComponenttState> {
  constructor(props: WithTranslation) {
    super(props);
    this.state = { searchInput: '' };
  }

  private readonly updateInputValue = (evt: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchInput: evt.target.value
    });
  };

  public render() {
    const t = this.props.t;
    return (
      <SearchContainer>
        <SearchForm method="GET" action="false">
          <SearchInput
            type="text"
            placeholder={t('gatsby.search.searchproduct')}
            value={this.state.searchInput}
            onChange={this.updateInputValue}
            name="plainTextSearch"
          />
          <SearchSubmit type="submit">
            <SearchIcon />
          </SearchSubmit>
        </SearchForm>
      </SearchContainer>
    );
  }
}

const SearchContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100% @media (max-width: 1024px) {
    width: 100%;
  }
`;

const SearchForm = styled.form`
  margin-bottom: 0;
`;

const SearchInput = styled.input`
  border-radius: 3px 0 0 3px;
  border: 1px solid #d6d6d6;
  padding: 0 0 0 10px;
  font-size: 14px;
  line-height: 25px;
  height: 27px;
  margin: auto;
  width: 230px;
  @media screen and (min-width: 769px) and (max-width: 1300px) {
    width: 160px;
  }
`;
const SearchSubmit = styled.button`
  border-radius: 0 3px 3px 0;
  color: #fff;
  background-color: #e2004e;
  border: none;
  border-right: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
  vertical-align: bottom;
  outline: none;
  height: 27px;
  transition: 300ms;
  &:hover {
    opacity: 0.5;
  }
`;

const SearchIcon = styled.span`
  display: flex;
  text-indent: -9999px;
  width: 17px;
  height: 25px;
  background: url(${search}) no-repeat;
  background-size: 15px 15px;
  cursor: pointer;
  background-position: center;
`;

const Search = withTranslation('translations')(SearchComponent);

export default Search;
