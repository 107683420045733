import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { SimpleContent, Maybe } from '../generated/contentful-generated-types';
import { useRichText } from '../hooks/richtext';

export interface TopBannerProps {
  topBanners?: Maybe<SimpleContent>[] | undefined;
}

export const HeaderPromoBar: FunctionComponent<TopBannerProps> = ({ topBanners }) => {
  const [currentPromoIndex, setCurrentPromoIndex] = useState<number>(0);
  const promosTexts = topBanners?.map(item => (item?.description ? useRichText(item.description) : '')) || undefined;

  useEffect(() => {
    if (promosTexts && promosTexts?.length > 1) {
      const interval = setInterval(handleIndexChange, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  const handleIndexChange = () => {
    setCurrentPromoIndex(prev => {
      if (promosTexts && prev >= promosTexts.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const handleClassNames = (slideIndex = 0) => (slideIndex === currentPromoIndex ? 'visible' : currentPromoIndex > slideIndex ? 'down' : '');
  return (
    <>
      {!!promosTexts && promosTexts.length > 0 && (
        <StyledHeaderPromoBar>
          {promosTexts?.map((promoText, index) => (
            <span key={index} className={handleClassNames(index)}>
              {promoText}
            </span>
          ))}
        </StyledHeaderPromoBar>
      )}
    </>
  );
};

const marginTop = '60px';

const StyledHeaderPromoBar = styled.div`
  background: #000000;
  text-align: center;
  padding: 14px 0;
  color: #fff;
  text-shadow: 0 0 3px rgba(150, 150, 150, 1);
  min-height: 44px;
  position: relative;

  strong {
    color: #fff;
  }

  span {
    font-size: 0.8em;
  }

  @media (max-width: 1024px) {
    // display: none;
  }

  span {
    width: 100%;
    position: absolute;
    top: -${marginTop};
    left: 50%;
    transform: translateX(-50%);
    &.visible {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.down {
      top: ${marginTop};
    }

    transition: all 0.5s ease-in-out;

    p {
      line-height: 1.5;
      font-size: 14px;
      color: #fff;
    }
  }
`;
