import { navigate } from 'gatsby';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MOBILE_SIZE } from '../common/constants';
import { useAlgoliaSuggestions } from '../hooks/useAlgoliaSuggestions';
import useOutsideClick from '../hooks/useOutsideClick';
import { useUrlSearchParams } from '../hooks/useUrlSearchParams';
import { useIsWindowWidthBelow } from '../hooks/useWindowSize';
import { initSearchInsights } from '../utils/algolia/searchInsights';
import { sendAlgoliaConvert } from '../utils/algolia/analytics';

const search = require('../../assets/icons/search3.svg');
const search_bg = require('../../assets/icons/search_bg.svg');

interface Props {
  display?: boolean;
}

export const NewSearchBox: FunctionComponent<Props> = ({ display = true }) => {
  const { t } = useTranslation('sharedComponents');
  const [searchTerms, setSearchTerms] = useState<string>('');
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const urlParams = useUrlSearchParams();

  /* 
    The reason searchInsights is instanciated here, is that the newSearchBox component
    is available on all pages.
    This also includes AMS pages, since this components is part of the SharedComponents bundle.
  */
  useEffect(() => {
    initSearchInsights();
    if (process.env.SHARED_COMPONENT_MODE) {
      (global as any).sendAlgoliaConvert = sendAlgoliaConvert;
    }
  }, []);

  useEffect(() => {
    setSearchTerms(urlParams || '');
  }, [urlParams]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerms(e.target.value);
    setShowSuggestions(true);
  };
  const escapeQuoteCharacter = (str: string) => {
    return str.replace(/\'/g, '%27');
  };
  const navigateOrRedirect = (input: string) => {
    const trimSearchTerms = input.trim();
    const href = trimSearchTerms.length === 0 ? '/' : '/apps/search?searchTerms=' + escapeQuoteCharacter(encodeURIComponent(trimSearchTerms));
    if (process.env.SHARED_COMPONENT_MODE !== undefined) {
      window.location.href = href;
    } else {
      navigate(href);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (selectedSuggestionIndex >= 0 && selectedSuggestionIndex < suggestions.length) {
        e.preventDefault();
        onSuggestionClick(suggestions[selectedSuggestionIndex].query);
      } else {
        setShowSuggestions(false);
        navigateOrRedirect(searchTerms);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      const nextIndex = selectedSuggestionIndex + 1;
      if (nextIndex < suggestions.length) {
        setSelectedSuggestionIndex(nextIndex);
      } else {
        setSelectedSuggestionIndex(suggestions.length - 1);
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prevIndex = selectedSuggestionIndex - 1;
      if (prevIndex >= -1) {
        setSelectedSuggestionIndex(prevIndex);
      } else {
        setSelectedSuggestionIndex(suggestions.length - 1);
      }
    }
  };

  const onSuggestionClick = (suggestion: string) => {
    setSearchTerms(suggestion);
    setShowSuggestions(false);
    setFocus(false);
    navigateOrRedirect(suggestion);
  };

  const onSearchBtn = () => {
    navigateOrRedirect(searchTerms);
    setShowSuggestions(false);
  };

  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);
  const maxSuggestionsDisplay = isMobile ? 6 : 8;

  const suggestions = useAlgoliaSuggestions({ searchTerms, hitsPerPage: maxSuggestionsDisplay, hasFocus: focus });

  const suggestionRef = useRef<HTMLDivElement>(null);

  const onInputClick = () => {
    setShowSuggestions(true);
    setFocus(true);
  };

  useOutsideClick(
    suggestionRef,
    () => {
      setShowSuggestions(false);
      setFocus(false);
    },
    false
  );

  return (
    <SearchBoxContainer ref={suggestionRef}>
      <StyledSearchBar mobileDisplay={display}>
        <StyledSearchInput
          onClick={onInputClick}
          data-testid="search-input"
          value={searchTerms}
          type="text"
          placeholder={t('shared.search.input')}
          onKeyDown={onKeyDown}
          onChange={onChange}
          tabIndex={0}
          aria-label="Search"
        ></StyledSearchInput>
        <StyledIconContainer>
          {<StyledCustomSearchIcon onClick={onSearchBtn} alt="" src={searchTerms ? search_bg : search} searchTerms={searchTerms} />}
        </StyledIconContainer>
        {searchTerms.length > 0 && <StyledCustomClearSearch onClick={() => setSearchTerms('')}>{t('shared.search.clearInput')}</StyledCustomClearSearch>}
        {showSuggestions && searchTerms.trim().length > 0 && suggestions.length > 0 && (
          <StyledSuggestionContainer>
            <StyledSuggestionsDropdown mobileDisplay={display}>
              {suggestions.map((item, index) => (
                <StyledSuggestionsResults
                  key={`suggestion-result-${index}`}
                  onClick={() => onSuggestionClick(item.query)}
                  className={selectedSuggestionIndex === index ? 'selected' : ''}
                  dangerouslySetInnerHTML={{ __html: item._highlightResult.query?.value.replace(/ /g, '\u00a0') }}
                ></StyledSuggestionsResults>
              ))}
            </StyledSuggestionsDropdown>
          </StyledSuggestionContainer>
        )}
      </StyledSearchBar>
    </SearchBoxContainer>
  );
};

const StyledSuggestionsResults = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;

  &.selected {
    background-color: #f1f1f1;
  }

  & em {
    font-weight: normal;
    font-style: normal;
  }
`;

const StyledSuggestionContainer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledSuggestionsDropdown = styled.div<SearchBarProps>`
  position: absolute;
  z-index: 110;
  margin: ${props => (props.mobileDisplay ? ' 0 ' : '10px 10px')};
  inset: 50px 0px 0px;
  height: min-content;
  background-color: #f1f1f1;
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 2px 2px 10px rgba(50, 50, 50, 0.2);
  border-radius: 12px;
`;

const SearchBoxContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

interface SearchBarProps {
  mobileDisplay?: boolean;
}

const StyledCustomClearSearch = styled.span`
  color: #ff3d77;
  cursor: pointer;
  position: absolute;
  right: 50px;
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledCustomSearchIcon = styled.img`
  height: ${({ searchTerms }) => (searchTerms ? '47px' : '35px')};
  width: ${({ searchTerms }) => (searchTerms ? '45px' : '20px')};
  position: absolute;
  right: ${({ searchTerms }) => (searchTerms ? '-1px' : '12px')};
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 50%;
`;

const StyledSearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #333333;
  font-size: 16px;
  font-weight: 500;

  &:focus {
    outline: none;
  }

  &:not(:placeholder-shown) {
    visibility: visible;
  }
`;

const StyledSearchBar = styled.div<SearchBarProps>`
  height: 48px;
  margin: ${props => (props.mobileDisplay ? '0 16px' : '5px 5px 5px 28px')};
  color: #323232;
  display: flex;
  border-radius: 30px;
  align-items: center;
  padding: 0px 16px;
  gap: 16px;
  justify-content: space-between;
  background-color: #f1f1f1;
  position: relative;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #dddddd;
  }

  &:focus-within {
    border: 1px solid #989898;
  }
`;
