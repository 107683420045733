import { useState, useEffect } from 'react';

// Hook to detect if the component is mounted so we are not running in SSR mode
// Used to prevent gatsby react rehydration issue
// More infos here : https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/

export const useIsMounted = () => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return isMounted;
};
