import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const home = require('../../assets/icons/home.svg');
const call = require('../../assets/icons/phone.svg');
const contact = require('../../assets/icons/contact.svg');
import { LogoutButton } from '../login/LogoutButton';
import { MiniCart } from '../cart/MiniCart';
import { MenuLink } from './MenuLink';
import { QueryStatus } from '../common/components/QueryStatus';
import { useTranslation } from 'react-i18next';
import { PrintboxLoginDropdown } from '../login/PrintboxLoginDropdown';
import { useGetLogin } from '../hooks/useLogin';

interface Props {
  cartUrl: string;
  baseUrl: string;
}

export const PrintboxTopNavigation: FunctionComponent<Props> = props => {
  const { children, baseUrl } = props;
  const { t } = useTranslation('translations');
  const { error, loading, data } = useGetLogin();

  let leftMenu;
  let rightMenu;
  if (!loading) {
    if (data && data.login) {
      const firstName = data.login.firstName;
      const lastName = data.login.lastName;

      leftMenu = (
        <MenuDropDownContainer className="navbar-item has-dropdown is-hoverable">
          <a className="navbar-link">
            {firstName} {lastName}
          </a>
          <MenuDropDown className="navbar-dropdown">
            <a href={`${baseUrl}${t('front.navigation.my-account-path')}`} className="navbar-item">
              {t('front.navigation.my-account')}
            </a>
            <a href={`${baseUrl}${t('front.navigation.delivery-adresses-path')}`} className="navbar-item">
              {t('front.navigation.delivery-adresses')}
            </a>
            <hr className="dropdown-divider" />
            <a href={`${baseUrl}${t('front.navigation.follow-order-path')}`} className="navbar-item">
              {t('front.navigation.follow-order')}
            </a>
            <a href={`${baseUrl}${t('front.navigation.invoices-path')}`} className="navbar-item">
              {t('front.navigation.invoices')}
            </a>
            <hr className="dropdown-divider" />
            <a href={`${baseUrl}${t('front.navigation.exaclub-path')}`} className="navbar-item">
              {t('front.navigation.exaclub')}
            </a>
            <a href={`${baseUrl}${t('front.navigation.faq-path')}`} className="navbar-item">
              {t('front.navigation.faq')}
            </a>
            <LogoutButton />
          </MenuDropDown>
        </MenuDropDownContainer>
      );

      rightMenu = (
        <MenuRight className="navbar-end connected-navbar">
          <MenuItem>
            <MenuLink href="/">
              <MenuHomeIcon />
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink href={`${baseUrl}${t('front.navigation.catalog-path')}`}>{t('front.navigation.catalog')}</MenuLink>
          </MenuItem>
          <MenuItem className="menu-quotation">
            <MenuLink href={`${baseUrl}${t('front.navigation.quotation-path')}`}>{t('front.navigation.quotation')}</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink href={`${baseUrl}${t('front.navigation.follow-order-path')}`}>{t('front.navigation.tracking')}</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink href={`${baseUrl}${t('front.navigation.invoices-path')}`}>{t('front.navigation.management')}</MenuLink>
          </MenuItem>
          <MiniCart />
        </MenuRight>
      );
    } else {
      rightMenu = (
        <MenuRight className="navbar-end">
          <MenuItem>
            <MenuLink href={`${baseUrl}${t('front.navigation.register-path')}`}>{t('front.navigation.register')}</MenuLink>
          </MenuItem>
          <MenuItem>
            <PrintboxLoginDropdown />
          </MenuItem>
        </MenuRight>
      );
    }
  }

  return (
    <Nav className="navbar" id="top-navigation">
      {error ? (
        <QueryStatus error={error} />
      ) : (
        <NavContainer className="navbar-menu container is-widescreen is-hidden-touch">
          <MenuLeft className="navbar-start">
            {leftMenu}
            <MenuItem className="round contact">
              <MenuLink href={`mailto:${t('front.navigation.contact-mailto')}`}>
                <MenuContactIcon />
              </MenuLink>
            </MenuItem>
            <MenuItem className="round call navbar-item has-dropdown is-hoverable">
              <MenuLink href="#">
                <MenuCallIcon />
              </MenuLink>
              <div className="navbar-dropdown">
                <div>
                  <span>{t('front.navigation.phone-label')}</span>
                  <p>{t('front.navigation.phone-number')}</p>
                  <span>{t('front.navigation.schedule-label')}</span>
                  <p>{t('front.navigation.schedule-text')}</p>
                </div>
              </div>
            </MenuItem>
          </MenuLeft>
          {children}
          {rightMenu}
        </NavContainer>
      )}
    </Nav>
  );
};

const Nav = styled.nav`
  color: #ffffff;
  &&& {
    background: #222222;
    @media screen and (min-width: 941px) {
      min-height: 2.5rem;
      height: 2.5rem;
    }
    @media screen and (max-width: 940px) {
      height: auto;
    }
    & ul {
      margin-top: 0;
      @media screen and (max-width: 940px) {
        position: relative;
      }
    }
  }
`;

const NavContainer = styled.div`
  &&& {
    @media screen and (min-width: 941px) {
      min-height: 2.5rem;
      height: 2.5rem;
    }
    @media screen and (max-width: 940px) {
      height: auto;
    }
    margin: 0 auto;
    flex-grow: 0;
    align-items: center;
    background: #222222;
    box-shadow: none;
    display: flex;
  }
`;

const MenuLeft = styled.ul`
  &&& {
    @media screen and (min-width: 375px) {
      padding: 0 0 0 20px;
      justify-content: flex-start;
      margin-right: 0;
    }
    position: absolute;
    margin-left: auto;
    left: 0;
    top: 0;
    height: 40px;
    line-height: 40px;
  }
`;

const MenuRight = styled.ul`
  &&& {
    @media screen and (min-width: 375px) {
      justify-content: flex-end;
      margin-left: 0;
    }
    position: absolute;
    margin-right: auto;
    right: 0;
    top: 0;
    height: 40px;
    line-height: 40px;
  }
`;

const MenuItem = styled.li`
  font-size: 0.8rem;
  float: left;
  margin: 0 0 0 15px;
  &:first-child {
    margin-left: 0;
  }
  &.round {
    display: block;
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 8px;
    border-radius: 50%;
    background: gray;
    color: white;
  }
  &.round a {
    display: block;
    width: 25px;
    height: 25px;
    padding: 6px;
  }
  &.round:hover {
    background: #d8235c;
  }
  &.round.call .navbar-dropdown {
    color: #333333;
    width: 490px;
    padding: 0 16px 16px;
    margin-top: 7px;
  }
  &.round.call .navbar-dropdown span {
    color: #d8235c;
    text-transform: uppercase;
    padding-top: 16px;
    display: block;
  }
`;

const MenuHomeIcon = styled.span`
  display: block;
  text-indent: -9999px;
  width: 17px;
  height: 17px;
  background: url(${home});
  background-size: 17px 17px;
  margin-top: 11px;
  &:hover {
    opacity: 0.5;
  }
`;

const MenuContactIcon = styled.span`
  display: block;
  text-indent: -9999px;
  width: 12px;
  height: 12px;
  background: url(${contact});
  background-size: 12px 12px;
  margin-top: 0;
`;
const MenuCallIcon = styled.span`
  display: block;
  text-indent: -9999px;
  width: 12px;
  height: 12px;
  background: url(${call});
  background-size: 12px 12px;
  margin-top: 1px;
`;

const MenuDropDownContainer = styled.div`
  &:hover a.navbar-link {
    color: #555555;
  }
  & a.navbar-link {
    color: #ffffff;
    font-size: 0.8rem;
    padding-right: 0.75rem;
  }
  & a.navbar-link:after {
    display: none;
  }
  & a.navbar-link:hover {
    color: #555555;
    background-color: #eeeeee;
    border-color: #eeeeee;
  }
`;
const MenuDropDown = styled.div`
  &&& {
    & a.navbar-item {
      padding: 2px 1rem;
    }
  }
  & a:hover {
    text-decoration: underline;
  }
  & hr {
    height: 0;
    margin: 10px 1rem;
    border-bottom: 1px dashed #cccccc;
  }
`;
