export type MenuEntry = ExternalLink | CategoryPage | Menu;

interface GenericEntry<T extends 'ExternalLink' | 'CategoryPage' | 'Menu'> {
  typename?: string;
  kind?: T;
  id: string;
  publishedAt?: string;
  title: string;
  slug: string;
  navigationContent?: any;
  seoMetaTags?: SearchEngineOptimization | null;
  showCampaignOnlyFor?: string;
  showCampaignOnlyForMultiple?: string[];
}

export interface ExternalLink extends GenericEntry<'ExternalLink'> {
  url: string;
}

export interface CategoryPage extends GenericEntry<'CategoryPage'> {
  abcCategoryKey: string[];
}

export interface Menu extends GenericEntry<'Menu'> {
  items: MenuEntry[];
}

export interface SearchEngineOptimization {
  metaTitle?: string | null;
  metaDescription?: string | null;
  metaRobots?: string[] | null;
}

export function isMenuEntry(entry: MenuEntry): entry is MenuEntry {
  return 'kind' in entry;
}

export function isMenu(entry: MenuEntry): entry is Menu {
  return entry.kind === 'Menu';
}

export function isExternalLink(entry: MenuEntry): entry is ExternalLink {
  return entry.kind === 'ExternalLink';
}

export function isPPAGCategoryPage(entry: MenuEntry): entry is CategoryPage {
  return entry.kind === 'CategoryPage';
}
