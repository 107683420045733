import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { PrintboxLoginForm } from './PrintboxLoginForm';
import { TopArrow } from '../common/components/TopArrow';

interface Props {}
export const PrintboxLoginDropdown: FunctionComponent<Props> = () => {
  return (
    <Dropdown className={`dropdown`}>
      <DropdownMenu className="dropdown-menu" id="dropdown-menu" role="menu">
        <TopArrow />
        <PrintboxLoginForm borderWidth={'4px'} />
      </DropdownMenu>
    </Dropdown>
  );
};

const Dropdown = styled.div`
  &.dropdown {
    @media (max-width: 1024px) {
      top: 60px;
      position: absolute;
      width: 360px;
      right: 0;
    }
  }
`;

const DropdownMenu = styled.div`
  &.dropdown-menu {
    z-index: 1000;
    left: auto;
    right: 0;
    min-width: 25rem;
    padding: 16px 16px 0 16px;
    background-color: #eee;
    margin-top: 16px;
    box-shadow: 0px 0px 0px 1px #eeeeee;

    @media (max-width: 1024px) {
      left: 0;
    }
  }
  form > .dropdown-content {
    border: 4px solid #d9d9d9;
  }
`;
