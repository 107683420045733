import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { LoginForm } from './ExaprintLoginForm';
import { TopArrow } from '../common/components/TopArrow';
import { useLoginForm } from '../hooks/useLoginForm';
import { TopContainer } from '../navigation/UserAction';
import closeImg from '../../assets/icons/close.svg';
import { useIsWindowWidthBelow } from '../hooks/useWindowSize';
import { MOBILE_SIZE } from '../common/constants';

export const LoginDropdown: FunctionComponent = () => {
  const loginFormContext = useLoginForm();
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);

  return (
    <>
      <DropdownMenu id="dropdown-menu" role="menu" isActive={loginFormContext?.isVisible}>
        {isMobile && (
          <TopContainer>
            <img src={closeImg} alt="close" />
          </TopContainer>
        )}
        <StyledTopArrow feature="inknpaper" />
        <LoginForm feature="inknpaper" />
      </DropdownMenu>
      {isMobile && <DropDownMenuBlurWrapper isActive={loginFormContext?.isVisible} />}
    </>
  );
};

const DropdownMenu = styled.div<{ isActive: boolean }>`
  display: ${props => (props.isActive ? 'block' : 'none')};
  z-index: 1000;
  min-width: 20rem;
  padding: 0;
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  position: absolute;

  @media (min-width: 1025px) {
    left: calc(50% - 275px);
    top: 40px;
  }

  @media (max-width: 1024px) {
    display: block;
    position: fixed;
    top: 0;
    height: 200vh;
    transition: right 0.8s ease;
    right: ${props => (props.isActive ? '0' : '-400px')};
  }
  form > .dropdown-content {
    border: 0px solid #d9d9d9;
  }
`;

const DropDownMenuBlurWrapper = styled.div<{ isActive: boolean }>`
  display: none;
  ${({ isActive }) =>
    isActive &&
    `
    position: fixed;
    backdrop-filter: blur(3px);
    inset: 0px;
    display: block;
  `}
`;

const StyledTopArrow = styled(TopArrow)`
  left: 118px;
`;
