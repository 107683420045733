import ApolloClient from '@apollo/client';
import { GetLogin, ProductInfo } from './graphql/types';
import { GET_LOGIN } from '../hooks/useLogin';

type PageType = 'product page' | 'home' | 'other';
type DeviceType = 'mobile' | 'tablet' | 'desktop';
type SearchType = 'search' | 'search result' | 'search suggestion' | 'quick result';

export type GlobalVariables = PageVariables & ProductVariables & DeviceType;
export type GTMEvent =
  | LoginEvent
  | SearchEvent
  | SearchResultsEvent
  | ProductAddEventGA4
  | ProductRemoveEventGA4
  | ProductViewItemEventGA4
  | ProductRemoveEventGA4
  | ProductViewCartEventGA4
  | QuoteEvent
  | HPClickEvent
  | ClickMenuDegreeEvent;
export type GTMPushable = GTMEvent | CustomerVariables | GlobalVariables;

export interface PageVariables {
  pageType?: PageType;
  deviceType?: DeviceType;
}

export interface ProductVariables {
  productName?: string;
}

export interface CustomerVariables {
  userId?: string; // IDClientContact from clientContact
  dateInscription?: string; // YYYY-MM-DD format
  raisonSociale?: string;
  clientType?: string;
  clientContact?: {
    IDClient: string;
    IDClientContact: string;
    IDSegment: string;
    CodeClient: string;
    IDPortefeuille: string;
    RaisonSociale: string;
    Nom: string;
    Prenom: string;
    Email: string;
    Fonction: { id?: string; libelle: string };
    DateInscription: string;
    InformationsSaisies: boolean;
    Siret: string;
    NumeroTVA: string;
    EstEligiblePrintyshop: false;
    StatutPrintyshop: number;
    NbCommandes: string;
    Dormeur: string;
    Locale: string;
    IDSociete: string;
  };
}
/**
 * Home page tracking event
 */
export interface HPClickEvent {
  event: 'HP_Click';
  category: 'Clicks Interaction';
  action: 'HP top' | 'Selection du moment';
  label: string; // Position in the slide or selection + title
}

export interface LoginEvent {
  event: 'account login';
}

export interface SearchEvent {
  event: 'search';
  searchType: SearchType;
  searchTerm: string; //Should contain the search term in the search box
}

export interface SearchResultsEvent {
  event: 'search result';
  searchResults: number;
  searchTerm: string; //Should contain the search term in the search box
}

export interface ProductAddEventGA4 {
  event: 'add_to_cart';
  ecommerce: {
    currency: string;
    value: number;
    items: ProductDetailGA4[];
  };
}

export interface ProductRemoveEventGA4 {
  event: 'remove_from_cart';
  ecommerce: {
    currency: string;
    value: number;
    items: ProductDetailGA4[];
  };
}

export interface ProductViewItemEventGA4 {
  event: 'view_item';
  ecommerce: {
    currency: string;
    value: number;
    items: ProductDetailGA4[];
  };
}

export interface ProductViewCartEventGA4 {
  event: 'view_cart';
  ecommerce: {
    currency: string;
    value: number;
    items: ProductDetailGA4[];
  };
}

type StatusQuoteEvent = 'start' | 'complete' | 'error';
export interface QuoteEvent {
  event: 'quote';
  status: StatusQuoteEvent;
  productName: string;
}

export type ClickMenuDegreeEvent = Readonly<{
  event: 'menu';
  category: 'Clicks Interaction';
  action: string;
  label: string;
}>;

// Data for Google Analytics 4 GTM Events
export interface ProductDetailGA4 {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  currency: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category: string;
  item_category2: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price?: number;
  quantity?: number;
}

export const gtmPushData = <T extends GTMPushable>(data: T) => {
  if (typeof window !== 'undefined') {
    ((window as any).dataLayer = (window as any).dataLayer || []).push(data);
  }
};

export type GTMPushEvent = <T extends GTMEvent>(data: T) => void;

export const gtmPushEvent: GTMPushEvent = data => gtmPushData(data);

export const updateGtmOnLogin = (client: ApolloClient.ApolloClient<object>) => {
  client
    .watchQuery<GetLogin>({
      query: GET_LOGIN
    })
    .subscribe(({ data }) => {
      if (data) {
        const ua = window.navigator.userAgent;
        const deviceType: DeviceType = ua ? (ua.includes('Mobi') ? 'mobile' : ua.includes('Tablet') ? 'tablet' : 'desktop') : 'desktop';

        const { login, clientContact } = data;
        const gtmData = { deviceType, clientContact };
        if (login) {
          const clientType = clientContact.ClientContactInfos ? clientContact.ClientContactInfos.LibelleQualificationClient : null;
          clientType &&
            gtmPushData({
              ...gtmData,
              clientType,
              userId: login.customerNumber,
              raisonSociale: login.companyName,
              dateInscription: clientContact.DateInscription
            });
        } else {
          gtmPushData({
            deviceType,
            clientContact: {
              ...clientContact,
              ClientContactInfos: null
            }
          });
        }
      }
    });
};

export const getProductInfoWithKey = (key: string, productInfo?: ProductInfo[] | null) => {
  const info = productInfo && productInfo.find(info => info.key === key);
  return (info && info.value) || '';
};

export const gtmPushGA4AddProductEvent = (products: ProductDetailGA4[], productPrice: number = 0) => {
  gtmPushEvent({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'EUR',
      value: productPrice || 0,
      items: products
    }
  });
};
export const gtmPushGA4RemoveProductEvent = (products: ProductDetailGA4[], productPrice: number = 0) => {
  gtmPushEvent({
    event: 'remove_from_cart',
    ecommerce: {
      currency: 'EUR',
      value: productPrice || 0,
      items: products
    }
  });
};

export const gtmPushQuoteEvent = (status: StatusQuoteEvent, productName: string) => {
  gtmPushEvent({ event: 'quote', status, productName });
};

export const gtmPushLoginEvent = () => {
  gtmPushEvent({ event: 'account login' });
};

export const pushProductViewEvent = (product: ProductDetailGA4, productPrice: number) => {
  const { item_name, item_id, currency, item_category, item_category2, item_category3, item_category4, item_category5, item_variant, price } = product;
  gtmPushData({
    event: 'view_item',
    ecommerce: {
      currency: currency,
      value: productPrice || 0,
      items: [
        {
          item_name,
          item_id,
          currency,
          item_category,
          item_category2,
          item_category3,
          item_category4,
          item_category5,
          item_variant,
          price
        }
      ]
    }
  });
};

export const gtmPushGA4AddViewCartEvent = (totalPrice: number, products: ProductDetailGA4[]) => {
  gtmPushEvent({
    event: 'view_cart',
    ecommerce: {
      currency: 'EUR',
      value: totalPrice,
      items: products
    }
  });
};
