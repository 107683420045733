exports.components = {
  "component---src-pages-browser-support-tsx": () => import("./../../../src/pages/browser-support.tsx" /* webpackChunkName: "component---src-pages-browser-support-tsx" */),
  "component---src-pages-design-proposals-tsx": () => import("./../../../src/pages/designProposals.tsx" /* webpackChunkName: "component---src-pages-design-proposals-tsx" */),
  "component---src-pages-design-review-tsx": () => import("./../../../src/pages/design-review.tsx" /* webpackChunkName: "component---src-pages-design-review-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-calculator-page-tsx": () => import("./../../../src/templates/calculatorPage.tsx" /* webpackChunkName: "component---src-templates-calculator-page-tsx" */),
  "component---src-templates-cart-tsx": () => import("./../../../src/templates/cart.tsx" /* webpackChunkName: "component---src-templates-cart-tsx" */),
  "component---src-templates-category-page-algolia-tsx": () => import("./../../../src/templates/categoryPageAlgolia.tsx" /* webpackChunkName: "component---src-templates-category-page-algolia-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/categoryPage.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-design-proposals-tsx": () => import("./../../../src/templates/designProposals.tsx" /* webpackChunkName: "component---src-templates-design-proposals-tsx" */),
  "component---src-templates-design-review-tsx": () => import("./../../../src/templates/designReview.tsx" /* webpackChunkName: "component---src-templates-design-review-tsx" */),
  "component---src-templates-home-page-v-3-tsx": () => import("./../../../src/templates/homePageV3.tsx" /* webpackChunkName: "component---src-templates-home-page-v-3-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-loyalty-tsx": () => import("./../../../src/templates/loyalty.tsx" /* webpackChunkName: "component---src-templates-loyalty-tsx" */),
  "component---src-templates-ppag-category-page-tsx": () => import("./../../../src/templates/PPAGCategoryPage.tsx" /* webpackChunkName: "component---src-templates-ppag-category-page-tsx" */),
  "component---src-templates-ppag-category-page-v-2-tsx": () => import("./../../../src/templates/PPAGCategoryPageV2.tsx" /* webpackChunkName: "component---src-templates-ppag-category-page-v-2-tsx" */),
  "component---src-templates-ppag-product-page-tsx": () => import("./../../../src/templates/PPAGProductPage.tsx" /* webpackChunkName: "component---src-templates-ppag-product-page-tsx" */),
  "component---src-templates-print-design-tsx": () => import("./../../../src/templates/printDesign.tsx" /* webpackChunkName: "component---src-templates-print-design-tsx" */),
  "component---src-templates-product-page-v-3-tsx": () => import("./../../../src/templates/productPageV3.tsx" /* webpackChunkName: "component---src-templates-product-page-v-3-tsx" */),
  "component---src-templates-registration-new-success-tsx": () => import("./../../../src/templates/registration/newSuccess.tsx" /* webpackChunkName: "component---src-templates-registration-new-success-tsx" */),
  "component---src-templates-registration-success-tsx": () => import("./../../../src/templates/registration/success.tsx" /* webpackChunkName: "component---src-templates-registration-success-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-simple-page-new-tsx": () => import("./../../../src/templates/simplePageNew.tsx" /* webpackChunkName: "component---src-templates-simple-page-new-tsx" */),
  "component---src-templates-simple-page-tsx": () => import("./../../../src/templates/simplePage.tsx" /* webpackChunkName: "component---src-templates-simple-page-tsx" */),
  "component---src-templates-universe-page-tsx": () => import("./../../../src/templates/universePage.tsx" /* webpackChunkName: "component---src-templates-universe-page-tsx" */)
}

