import { SESSION_STORAGE_CUSTOMER_NUMBER } from '../../login/auth';
import { HitWithAnalytics } from '../../typings/AlgoliaTypes';
import searchInsights from './searchInsights';

export type ALGOLIA_CLICKED_FROM = 'SearchBox';
export type ALGOLIA_CONVERT_FROM = 'PPAG Quantity Form' | 'AMS Product Page' | 'Product Page';

enum ALGOLIA_INSIGHTS_TYPE {
  convertedObjectIDsAfterSearch = 'convertedObjectIDsAfterSearch',
  clickedObjectIDsAfterSearch = 'clickedObjectIDsAfterSearch',
  clickedObjectIDs = 'clickedObjectIDs'
}

enum ALGOLIA_INSIGHTS_EVENTS_MSG {
  requestAQuote = 'Request a Quote',
  productAddedToCart = 'Product Added to Cart',
  productClicked = 'Product Clicked'
}

// Note : on AMS side within Shared Components, from and message values are not tested.
export const sendAlgoliaConvert = (from: ALGOLIA_CONVERT_FROM, message?: ALGOLIA_INSIGHTS_EVENTS_MSG) => {
  if (typeof sessionStorage['clickedHitNow'] === 'undefined') {
    return;
  }

  try {
    const clickedHitNow: HitWithAnalytics = JSON.parse(sessionStorage.clickedHitNow);
    const insightsParams = {
      eventType: 'conversion',
      eventName: `${from};${message || ALGOLIA_INSIGHTS_EVENTS_MSG.productAddedToCart}`,
      index: process.env.GATSBY_ALGOLIA_PAGE_INDEX_NAME || '',
      queryID: clickedHitNow._queryID,
      objectIDs: [clickedHitNow.objectID],
      userToken: sessionStorage[SESSION_STORAGE_CUSTOMER_NUMBER] || undefined
    };
    searchInsights(ALGOLIA_INSIGHTS_TYPE.convertedObjectIDsAfterSearch, insightsParams);
    sessionStorage.removeItem('clickedHitNow');
  } catch (e) {
    console.error('Failed sending algolia convert', e);
  }
};

export const sendAndStoreAlgoliaClick = (from: ALGOLIA_CLICKED_FROM, hit: HitWithAnalytics) => {
  sessionStorage.setItem('clickedHitNow', JSON.stringify(hit));

  try {
    const insightsParams = {
      eventType: 'click',
      eventName: `${from};${ALGOLIA_INSIGHTS_EVENTS_MSG.productClicked}`,
      index: process.env.GATSBY_ALGOLIA_PAGE_INDEX_NAME || '',
      queryID: hit._queryID,
      objectIDs: [hit.objectID],
      positions: [hit._position],
      userToken: sessionStorage[SESSION_STORAGE_CUSTOMER_NUMBER] || undefined
    };

    searchInsights(ALGOLIA_INSIGHTS_TYPE.clickedObjectIDsAfterSearch, insightsParams);
  } catch (e) {
    console.error('Failed sending algolia click', e);
  }
};
