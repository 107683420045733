import loadable from '@loadable/component';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MOBILE_SIZE } from '../../common/constants';
import { useDisableScrolling } from '../../hooks/useDisableScrolling';
import { useIsLoggedIn, useIsRegisteredNonBuyer, useIsReseller } from '../../hooks/useLogin';
import { useIsWindowWidthBelow } from '../../hooks/useWindowSize';
import { showForUserMultiple } from '../../utils/contentful';
import { MenuItem, ROOT_PROMO_KEY, SortType, cloneMenuWithFiltering, generatePromoSubMenu, sortThematics } from './MenuUtils';
import { MenuHorizontalBar } from './desktop/MenuHorizontalBar';

const MenuMobileContent = loadable(() => import('./mobile/MenuMobileContent'));
//const MenuDesktopContent = loadable(() => import('./desktop/MenuDesktopContent'));

interface Props {
  rootMenuItems: MenuItem[];
  menuContentVisible: boolean;
  setMenuContentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isFunnel?: boolean;
  isMinimalistHeader: boolean;
}

export const Menu: FunctionComponent<Props> = ({ rootMenuItems, menuContentVisible, setMenuContentVisible, isFunnel, isMinimalistHeader }) => {
  const { t } = useTranslation('sharedComponents');
  const [menuSelectedPath, setMenuSelectedPath] = useState<MenuItem[]>([]);
  const [thematicsFilter, setThematicsFilter] = useState<string>('');
  const [sorting, setSorting] = useState(SortType.natural_sorting);

  //Ensure there is no double scrollbar when menu is visible
  useDisableScrolling(menuContentVisible);

  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);

  const openMenu = (selectedItem?: MenuItem) => {
    if (selectedItem?.children) {
      setMenuContentVisible(true);
    }
    if (selectedItem) {
      setMenuSelectedPath([selectedItem]);
    }
  };
  const closeMenu = () => {
    setMenuSelectedPath([]);
    setMenuContentVisible(false);
    setThematicsFilter('');
  };
  const handleEscapeKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  //Keep user visible content only
  const currentUserLoggedIn = useIsLoggedIn();
  const currentUserReseller = useIsReseller();
  const currentUserRegisteredNonBuyer = useIsRegisteredNonBuyer();
  const items =
    cloneMenuWithFiltering(
      (item: MenuItem) => showForUserMultiple(item.showOnlyForMultiple, currentUserReseller, currentUserLoggedIn, currentUserRegisteredNonBuyer),
      rootMenuItems
    ) ?? [];

  //sort thematics children alphabeticaly
  sortThematics(items);
  // On-the-fly generation of "Promo" SubMenu and Filter it with campaign showOnlyFor

  const promoItems = generatePromoSubMenu(items, currentUserReseller, currentUserLoggedIn, currentUserRegisteredNonBuyer);

  if (promoItems != undefined && promoItems.length > 0) {
    items.unshift({
      label: t('shared.menu.promoItemLabel'),
      key: ROOT_PROMO_KEY,
      children: generatePromoSubMenu(items, currentUserReseller, currentUserLoggedIn, currentUserRegisteredNonBuyer),
      href: t('shared.menu.promo-seeAll-link')
    });
  }

  const selectedTopItem = menuSelectedPath[0];

  const setSortingCallback = (sortType: SortType) => {
    setSorting(sortType);
    if (sorting !== sortType) {
      //close panels when sorting is changed
      if (selectedTopItem) {
        setMenuSelectedPath([selectedTopItem]);
      }
    }
  };

  return (
    <>
      {process.env.SHARED_COMPONENT_MODE && (
        <style>
          {`@font-face {
          font-family: 'Styrene Medium';
          src: url('/fonts/StyreneA-Medium.woff') format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }`}
        </style>
      )}
      {isMobile || (
        <StyledDesktop>
          {!isFunnel && <MenuHorizontalBar rootMenuItems={items} openMenu={openMenu} closeMenu={closeMenu} isMinimalistHeader={isMinimalistHeader} />}
          {/*
          <MenuDesktopContent
            show={menuContentVisible}
            rootMenuItems={items}
            menuSelectedPath={menuSelectedPath}
            setMenuSelectedPath={setMenuSelectedPathCallBack}
            closeMenu={closeMenu}
            sorting={sorting}
            setSorting={setSortingCallback}
            productsDisplayMode={productsDisplayMode}
            setProductsDisplayMode={setProductsDisplayModeCallback}
            thematicsFilter={thematicsFilter}
            setThematicsFilter={setThematicsFilter}
            isMinimalistHeader={isMinimalistHeader}
      />*/}
        </StyledDesktop>
      )}
      {!isMobile || (
        <StyledMobile>
          <MenuMobileContent
            show={menuContentVisible}
            rootMenuItems={items}
            menuSelectedPath={menuSelectedPath}
            setMenuSelectedPath={setMenuSelectedPath}
            closeMenu={closeMenu}
            sorting={sorting}
            setSorting={setSortingCallback}
            thematicsFilter={thematicsFilter}
            setThematicsFilter={setThematicsFilter}
            isMinimalistHeader={isMinimalistHeader}
          />
        </StyledMobile>
      )}
    </>
  );
};

const StyledDesktop = styled.div``;
const StyledMobile = styled.div``;
