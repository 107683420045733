import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

export interface MenuLinkProps {
  href?: string;
  feature?: string;
  onClick?: React.MouseEventHandler;
  hideOnMobile?: boolean;
  hideOnDesktop?: boolean;
  className?: string;
  id?: string;
}

export const MenuLink: FunctionComponent<MenuLinkProps> = props => (props?.href ? <StyledMenuLink {...props} /> : <StyledMenuSpanLink {...props} />);

const menuLinkStyles = css<MenuLinkProps>`
  color: ${props => (props.feature === 'inknpaper' ? '#3273dc' : '#ffffff')};
  transition: 300ms;
  &:hover {
    color: ${props => (props.feature === 'inknpaper' ? '#3273dc' : '#ffffff')};
    text-decoration: underline;
  }

  @media (max-width: 1024px) {
    display: ${props => (!!props.hideOnMobile ? 'none' : 'block')};
  }
  @media (min-width: 1025px) {
    display: ${props => (!!props.hideOnDesktop ? 'none' : 'block')};
  }
`;

export const StyledMenuLink = styled.a<MenuLinkProps>`
  ${menuLinkStyles}
`;

export const StyledMenuSpanLink = styled.span<MenuLinkProps>`
  ${menuLinkStyles}
`;
