import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Logo } from './Logo';
import { UserMenu } from './UserMenu';
import { HeaderProps } from './Header';
import { HeaderContact } from './HeaderContact';
import { QueryStatus } from '../common/components/QueryStatus';
import { MiniCartINP } from '../cart/MiniCartINP';
import { useGetLogin, isLoggedIn } from '../hooks/useLogin';
import { HeaderTracking } from './HeaderTracking';
import { NewSearchBox } from '../components/newSearchBox';

interface Props extends HeaderProps {
  isWindow: boolean;
  gtmPushData: any;
  pageType?: string;
  toggleMenu: () => void;
  isFunnel?: boolean;
  isMinimalistHeader: boolean;
}

export const HeaderDesktop: FunctionComponent<Props> = ({ isFunnel }) => {
  const { t } = useTranslation('sharedComponents');
  const useLoginResult = useGetLogin();
  const loggedIn = isLoggedIn(useLoginResult);

  return (
    <>
      <StyledHeaderContent>
        <StyledHeaderBottom>
          <StyledHeaderLeft>
            <Logo href="/" isFunnel={isFunnel} aria-label="Exaprint homepage">
              <StyledBaseline>{t('shared.logo.baseline')}</StyledBaseline>
            </Logo>
          </StyledHeaderLeft>
          <StyledHeaderCenter>{!isFunnel && <NewSearchBox />}</StyledHeaderCenter>
          <StyledHeaderRight>
            {!isFunnel && (
              <>
                <HeaderContact />
                {useLoginResult.error && <QueryStatus error={useLoginResult.error} />}
                {useLoginResult.data && (
                  <StyledHeaderUser data-testid="header-user">
                    <StyledHeaderItemContent>
                      <UserMenu feature="inknpaper" loginData={useLoginResult.data} />
                    </StyledHeaderItemContent>
                  </StyledHeaderUser>
                )}
                {loggedIn && <HeaderTracking mobile={false} notificationsCount={useLoginResult.data?.login?.notifications} />}
                {useLoginResult.error && <QueryStatus error={useLoginResult.error} />}
                {loggedIn && <MiniCartINP />}
              </>
            )}
          </StyledHeaderRight>
        </StyledHeaderBottom>
      </StyledHeaderContent>
    </>
  );
};

const StyledHeaderContent = styled.div`
  position: relative;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
`;

const StyledHeaderBottom = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  padding: 10px 0;
`;

const StyledHeaderLeft = styled.div`
  align-items: center;
  display: flex;
  column-gap: 15px;
`;

const StyledBaseline = styled.p`
  font-family: var(--font-styrene);
  padding-left: 2px;
  font-size: 10px;
  line-height: 10px;
`;

const StyledHeaderCenter = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1;
`;

const StyledHeaderRight = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 50px;
  font-size: 15px;
  font-weight: bold;
  align-items: center;

  > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledHeaderUser = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

export const StyledHeaderItemContent = styled.div`
  p {
    font-family: var(--font-styrene);
    padding: 3px;
  }

  a {
    text-decoration: underline;
    font-weight: normal;
    color: #33d1ff;

    :hover {
      text-decoration: none;
      color: #33d1ff;
    }
  }
  &.dropdown-contact {
    left: auto;
    right: 0;
  }
`;
