import { gql } from '@apollo/client';

export const CART_LINEITEM_VARIANT_FRAGMENT = gql`
  fragment LineItemVariant on LineItemVariant {
    variantId
    sku
    colorName
    size
    images
  }
`;
