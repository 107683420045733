import { useState, useEffect } from 'react';
import { AlgoliaItem } from '../typings/AlgoliaTypes';
import { getAlgoliaSearchIndex } from '../utils/algolia/helpers';

interface Props {
  searchTerms?: string | null;
  ignoreSame?: boolean;
  hitsPerPage?: number;
  hasFocus?: boolean;
}

export const useAlgoliaSuggestions = ({ searchTerms, ignoreSame = false, hitsPerPage = 10, hasFocus }: Props): AlgoliaItem[] => {
  const searchIndex = getAlgoliaSearchIndex({ useQuerySuggestionIndex: true });
  const [results, setResults] = useState<AlgoliaItem[]>([]);

  useEffect(() => {
    if (searchTerms && searchTerms.trim().length > 0 && hasFocus !== false) {
      searchIndex
        .search<AlgoliaItem>(searchTerms, {
          facets: ['*'],
          analytics: false, // Do not use these queries in Analytics
          hitsPerPage
        })
        .then(res => {
          const results = ignoreSame ? res?.hits.filter(hit => hit.query !== searchTerms) : res?.hits;
          setResults(results);
        })
        .catch((err: any) => console.error(err));
    }
  }, [searchTerms, hasFocus]);
  return results;
};
