import React, { FunctionComponent, useEffect } from 'react';

export interface TrustpilotData {
  templateId: string;
  businessUnitId: string;
  styleHeight: string;
  styleWidth: string;
  theme: string;
  href: string;
  stars?: string;
}

export const TrustPilotWidget: FunctionComponent<TrustpilotData> = ({ templateId, businessUnitId, styleHeight, styleWidth, theme, href, stars }) => {
  const ref = React.useRef(null);
  useEffect(() => {
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  const locale = process.env.GATSBY_LOCALE || 'fr';

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={locale}
      data-template-id={templateId}
      data-businessunit-id={businessUnitId}
      data-style-height={styleHeight}
      data-style-width={styleWidth}
      data-theme={theme}
      data-stars={stars || null}
    >
      <a href={href} target="_blank" rel="noopener">
        Trustpilot
      </a>
    </div>
  );
};
