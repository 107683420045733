import React from 'react';
import { ApolloError } from '@apollo/client';

import { QueryError } from './QueryError';
import { QueryLoading } from './QueryLoading';

export interface QueryStatusProps {
  error?: ApolloError;
  loading?: boolean;
}

export const QueryStatus = ({ error, loading }: QueryStatusProps) => {
  if (loading) {
    return <QueryLoading loading={loading} />;
  }
  if (error) {
    return <QueryError />;
  } else {
    return null;
  }
};
