import { gql } from '@apollo/client';

export const PRICE_VALUE_FRAGMENT = gql`
  fragment PriceValue on PriceValue {
    fractionDigits
    currencyCode
    centAmount
    type
  }
`;
