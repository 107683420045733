import {
  SearchEngineOptimization,
  CategoryPage,
  ExternalLink,
  Menu,
  ProductPage,
  RelativeLink,
  SimplePage,
  ContentBox,
  MenuMediaItem,
  Highlight,
  Entry,
  Video,
  RichAsset,
  SimplePageNew,
  CalculatorPage,
  Emoticons,
  UniversePage,
  ContentBoxContent,
  MenuRichSnippetItem,
  RichSnippetFaqEntry,
  RichSnippetPrice,
  Maybe,
  SimpleContent,
  ConceptBlock,
  UniverseSections
} from '../generated/contentful-generated-types';
import get from 'lodash/get';
import { getUrlOf, prefixes } from './urls';
import { CustomProduct } from '../common/custom-gql-types';
import { SHOWONLYFOR, SHOWONLYFORMULTIPLE } from '../common/constants';
import { AlgoliaPriceRange } from '../typings/AlgoliaTypes';

export type OmitTypename<T> = Omit<T, '__typename'>;

export type CategoryRowItem = ContentBox | MenuMediaItem | Highlight;
export type ContentfulNode = { __typename: string };
export type PageWithAlternate = ContentfulNode & {
  alternateFR: string;
  alternateES: string;
};
export type ContentBoxContentWithoutUniversePage = Exclude<ContentBoxContent, UniversePage>;

export function getTypeName<T extends Entry>(item: T | ContentfulNode) {
  const typename = (item as any as ContentfulNode).__typename;
  if (typename == undefined) throw Error('Coud not found __typename attribute on ' + JSON.stringify(item));
  const matches = typename.match(/(Contentful_)?(.*)/);
  return matches ? matches[2] : '';
}

export function isVideo(item: CategoryRowItem): item is Video {
  return getTypeName(item) === 'Video';
}

export function isRichAsset(item: CategoryRowItem): item is RichAsset {
  return getTypeName(item) === 'RichAsset';
}

export function isMedia(item: CategoryRowItem): item is MenuMediaItem {
  return isVideo(item) || isRichAsset(item);
}

export function isHighlight(item: CategoryRowItem): item is Highlight {
  return getTypeName(item) === 'Highlight';
}

export function isCategoryPage(entry: Entry): entry is Menu {
  return getTypeName(entry) === 'Menu';
}

export function isUniversePage(entry: Entry): entry is UniversePage {
  return getTypeName(entry) === 'UniversePage';
}

export function isAmsProductPage(entry: Entry): entry is RelativeLink {
  return getTypeName(entry) === 'RelativeLink';
}

export function isPPAGCategoryPage(entry: Entry): entry is CategoryPage {
  return getTypeName(entry) === 'CategoryPage';
}

export function isExternalLink(entry: Entry): entry is ExternalLink {
  return getTypeName(entry) === 'ExternalLink';
}

export function isEmoticons(entry: Entry): entry is Emoticons {
  return getTypeName(entry) === 'Emoticons';
}

export function isProductPage(entry: Entry): entry is ProductPage {
  return getTypeName(entry) === 'ProductPage';
}

export function isCustomProduct(entry: ContentfulNode): entry is ContentfulNode & CustomProduct {
  return getTypeName(entry) === 'Product';
}

export function isSimplePage(entry: Entry): entry is SimplePage {
  return getTypeName(entry) === 'SimplePage';
}

export function isCalculatorPage(entry: Entry): entry is CalculatorPage {
  return getTypeName(entry) === 'CalculatorPage';
}

export function isSimplePageNew(entry: Entry): entry is SimplePageNew {
  return getTypeName(entry) === 'SimplePageNew';
}

export function isContentBox(entry: Entry): entry is ContentBox {
  return getTypeName(entry) === 'ContentBox' || getTypeName(entry) === 'Contentful_ContentBox';
}

export function isSimpleContent(entry: Entry): entry is SimpleContent {
  return getTypeName(entry) === 'SimpleContent' || getTypeName(entry) === 'Contentful_SimpleContent';
}
export function isConceptBlock(entry: Entry): entry is ConceptBlock {
  return getTypeName(entry) === 'ConceptBlock';
}

export function isUniverseSections(entry: Entry): entry is UniverseSections {
  return getTypeName(entry) === 'UniverseSections';
}

export const getSeoInformations = (
  title: string,
  seoMetaTags: SearchEngineOptimization | null | undefined,
  defaultMetaDescription: string,
  defaultMetaTitle: string,
  menuRichSnippetItemEntries?: Maybe<MenuRichSnippetItem>[],
  richSnippetProductImage?: string | null,
  callingPrice?: AlgoliaPriceRange | null
) => {
  const getMetaTitle = (defaultMetaTitle: string) => {
    let autoMetaTitle = `${defaultMetaTitle}`;
    autoMetaTitle = autoMetaTitle.length > 60 ? `${title}` : autoMetaTitle;
    return autoMetaTitle;
  };
  const fallbackMetaTitle = getMetaTitle(defaultMetaTitle);
  const metaDescription = (get(seoMetaTags, 'metaDescription', `${defaultMetaDescription}`) as string) || `${defaultMetaDescription}`;
  const metaRobots = get(seoMetaTags, 'metaRobots') || [];
  const metaRobotsStr = metaRobots.join(',');
  const metaTitle = get(seoMetaTags, 'metaTitle', fallbackMetaTitle) || fallbackMetaTitle;

  const richSnippetScript = createRichSnippetScript(title, menuRichSnippetItemEntries, richSnippetProductImage, callingPrice);

  return {
    metaDescription,
    metaRobotsStr,
    metaTitle,
    richSnippetScript
  };
};

interface getEntryHrefOptions {
  absolute?: boolean;
  relativeLinkUsePlain?: boolean;
}

// equivalent to type MenuItemsItem but decomposed for more flexibility

export const getEntryHref = (entry: Entry, options?: getEntryHrefOptions): string => {
  const locale = process.env.GATSBY_LOCALE || 'fr';

  if (isSimplePage(entry) || isSimplePageNew(entry)) {
    return `${options && options.absolute ? getUrlOf('ams') : ''}/${prefixes[locale].onlinePrinting}/${entry.slug}`;
  }

  if (isCalculatorPage(entry)) {
    return `${options && options.absolute ? getUrlOf('ams') : ''}/${prefixes[locale].custom}/${entry.slug}`;
  }

  if (isExternalLink(entry)) {
    return entry.url || '';
  }

  if (isAmsProductPage(entry)) {
    const baseUrl = options && options.absolute ? getUrlOf('ams') : '';
    return `${baseUrl}${options && options.relativeLinkUsePlain ? entry.plainPath || entry.path : entry.path}` || '';
  }

  if (isCategoryPage(entry) || isPPAGCategoryPage(entry)) {
    return `${options && options.absolute ? getUrlOf('ams') : ''}/${prefixes[locale].print}/${entry.slug}`;
  }

  if (isProductPage(entry)) {
    return `${options && options.absolute ? getUrlOf('ams') : ''}/${prefixes[locale].product}/${entry.slug}`;
  }

  if (isUniversePage(entry)) {
    return `${options && options.absolute ? getUrlOf('ams') : ''}/${entry.slug}`;
  }

  throw new Error("Can't find an URL for the given MenuItemsItem");
};

export const getEntryPrefix = (entry: ContentfulNode & (Entry | CustomProduct), lng?: string): string => {
  const locale = lng || process.env.GATSBY_LOCALE || 'fr';

  if (isCustomProduct(entry) || isProductPage(entry)) {
    return `${prefixes[locale].product}/`;
  }

  if (isSimplePage(entry) || isSimplePageNew(entry)) {
    return `${prefixes[locale].onlinePrinting}/`;
  }

  if (isCalculatorPage(entry)) {
    return `${prefixes[locale].custom}/`;
  }

  if (isExternalLink(entry) || isAmsProductPage(entry)) {
    return '';
  }

  if (isCategoryPage(entry) || isPPAGCategoryPage(entry)) {
    return `${prefixes[locale].print}/`;
  }

  throw new Error("Can't find prefix for the given entry");
};

export const getUrlFragment = (newLinkHash: string | undefined | null) => {
  return newLinkHash && newLinkHash.trim().length > 0 ? `#${newLinkHash}` : '';
};

export const showForUser = (showOnlyFor: string | null | undefined, isReseller: boolean, isLoggedIn: boolean, isRegisteredNonBuyer: boolean): boolean => {
  if (!showOnlyFor) return true;

  if (isLoggedIn && showOnlyFor === SHOWONLYFOR.ALL_CONNECTED) return true;
  if (!isLoggedIn && showOnlyFor === SHOWONLYFOR.NON_CONNECTED) return true;
  if (isLoggedIn && isReseller && showOnlyFor === SHOWONLYFOR.CONNECTED_RESELLERS) return true;
  if (isLoggedIn && !isReseller && showOnlyFor === SHOWONLYFOR.CONNECTED_NON_RESELLERS) return true;

  if (isLoggedIn && isRegisteredNonBuyer && showOnlyFor === SHOWONLYFOR.ALL_REGISTERED_NON_BUYERS) return true;
  if (isLoggedIn && !isRegisteredNonBuyer && showOnlyFor === SHOWONLYFOR.ALL_CUSTOMERS) return true;

  if (isLoggedIn && isReseller && showOnlyFor === SHOWONLYFOR.ALL_RESELLERS) return true;
  if (isLoggedIn && isReseller && isRegisteredNonBuyer && showOnlyFor === SHOWONLYFOR.REGISTERED_NON_BUYERS_RESELLERS) return true;
  if (isLoggedIn && isReseller && !isRegisteredNonBuyer && showOnlyFor === SHOWONLYFOR.CUSTOMERS_RESELLERS) return true;

  if (isLoggedIn && !isReseller && showOnlyFor === SHOWONLYFOR.ALL_NON_RESELLERS) return true;
  if (isLoggedIn && !isReseller && isRegisteredNonBuyer && showOnlyFor === SHOWONLYFOR.REGISTERED_NON_BUYERS_NON_RESELLERS) return true;
  if (isLoggedIn && !isReseller && !isRegisteredNonBuyer && showOnlyFor === SHOWONLYFOR.CUSTOMERS_NON_RESELLERS) return true;
  if ((!isLoggedIn || isRegisteredNonBuyer) && showOnlyFor === SHOWONLYFOR.NON_CONNECTED_OR_NON_BUYERS) return true;

  return false;
};

export const showForUserMultiple = (
  showOnlyForMultiple: Maybe<Maybe<string>[]> | undefined,
  isReseller: boolean,
  isLoggedIn: boolean,
  isRegisteredNonBuyer: boolean
): boolean => {
  if (!showOnlyForMultiple || showOnlyForMultiple.length === 0) return true;

  const checks = [
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.ALL_CONNECTED) && isLoggedIn,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.NON_CONNECTED) && !isLoggedIn,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.CONNECTED_RESELLERS) && isLoggedIn && isReseller,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.CONNECTED_NON_RESELLERS) && isLoggedIn && !isReseller,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.ALL_CONNECTED_NON_BUYERS) && isLoggedIn && isRegisteredNonBuyer,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.ALL_CONNECTED_CUSTOMERS) && isLoggedIn && !isRegisteredNonBuyer,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.ALL_CONNECTED_RESELLERS) && isLoggedIn && isReseller,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.CONNECTED_NON_BUYERS_RESELLERS) && isLoggedIn && isReseller && isRegisteredNonBuyer,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.CONNECTED_CUSTOMERS_RESELLERS) && isLoggedIn && isReseller && !isRegisteredNonBuyer,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.ALL_CONNECTED_NON_RESELLERS) && isLoggedIn && !isReseller,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.CONNECTED_NON_BUYERS_NON_RESELLERS) && isLoggedIn && !isReseller && isRegisteredNonBuyer,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.CONNECTED_CUSTOMERS_NON_RESELLERS) && isLoggedIn && !isReseller && !isRegisteredNonBuyer,
    showOnlyForMultiple.includes(SHOWONLYFORMULTIPLE.NON_CONNECTED_OR_NON_BUYERS) && (!isLoggedIn || isRegisteredNonBuyer)
  ];

  return checks.some(Boolean);
};

export const getContentfulEntryBaseUrl = () =>
  `https://app.contentful.com/spaces/${process.env.GATSBY_CONTENTFUL_SPACEID}/environments/${process.env.GATSBY_CONTENTFUL_ENVIRONMENT}/entries/`;

export type ContentBoxContentWithoutUniverse = Exclude<ContentBoxContent, UniversePage>;

const createRichSnippetScript = (
  title: string,
  menuRichSnippetItemEntries?: Maybe<MenuRichSnippetItem>[],
  richSnippetProductImage?: String | null,
  callingPrice?: AlgoliaPriceRange | null
) => {
  const richSnippetFAQEntries = menuRichSnippetItemEntries?.filter(item => (item as ContentfulNode).__typename === 'Contentful_RichSnippetFaqEntry') as
    | RichSnippetFaqEntry[]
    | undefined;
  const richSnippetPrice = menuRichSnippetItemEntries?.find(item => (item as ContentfulNode).__typename === 'Contentful_RichSnippetPrice') as RichSnippetPrice;

  const hasPrice = !!callingPrice;
  const hasFAQ = !!richSnippetFAQEntries?.length;

  if (!hasPrice && !hasFAQ) {
    return [];
  }

  const FAQMarkup = hasFAQ
    ? `{
		  "@context": "https://schema.org",
		  "@type": "FAQPage",
		  "mainEntity":[
			  ${richSnippetFAQEntries
          .map(({ question, answer }) => {
            return `{
					  "@type": "Question",
					  "name": "${question}",
					  "acceptedAnswer": {
						  "@type": "Answer",
						  "text": "<p>${answer}</p>"
					  }
				  }`;
          })
          .join(',')}
		  ]}`
    : '';

  const aggregateRatingMarkup =
    richSnippetPrice && richSnippetPrice.rating && richSnippetPrice.reviews
      ? `"aggregateRating": {
		"@type": "AggregateRating",
		"ratingValue": "${richSnippetPrice.rating}",
		"reviewCount": ${richSnippetPrice.reviews}
	  }`
      : '';

  const offersMarkup = callingPrice
    ? `"offers": {
		"@type": "Offer",
		"priceCurrency": "EUR",
		"price": "${callingPrice.minAmount}",
		"priceSpecification": {
		  "@type": "UnitPriceSpecification",
		  "priceCurrency": "EUR",
		  "price": "${callingPrice.minAmount}",
		  "unitCode": "C62",
		  "referenceQuantity": {
			"@type": "QuantitativeValue",
			${callingPrice.minAmountQuantity ? `"value": "${callingPrice.minAmountQuantity}",` : `"value": ""`}
			"unitText": "ex"
		  }
		}
	  }`
    : '';

  const priceMarkup = hasPrice
    ? `{
		  "@context": "https://schema.org",
		  "@type": "Product",
		  "name": "${title}",
		  "image": "${richSnippetProductImage}"
		  ${aggregateRatingMarkup ? `,${aggregateRatingMarkup}` : ''}

		  ${offersMarkup ? `,${offersMarkup}` : ''}
		}`
    : '';

  return [FAQMarkup, priceMarkup].filter(markup => markup);
};
