import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLoginForm } from '../hooks/useLoginForm';
import { useIsWindowWidthBelow } from '../hooks/useWindowSize';

import discount from '../../assets/icons/discount-pourcentage.svg';
import rates from '../../assets/icons/exclusive-rates.svg';
import sample from '../../assets/icons/sample-kit.svg';
import chevron from '../../assets/icons/chevron_bas.svg';
import close from '../../assets/icons/plus.svg';

import { LOCAL_STORAGE_KEY_POP_IN_REGISTRATION } from '../common/constants';

const WELCOME_IMG_AND_TEXT = [
  {
    url: discount,
    label: 'shared.registrationModal.welcomeOffer'
  },
  {
    url: sample,
    label: 'shared.registrationModal.sampleKit'
  },
  {
    url: rates,
    label: 'shared.registrationModal.exclusivesRate'
  }
];

export const RegistrationModal: React.FC = () => {
  const { t } = useTranslation('sharedComponents');
  const [displayModal, setModalDisplay] = useState<boolean>(false);
  const [{ displayAnimation, displayMobileBenefits }, setMobileBenefitsDisplay] = useState<{ displayMobileBenefits: boolean; displayAnimation: boolean }>({
    displayMobileBenefits: false,
    displayAnimation: false
  });
  const { show, isVisible } = useLoginForm() || { show: undefined, isVisible: undefined };
  const isMobile = useIsWindowWidthBelow(700);

  const closePopIn = () => {
    window?.localStorage.setItem(LOCAL_STORAGE_KEY_POP_IN_REGISTRATION, 'false');
    setModalDisplay(false);
  };

  const createNewAccount = () => {
    closePopIn();
    window.open(t('shared.navigation.register-path-new'), '_self');
  };

  const logIn = () => {
    closePopIn();
    show?.();
  };

  const onClickDisplayMobileBenefits = () => {
    if (displayAnimation) {
      setMobileBenefitsDisplay(({ displayMobileBenefits, displayAnimation }) => ({
        displayAnimation: !displayAnimation,
        displayMobileBenefits
      }));
      // timer for the mobile collapse animation to be render
      const timer = setTimeout(() => {
        setMobileBenefitsDisplay(({ displayMobileBenefits, displayAnimation }) => ({
          displayAnimation,
          displayMobileBenefits: !displayMobileBenefits
        }));
      }, 900);
      return () => clearTimeout(timer);
    } else {
      setMobileBenefitsDisplay(({ displayMobileBenefits, displayAnimation }) => ({
        displayAnimation: !displayAnimation,
        displayMobileBenefits: !displayMobileBenefits
      }));
    }
  };

  // Waiting 10s after the page load before displaying modal
  useEffect(() => {
    // if user already on registration page OR Landing / Simple page OR loginForm is visible,  we don't show registration modal
    const isUrlNotForbidden =
      !window?.location?.href?.includes(t('shared.navigation.register-path-new')) &&
      !window?.location?.href?.includes('/lps/') &&
      !window?.location?.href?.includes('/imprimerie-en-ligne/') &&
      !window?.location?.href?.includes('/imprenta-online/');
    const isDidomiVisible = !!document?.getElementById?.('didomi-popup');
    if (isUrlNotForbidden && !isDidomiVisible && !isVisible) {
      const timer = setTimeout(() => {
        setModalDisplay(prevState => !prevState);
      }, 10000);
      return () => clearTimeout(timer);
    } else if (isVisible && displayModal) {
      setModalDisplay(prevState => !prevState);
    }
  }, [isVisible]);

  if (isMobile)
    return (
      <StyledModalContainer displayModal={displayModal} onClose={closePopIn} onClick={closePopIn}>
        <StyledPopInRegistrationMobileContainer displayModal={displayModal} onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
          <img onClick={() => closePopIn()} src={close} alt="close registration modal's icon" />
          <h2>{t('shared.registrationModal.registerNow')}</h2>
          <StyledBenefitsSpan isContainerOpen={displayMobileBenefits} onClick={onClickDisplayMobileBenefits}>
            {t('shared.registrationModal.mobileBenefits')}
            <img src={chevron} alt="exaprint benefits opener icon" />
          </StyledBenefitsSpan>
          {displayMobileBenefits && (
            <StyledWelcomeOfferMobileWrapper displayBenefits={displayMobileBenefits}>
              {WELCOME_IMG_AND_TEXT.map(({ url, label }, index) => (
                <StyledImgAndTextMobileWrapper displayAnimation={displayAnimation} key={`welcome-offer-modal-mobile-item-${index}`}>
                  <img src={url} alt={`welcome offer: ${label}`} />
                  <span>{t(label)}</span>
                </StyledImgAndTextMobileWrapper>
              ))}
            </StyledWelcomeOfferMobileWrapper>
          )}
          <StyledCTAMobileWrapper>
            <button onClick={() => createNewAccount()}>{t('shared.navigation.register')}</button>
            <span>{t('shared.registrationModal.or')}</span>
            <StyledRegistrationLogInWrapper>
              <span>
                {t('shared.registrationModal.alreadyRegistered')}
                <a onClick={() => logIn()}>{t('shared.navigation.login')}</a>
              </span>
              <span>{t('shared.registrationModal.personalisedExperience')}</span>
            </StyledRegistrationLogInWrapper>
          </StyledCTAMobileWrapper>
        </StyledPopInRegistrationMobileContainer>
      </StyledModalContainer>
    );

  return (
    <StyledModalContainer displayModal={displayModal} onClose={closePopIn} onClick={closePopIn}>
      <StyledPopInRegistrationContainer onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        <span onClick={() => closePopIn()}>{t('shared.registrationModal.welcomeLater')}</span>
        <h2>{t('shared.registrationModal.registerNow')}</h2>
        <StyledWelcomeOfferWrapper>
          {WELCOME_IMG_AND_TEXT.map(({ url, label }, index) => (
            <StyledImgAndTextWrapper key={`welcome-offer-modal-item-${index}`}>
              <img src={url} alt={`welcome offer: ${t(label)}`} />
              <span>{t(label)}</span>
            </StyledImgAndTextWrapper>
          ))}
        </StyledWelcomeOfferWrapper>
        <StyledCTAWrapper>
          <button onClick={() => createNewAccount()}>{t('shared.navigation.register')}</button>
          <span>{t('shared.registrationModal.or')}</span>
          <StyledRegistrationLogInWrapper>
            <span>
              {t('shared.registrationModal.alreadyRegistered')}
              <a onClick={() => logIn()}>{t('shared.navigation.login')}</a>
            </span>
            <span>{t('shared.registrationModal.personalisedExperience')}</span>
          </StyledRegistrationLogInWrapper>
        </StyledCTAWrapper>
      </StyledPopInRegistrationContainer>
    </StyledModalContainer>
  );
};

const StyledModalContainerDefault = styled.div`
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  position: fixed;
  z-index: 10000; // Header z-index = 9999!!!
`;

const StyledModalContainer = styled(StyledModalContainerDefault)<{ displayModal: boolean }>`
  ${({ displayModal }) => !displayModal && 'display: none;'}
`;

const StyledDefaultWrapper = styled.div`
  align-items: center;
  background: #323232;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: 'StyreneA', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  gap: 36px;
  justify-content: center;
  line-height: normal;
  text-align: center;
`;

const StyledPopInRegistrationDefault = styled(StyledDefaultWrapper)`
  align-items: flex-end;
  flex-direction: column;
  padding: 24px 48px 48px 48px;
  > span {
    cursor: pointer;
    text-align: center;
    text-decoration-line: underline;
    &:hover {
      color: #51c1ec;
    }
  }
  > h2 {
    font-size: 24px;
    font-weight: 500;
    padding: 0 20%;
  }
`;

const StyledPopInRegistrationContainer = styled(StyledPopInRegistrationDefault)`
  border-radius: 8px;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  left: 50%;
  max-width: 770px;
  min-width: 600px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledWelcomeOfferWrapper = styled(StyledDefaultWrapper)`
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`;

const StyledImgAndTextWrapper = styled(StyledDefaultWrapper)`
  flex-direction: column;
  height: 156px;
  gap: 8px;
  justify-content: flex-start;
  flex: 1 0 0;
`;

const StyledCTAWrapper = styled(StyledDefaultWrapper)`
  flex-direction: column;
  gap: 8px;
  width: 100%;
  > button {
    align-items: center;
    background: #51c1ec;
    border-color: #51c1ec;
    border-radius: 4px;
    box-shadow: 0 6px 4px 0 rgba(36, 90, 113, 0.1), 0 3px 2px 0 rgba(36, 90, 113, 0.08);
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    justify-content: center;
    outline: none;
    padding: 12px 30px;
    &:hover {
      background: #323232;
      color: #51c1ec;
    }
  }
`;

const StyledRegistrationLogInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & a {
    color: #fff;
    text-decoration: underline;
    &:hover {
      color: #51c1ec;
    }
  }
`;

/* MOBILE Style */

const maxHeight = keyframes`
  from {
    height: 0;
  }
  to {
    height: 400px;
  }
`;

const maxHeightBenefits = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  75% {
    visibility: visible;
    opacity: .2;
  }
  to {
    opacity: 1;
    height: 115px;
  }
`;

const minHeightBenefits = keyframes`
  from {
    height: 115px;
    opacity: 1;
  }
  25% {
    visibility: hidden;
    opacity: .2;
  }
  to {
    height: 0;
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: visible;
  }
  45% {
    opacity: .2;
  }
  to {
    opacity: 1;
  }
`;

const visibilityIn = keyframes`
  from {
    visibility: hidden;
    height: 0;
  }
  5% {
    visibility: visible;
  }
  to {
    visibility: visible;
    height: 300px;
  }
`;

const visibilityOut = keyframes`
  from {
    visibility: visible;
    height: 300px;
  }
  50% {
    height: 50px;
  }
  to {
    visibility: hidden;
    height: 0;
  }
`;

const StyledPopInRegistrationMobileContainer = styled(StyledPopInRegistrationDefault)<{ displayModal: boolean }>`
  animation: ${({ displayModal }) => displayModal && maxHeight} 1s 1;
  align-items: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  bottom: 0;
  display: ${({ displayModal }) => (displayModal ? 'flex' : 'none')};
  gap: 32px;
  padding: 24px 24px 48px 24px;
  position: fixed;
  > img {
    align-self: flex-end;
    height: 16px;
    transform: rotate(45deg);
    width: 16px;
  }
  > h2 {
    font-size: 20px;
    padding: 0;
  }
  > span {
    font-size: 16px;
  }
  > img,
  h2,
  span {
    animation: ${({ displayModal }) => displayModal && fadeIn} 2s 1;
  }
`;

const StyledCTAMobileWrapper = styled(StyledCTAWrapper)`
  font-size: 14px;
  > button {
    font-size: 14px;
  }
  > div > span:last-child,
  a {
    font-size: 12px;
  }
`;

const StyledWelcomeOfferMobileWrapper = styled(StyledWelcomeOfferWrapper)<{ displayBenefits: boolean }>`
  animation: ${({ displayBenefits }) => (displayBenefits ? visibilityIn : visibilityOut)} 1s 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledImgAndTextMobileWrapper = styled(StyledDefaultWrapper)<{ displayAnimation: boolean }>`
  animation: ${({ displayAnimation }) => (displayAnimation ? maxHeightBenefits : minHeightBenefits)} 1s 1;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  width: 100%;
`;

const StyledBenefitsSpan = styled.span<{ isContainerOpen: boolean }>`
  align-items: end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  > img {
    width: 16px;
    height: 16px;
    ${({ isContainerOpen }) => (isContainerOpen ? 'transform: rotate(0)' : 'transform: rotate(180deg)')};
    transition: transform 700ms ease-in-out;
  }
`;
