import algoliasearch from 'algoliasearch';
import { navigate } from 'gatsby';
import { OtherCategories } from '../../templates/categoryPageAlgolia';
import { RankingFilters } from '../../templates/search';
import { AlgoliaFacetData, AlgoliaItem, RefinementFacet, RenderingContent, SelectedFacet } from '../../typings/AlgoliaTypes';
import { getUrlFragment } from '../contentful';
import { prefixes } from '../urls';
import { sendAndStoreAlgoliaClick } from './analytics';

export interface AlgoliaRefinementListGroupProps {
  setSelectedFacets: (facetName: string, facetOption: string) => void;
  resetFacets: () => void;
  facetsList?: RefinementFacet[];
  selectedFacets?: SelectedFacet[];
  useDefaultFacetsList?: boolean;
  displayedContentCount: number;
  ranKingFilters?: RankingFilters;
  setRankingFilters?: React.Dispatch<React.SetStateAction<RankingFilters>>;
  otherCategoriesInUniverse?: OtherCategories[];
  initialRender?: React.MutableRefObject<boolean>;
  renderingContent?: RenderingContent | undefined;
}

export const EXCLUDED_FACETS = ['Brand_Name___LOV', 'type', 'categories.position', 'categories.id'];
export const DEFAULT_AUTHORISED_SLUG = ['cajas-carton', 'doypack', 'bolsas-papel', 'boite-carton', 'packaging', 'sac-et-sachet'];
export const QUERY_SUGGESTION_INDEX_SUFFIX = '_query_suggestions';

interface Options {
  useQuerySuggestionIndex?: boolean;
  filter?: string;
}

export const getAlgoliaSearchIndex = (props?: Options) => {
  const useSuggestSuffix = props?.useQuerySuggestionIndex;
  const filter = props?.filter;

  let algoliaPageIndexName = process.env.GATSBY_ALGOLIA_PAGE_INDEX_NAME || '';

  switch (filter) {
    case 'latest':
      algoliaPageIndexName = algoliaPageIndexName.concat('_lastupdate_asc');
      break;
    case 'relevance_asc':
      algoliaPageIndexName = algoliaPageIndexName.concat('_price_asc');
      break;
    case 'relevance_desc':
      algoliaPageIndexName = algoliaPageIndexName.concat('_price_desc');
      break;
    default:
      algoliaPageIndexName = algoliaPageIndexName.concat(useSuggestSuffix ? QUERY_SUGGESTION_INDEX_SUFFIX : '');
  }
  const applicationId = process.env.GATSBY_ALGOLIA_APPLICATION_ID || '';
  const searchApiKey = process.env.GATSBY_ALGOLIA_SEARCH_API_KEY || '';

  const client = algoliasearch(applicationId, searchApiKey);
  const index = client.initIndex(algoliaPageIndexName);

  return index;
};

export const toggleFacetValue = (facetName: string, facetValue: string, selectedFacets: SelectedFacet[]): SelectedFacet[] => {
  const facet = selectedFacets.find(facet => facet.label === facetName);

  if (!facet) {
    return [...selectedFacets, { label: facetName, items: [facetValue] }];
  } else {
    const facetValues = facet?.items;
    const facetValueExist = facet?.items?.find(option => option === facetValue);
    // if value exist we remove it
    const newFacetValues = facetValueExist ? facetValues.filter(option => option !== facetValue) : [...facet?.items, facetValue];

    const otherFacets = selectedFacets?.filter(facet => facet.label !== facetName);
    return newFacetValues?.length > 0 ? [...otherFacets, { label: facetName, items: newFacetValues }] : [...otherFacets];
  }
};

export const transformIntoRefinementFacet = (algoliaFacets: AlgoliaFacetData, selectedFacets: SelectedFacet[]): RefinementFacet[] => {
  if (!algoliaFacets) return [];
  const facetsKey = Object.keys(algoliaFacets); // get all facets key value
  return facetsKey?.map(defaultFacet => {
    const items = Object?.keys(algoliaFacets?.[defaultFacet]) // get all the facet's items key value
      ?.map(key => {
        const facetRefined = selectedFacets?.find(facetR => facetR.label === defaultFacet);
        const dynamicFacetsKey = algoliaFacets?.[defaultFacet] ? Object?.keys(algoliaFacets?.[defaultFacet]) : [];

        return {
          label: key,
          count: algoliaFacets?.[defaultFacet]?.[key],
          isRefined: !!facetRefined && !!facetRefined?.items?.find(option => option === key),
          isDisabled: !dynamicFacetsKey?.includes(key)
        };
      })
      ?.sort((a, b) => {
        // sort to get the facet with count undefined (disabled) at the end of the list
        if (a?.count > b?.count || b?.count === undefined) {
          return -1;
        }
        if (a?.count < b?.count || a?.count === undefined) {
          return 1;
        }
        return 0;
      });
    return {
      name: defaultFacet,
      items
    };
  });
};

export const getSelectedFacetsNumber = (selectedFacets?: SelectedFacet[]): number =>
  selectedFacets?.map(family => family?.items?.length)?.reduce((partialSum, a) => partialSum + a, 0) || 0;

export const getDisplayedFacetsList = (useDefaultFacetsList: boolean, facetsList?: RefinementFacet[]): RefinementFacet[] | undefined => {
  const filteredFacets = facetsList?.filter(facet => !EXCLUDED_FACETS.includes(facet.name));
  return filteredFacets;
};

export const navigateToHit = (hit: AlgoliaItem, pagePath?: String) => {
  const getAlgoliaHitUrl = (hit: AlgoliaItem) => {
    const locale = process.env.GATSBY_LOCALE || 'fr';
    const uri =
      hit.type === 'Product' && hit.contentType === 'calculatorPage'
        ? `${pagePath ?? `/${prefixes[locale].custom}`}`
        : hit.type === 'Product' && hit?.contentType !== 'relativeLink' && hit?.productType !== 'amsProductPage'
        ? `${pagePath ?? `/${prefixes[locale].product}`}`
        : '';
    if (hit.path != null && hit.path.universePageSlug != null && hit.path.categoryPageSlug != null && hit.path.productPageSlug != null) {
      return `/${hit.path.universePageSlug}/${hit.path.categoryPageSlug}/${hit.path.productPageSlug}?objectID=${hit.objectID}${getUrlFragment(hit?.linkHash)}`;
    }
    return hit?.slug && `${uri}${hit.slug.charAt(0) === '/' ? '' : '/'}${hit.slug}?objectID=${hit.objectID}${getUrlFragment(hit?.newLinkHash)}`;
  };
  const url = getAlgoliaHitUrl(hit);
  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    sendAndStoreAlgoliaClick('SearchBox', hit);
    if (hit.contentType === 'productPage' || hit.contentType === 'calculatorPage' || hit.productType === 'Goodies') {
      navigate(url);
    } else {
      window.location.href = url;
    }
  };
  return [url, onClick];
};
