import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Logo } from './Logo';
import { TrustPilot } from './TrustPilot';

export interface MainHeaderProps {
  homeUrl?: string;
}

export const MainHeader: FunctionComponent<MainHeaderProps> = ({ homeUrl }) => {
  return (
    <HeaderSection className="section" id="top-header">
      <Hero className="hero">
        <Logo centered href={`${homeUrl || '/'}`} />
        <TrustPilot />
      </Hero>
      <RegisterMark>
        <TopLeft>
          <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
            <rect className="cls-1" y="40" width="33" height="12" />
            <rect className="cls-1" x="40" width="12" height="33" />
          </svg>
        </TopLeft>
        <TopRight>
          <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
            <rect className="cls-1" y="40" width="33" height="12" />
            <rect className="cls-1" x="40" width="12" height="33" />
          </svg>
        </TopRight>
      </RegisterMark>
    </HeaderSection>
  );
};

const HeaderSection = styled.section`
  position: relative;
  margin: 0;
  &&& {
    padding: 0;
  }
`;

const Hero = styled.div``;

const RegisterMark = styled.div`
  position: relative;
  div {
    position: absolute;
    width: 20px;
    height: 20px;
  }
  div svg {
    width: 20px;
    height: 20px;
  }
`;

const TopLeft = styled.div`
  left: -20px;
  svg {
    fill: #fdc74b;
  }
`;

const TopRight = styled.div`
  right: -20px;
  transform: rotate(90deg);
  svg {
    fill: #e94379;
  }
`;
