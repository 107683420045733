import i18n from 'i18next';
import { initReactI18next, getI18n } from 'react-i18next';

const initI18nOptions = (locale: string, translations: any, debug: boolean) => {
  return {
    lng: locale,
    ns: ['translations'],
    defaultNS: 'translations',
    debug,
    resources: { [locale]: translations },
    interpolation: {
      escapeValue: false,
      format: (value: any) => {
        if (value instanceof Date) {
          if (!value.valueOf()) {
            return '-';
          }
          const options: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          };
          const date = new Intl.DateTimeFormat(locale, options);
          return date ? date.format(value) : value;
        }
        return value;
      }
    }
  };
};

const getDebugDefaultValue = () => {
  return process.env.NODE_ENV !== 'production';
};
export const initI18n = (locale: string, translations: any) => {
  const i18nOpts = initI18nOptions(locale, translations, getDebugDefaultValue());
  i18n.use(initReactI18next).init(i18nOpts);
};

export function getResource(...args: any[]) {
  return (i18n.getResource as any).apply(null, args);
}

export function disableI18nDebug() {
  setI18nDebug(false);
}
export function restoreI18nDebug() {
  setI18nDebug(getDebugDefaultValue());
}

export function setI18nDebug(value: boolean) {
  const i18n: any = getI18n();
  const logger = i18n?.translator?.logger;
  if (!logger) {
    console.warn('Cannot change debug mode for i18n as logger was not found');
    return;
  }
  i18n.translator.logger.setDebug(value);
}
