import React from 'react';

const locale = process.env.GATSBY_LOCALE || 'fr';
/**
 * gtm script for pages
 */
export const gtm = (
  <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://metrics.exaprint.${locale}/wpkqmnvti2k45fo.js?aw='+i.replace(/^GTM-/, '')+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-T72G397');`}</script>
);
