import { MenuLink } from './MenuLink';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { StyledHeaderItemContent } from './HeaderDesktop';
import { useTranslation } from 'react-i18next';
import { Icon } from '../common/components/Icon';

export const HeaderContact: FunctionComponent = () => {
  const { t } = useTranslation('sharedComponents');

  return (
    <DesktopContactInfos>
      <Icon name="contact-phone" />
      <StyledHeaderItemContent>
        <ContactMenuLink className="no-decoration" feature="inknpaper" href={t('shared.navigation.contact-path-new')}>
          {t('shared.navigation.contact-us')}
        </ContactMenuLink>
        <PhoneNumberDesktop>{t('shared.navigation.phone-number')}</PhoneNumberDesktop>
      </StyledHeaderItemContent>
    </DesktopContactInfos>
  );
};

const ContactMenuLink = styled(MenuLink)`
  padding: 3px;
  &.no-decoration {
    @media (max-width: 1024px) {
      text-decoration: none;
      color: #323232;
      font-weight: bold;
      &:hover {
        color: inherit;
      }
    }
  }
`;

const DesktopContactInfos = styled.div`
  display: flex;
  @media (max-width: 1279px) {
    display: none !important;
  }
`;

const PhoneNumberDesktop = styled.div`
  padding: 3px;
  width: 170px;
  @media (max-width: 1024px) {
    display: none;
  }
`;
