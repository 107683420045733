import React, { FunctionComponent } from 'react';
import { Icon } from './Icon';
import styled from 'styled-components';

interface Props {
  name: string;
  notifications?: number;
  onClick?: () => void;
}

export const IconWithNotification: FunctionComponent<Props> = ({ name, notifications, onClick }) => {
  return (
    <IconWithNotificationContainer onClick={onClick}>
      <Icon name={name} />
      {!!notifications && <NotificationCircle className="notification-circle">{notifications}</NotificationCircle>}
    </IconWithNotificationContainer>
  );
};

export const IconWithNotificationContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 0 0 transparent;

  .notification-circle {
    &:hover {
      box-shadow: 0 0 4px #e94279;
    }
  }
`;

export const NotificationCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #fc3b30;
`;
