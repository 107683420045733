import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Menu } from '../menu';

import { PrintboxBigMenu } from './PrintboxBigMenu';

interface Data {
  navigation: Menu;
}

const PrintboxBigNavigation = () => (
  <StaticQuery
    query={graphql`
      query BigMenuFromApi {
        navigation {
          kind
          id
          title
          slug
          items {
            kind
            id
            title
            slug
            url
            navigationContent {
              menuLabel
            }
            items {
              kind
              id
              slug
              title
              navigationContent {
                menuLabel
              }
              items {
                kind
                id
                slug
                title
                abcCategoryKey
                navigationContent {
                  menuLabel
                }
              }
            }
          }
        }
      }
    `}
    render={(data: Data) => <PrintboxBigMenu entries={data.navigation.items} />}
  />
);

export default PrintboxBigNavigation;
