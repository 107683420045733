import styled from 'styled-components';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import { useDidomiConsent } from './DidomiConsentContext';
import { useTranslation } from 'react-i18next';

type Dimension = string | number | undefined;

interface VideoPlayerProps {
  loadingComponent?: ReactElement<any, any>;
  height?: Dimension;
  width?: Dimension;
  url: string;
  loading: boolean;
}

export const VideoConsent: FunctionComponent<VideoPlayerProps> = ({ loading, children, loadingComponent, height, width, url }) => {
  const didomiConsent = useDidomiConsent();
  const userCookieAgreement = didomiConsent?.userCookieAgreement;
  const { t } = useTranslation('translations');

  const [consentThisOne, setConsentThisOne] = useState(false);

  const isConsent = consentThisOne || (isYoutubeUrl(url) && userCookieAgreement?.includes('c:youtube'));

  if (isConsent) {
    return (
      <>
        {loading && loadingComponent}
        {children}
      </>
    );
  }

  return (
    <VideoConsentElement height={height} width={width}>
      <div className="video-consent-overlay-text">{t('front.consent.videoDisplay')}</div>
      <div className="video-consent-overlay-accept-button" onClick={() => setConsentThisOne(true)}>
        {t('front.consent.videoAcceptButton')}
      </div>
    </VideoConsentElement>
  );
};

const isYoutubeUrl = (url: string) => {
  const youtubeUrlRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
  return youtubeUrlRegex.test(url);
};

const VideoConsentElement = styled.div<{ width: Dimension; height: Dimension }>`
  width: ${props => props.width ?? '100%'};
  height: ${props => props.height ?? '100%'};
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  color: white;
  > .video-consent-overlay-text {
    text-align: center;
  }
  > .video-consent-overlay-accept-button {
    border-radius: 5px;
    margin: 20px 0 0 0;
    padding: 8px 10px;
    background-color: white;
    color: rgb(50, 50, 50);
    cursor: pointer;
    & :hover {
      background-color: rgb(50, 50, 50);
      color: white;
    }
  }
`;
