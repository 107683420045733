import React from 'react';
import styled from 'styled-components';
import { MenuEntry, isExternalLink } from '../menu';
import { getPPAGMenuLabel } from '../navigation/Category';
import { PrintboxEntryLink } from './EntryLink';

interface Props {
  entries: MenuEntry[];
}

const PrintboxBigMenu = ({ entries }: Props) => {
  const bigMenuList = entries.map((entry, index) => {
    return (
      <BigMenuItem key={index} className={isExternalLink(entry) ? '' : 'selected'}>
        <BigMenuItemLink entry={entry}>{getPPAGMenuLabel(entry)}</BigMenuItemLink>
      </BigMenuItem>
    );
  });
  return (
    <div className="columns" id="big-menu">
      <div className="column">
        <BigMenuContainer className="navbar">
          <BigMenuList className="navbar-menu is-active is-clearfix">{bigMenuList}</BigMenuList>
        </BigMenuContainer>
      </div>
    </div>
  );
};

const BigMenuContainer = styled.nav`
  &.navbar {
    z-index: 1;
  }
  position: relative;
  &&& {
    display: block;
    background: #fafafa;
  }
`;

const BigMenuList = styled.ul`
  border: none;
  margin-left: 10px;
  list-style-type: none;
  padding: 15px 0 0;
  display: flex;
  height: 40px;
  &&& {
    box-shadow: none;

    @media screen and (min-width: 769px) {
      display: flex;
    }
  }
`;

const BigMenuItem = styled.li`
  padding: 0;
  float: left;
  text-transform: uppercase;
  display: block;
  border-right: 1px solid #cecece;
  text-align: center;
  height: 26px;
  margin: 0;
  font-size: 10.5px;
  flex: auto;

  &.selected a {
    border-bottom: 2px solid #dc004c;
  }
  &:last-child {
    border: 0;
  }
`;
const BigMenuItemLink = styled(PrintboxEntryLink)`
  margin: 7px 8px 14px;
  padding: 0 0 2px;
  display: block;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  color: #000;

  &:hover {
    border-bottom: 2px solid #dc004c;
  }
`;

export { PrintboxBigMenu };
