import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  onClose: () => void;
  href: string;
}

export const FunnelExit: FunctionComponent<Props> = ({ onClose, href }) => {
  const { t } = useTranslation('sharedComponents');
  return (
    <FunnelExitContainer>
      <TextContainer>{t('shared.funnelExit.exitText')}</TextContainer>
      <ButtonContainer>
        <Button className={'subdued'} href={href}>
          {t('shared.funnelExit.exitHome')}
        </Button>
        <Button onClick={onClose}>{t('shared.funnelExit.finalizeOrder')}</Button>
      </ButtonContainer>
    </FunnelExitContainer>
  );
};

const FunnelExitContainer = styled.div`
  padding: 16px;
  position: absolute;
  z-index: 99999;
  background: #ffffff;
  top: 84px;
  width: 22rem;
  box-shadow: rgb(0 0 0 / 20%) 0 0 10px 1px;
  border: none;

  &:after {
    content: '';
    position: absolute;
    left: 80px;
    top: -10px;
    width: 20px;
    height: 20px;
    box-shadow: 6px 5px 9px -9px black;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: white;
  }
`;

const TextContainer = styled.div`
  font-size: 12px;
  text-align: center;
  line-height: 1.6;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 10px;

  > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Button = styled.a`
  background-color: #fdc744;
  padding: 12px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 2px 6px 1px rgb(50 50 50 / 20%);
  color: black;

  &:hover {
    background-color: black;
    color: white;
  }

  &.subdued {
    background-color: transparent;
    text-decoration: underline;
    box-shadow: none;

    &:hover {
      background-color: transparent;
      color: black;
    }
  }
`;
