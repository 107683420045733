import React, { FunctionComponent, ReactElement, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { VideoConsent } from '../../components/Consent/VideoConsent';

type Dimension = string | number | undefined;

interface VideoPlayerProps extends ReactPlayerProps {
  alt?: string;
  height?: Dimension;
  width?: Dimension;
  url: string;
  loadingComponent?: ReactElement<any, any>;
}

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({ onReady: previousOnReady, loadingComponent, alt, height, width, url, ...props }) => {
  const [loading, setLoading] = useState(true);

  const onReady = (player: ReactPlayer) => {
    previousOnReady && previousOnReady(player);
    setLoading(false);
  };
  return (
    <VideoConsent loading={loading} height={height} width={width} url={url} loadingComponent={loadingComponent}>
      <ReactPlayer onReady={onReady} alt={alt} height={height ?? '100%'} width={width ?? '100%'} url={url} controls {...props} />
    </VideoConsent>
  );
};
