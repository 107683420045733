import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { SimpleContent } from '../../../../generated/contentful-generated-types';
import { MenuItem } from '../../MenuUtils';
import Left from './Left';
import Main from './Main';

interface Props {
  setMenuVisible: (isMenuVisible: boolean) => void;
  menuItems: MenuItem[];
}

interface Link {
  id: string;
  label: string;
  href?: string;
}

interface LinkContent {
  id: string;
  label: string;
  children: MenuItem[];
  href?: string;
  menuHighlight?: SimpleContent;
}

export const BigMenu: FunctionComponent<Props> = ({ setMenuVisible, menuItems }) => {
  const [selectedId, setSelectedId] = useState('0');
  const selectId = (id: string) => {
    setMenuVisible(true);
    setSelectedId(id);
  };
  const deSelectedLinkId = () => {
    setMenuVisible(false);
    setSelectedId('');
  };

  const closeBigMenu = () => {
    setMenuVisible(false);
  };

  const defaultSelectedId = '0';
  const links: Link[] = [];
  const linksContent: LinkContent[] = [];

  menuItems.forEach(({ href, label, children = [], menuHighlight }, index) => {
    links.push({ id: index.toString(), href, label });
    linksContent.push({ id: index.toString(), label, href, children, menuHighlight });
  });

  return (
    <StyledMainContainer onMouseLeave={deSelectedLinkId}>
      <Left links={links} selectedId={selectedId || defaultSelectedId} closeBigMenu={closeBigMenu} selectId={selectId} />
      <Main linksContent={linksContent} selectedId={selectedId || defaultSelectedId} closeBigMenu={closeBigMenu} />
    </StyledMainContainer>
  );
};

const StyledMainContainer = styled.div`
  display: flex;
  border: 1px solid #e0e0e0;
  width: 100%;
  z-index: 4;
`;
