import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Logo } from './Logo';
import { HeaderProps } from './Header';
import { MenuBurgerButton } from '../components/menu/components/MenuBurgerButton';
import { QueryStatus } from '../common/components/QueryStatus';
import { isLoggedIn, useGetLogin } from '../hooks/useLogin';
import { MiniCartINP } from '../cart/MiniCartINP';
import { useTranslation } from 'react-i18next';
import { NewSearchBox } from '../components/newSearchBox';
import { UserMenu } from './UserMenu';
import { StyledHeaderUser, StyledHeaderItemContent } from './HeaderDesktop';

interface Props extends HeaderProps {
  isWindow: boolean;
  gtmPushData: any;
  toggleMenu: () => void;
}

export const HeaderMobile: FunctionComponent<Props> = ({ toggleMenu, hideNavigation }) => {
  const { t } = useTranslation('sharedComponents');
  const useLoginResult = useGetLogin();
  const [isSearchInputOpen] = useState(true);
  const loggedIn = isLoggedIn(useLoginResult);

  return (
    <HeaderMobileContainer>
      <MobileTopLine>
        <ButtonContainerLeft>{!hideNavigation && <MenuBurgerButton onClick={() => toggleMenu()} />}</ButtonContainerLeft>
        <div className="topline-left">
          <Logo href="/">
            <Baseline>{t('shared.logo.baseline')}</Baseline>
          </Logo>
        </div>
        {useLoginResult?.error && <QueryStatus error={useLoginResult?.error} />}
        <ButtonContainerRight>
          {useLoginResult?.data && (
            <StyledHeaderUser data-testid="header-user">
              <StyledHeaderItemContent>
                <UserMenu feature="inknpaper" loginData={useLoginResult.data} />
              </StyledHeaderItemContent>
            </StyledHeaderUser>
          )}
          {loggedIn && <MiniCartINP />}
        </ButtonContainerRight>
      </MobileTopLine>
      <MobileSearchContainer open={isSearchInputOpen}>
        <NewSearchBox />
      </MobileSearchContainer>
    </HeaderMobileContainer>
  );
};

const HeaderMobileContainer = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
`;

const ButtonContainerLeft = styled.div`
  width: 70px;
`;

const ButtonContainerRight = styled.div`
  width: 110px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  margin-right: 20px;

  @media (max-width: 480px) {
    margin-right: 0;
  }
`;

const MobileTopLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: white;
  z-index: 9999;

  .topline-left {
    flex: 1;
    display: flex;
    justify-content: center;
  }
`;
interface MobileSearchContainerProps {
  open?: boolean;
}

const MobileSearchContainer = styled.div<MobileSearchContainerProps>`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => (props.open ? '0' : '-60px')};
  transition: all 0.3s;
  background-color: white;
  z-index: 999;

  .autocomplete-result-container {
    top: ${props => (props.open ? '110px' : '60px')};
  }
`;

const Baseline = styled.p`
  font-family: var(--font-styrene);
  padding-left: 2px;
  font-size: 10px;
  line-height: 10px;
`;
