import React, { useContext, useEffect, useState } from 'react';
import { acceptCookieInsights } from '../../utils/algolia/searchInsights';
import { acceptSentryReplay } from '../../utils/sentry';

interface DidomiConsentContextType {
  userCookieAgreement?: string;
}

export interface DidomiContentType {
  didomiVendorsConsent: string;
  didomiVendorsConsentUnknown: string;
}

export const DidomiConsentContext = React.createContext<DidomiConsentContextType | undefined>(undefined);

const checkDidomiConsent = (setDidomiConsent: (consent: DidomiContentType) => void) => {
  const datalayer: Array<any> = (typeof window !== 'undefined' && (window as any).dataLayer) || [];
  const didomiConsent = datalayer.find(item => item.event === 'didomi-consent' && item.didomiVendorsConsentUnknown === '');

  if (didomiConsent) {
    setDidomiConsent(didomiConsent);
    clearInterval(interval);
    if (didomiConsent.didomiVendorsConsent.includes('c:algolia')) {
      acceptCookieInsights();
    }
    if (didomiConsent.didomiVendorsConsent.includes('c:sentry')) {
      acceptSentryReplay();
    }
  }
};

export const useDidomiConsent = () => useContext(DidomiConsentContext);

let interval: NodeJS.Timeout;

interface Props {}

export const DidomiConsentContextProvider: React.FC<Props> = ({ children }) => {
  const [didomiConsent, setDidomiConsent] = useState<DidomiContentType | undefined>(undefined);

  useEffect(() => {
    if (!didomiConsent) {
      interval = setInterval(() => {
        checkDidomiConsent(setDidomiConsent);
      }, 500);
    }
    return () => clearInterval(interval);
  }, []);

  const userCookieAgreement = didomiConsent?.didomiVendorsConsent;

  return <DidomiConsentContext.Provider value={{ userCookieAgreement }}>{children}</DidomiConsentContext.Provider>;
};
