import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { useIsWindowWidthBelow } from '../../hooks/useWindowSize';
import { useIsLoggedIn, useIsRegisteredNonBuyer, useIsReseller } from '../../hooks/useLogin';
import { showForUserMultiple } from '../../utils/contentful';
import { get } from 'lodash';
import { LoadableEntryLink } from './LoadableEntryLink';

import {
  FooterBlocksCollection,
  FooterBlockLinksItem,
  RelativeLink,
  ExternalLink,
  SimplePage,
  SimplePageNew,
  ContentBox
} from '../../generated/contentful-generated-types';
import { EXA_COLORS, getColorFromCode, MOBILE_SIZE } from '../../common/constants';

import logo from '../../../assets/icons/footer-exaprint.svg';
import chevron from '../../../assets/icons/chevron_bas.svg';

type ContentfulNode = { __typename: string };

interface MobileLinkContainerProps {
  links?: FooterBlockLinksItem[];
  title?: string;
  titleColor?: string;
  isLoggedIn: boolean;
  isReseller: boolean;
  isRegisteredNonBuyer: boolean;
}

const isContentBoxDisplayedForUser = (link: FooterBlockLinksItem, isReseller: boolean, isLoggedIn: boolean, isRegisteredNonBuyer: boolean) => {
  const isContentBoxForUser =
    (link as ContentfulNode).__typename === 'Contentful_ContentBox' &&
    showForUserMultiple((link as ContentBox).showOnlyForMultiple, isReseller, isLoggedIn, isRegisteredNonBuyer);
  const isResellerOrNotLogged = !get(link, 'resellerOnly') || !isLoggedIn || isReseller;

  return isContentBoxForUser || isResellerOrNotLogged;
};

const isItemsListDisplayedForUser = (links: FooterBlockLinksItem[], isReseller: boolean, isLoggedIn: boolean, isRegisteredNonBuyer: boolean) =>
  !!links.find(link => isContentBoxDisplayedForUser(link, isReseller, isLoggedIn, isRegisteredNonBuyer));

const ItemsLinkList: React.FC<MobileLinkContainerProps> = ({ title, links, isReseller, isLoggedIn, isRegisteredNonBuyer }) => {
  return (
    <ul>
      {links?.map((link, index) => {
        if ((link as ContentfulNode).__typename === 'Contentful_FooterBlockSubtitle') return;
        if (isContentBoxDisplayedForUser(link, isReseller, isLoggedIn, isRegisteredNonBuyer)) {
          const linkable = link as RelativeLink | ExternalLink | SimplePage | SimplePageNew;
          return (
            <li key={`footerlink-${title}-${index}`}>
              <LoadableEntryLink id={`footersublink-${title}-${index}`} entry={linkable} text={linkable.title} />
            </li>
          );
        }
      })}
    </ul>
  );
};

const MobileLinkContainer: React.FC<MobileLinkContainerProps> = ({ title, titleColor, ...rest }) => {
  const [isContainerOpen, setContainerOpen] = useState<boolean>(false);
  return (
    <StyledCategoryListMobileWrapper
      key={`${title} category`}
      color={getColorFromCode(titleColor, EXA_COLORS.white ?? '#ebebeb')}
      isContainerOpen={isContainerOpen}
    >
      <div onClick={() => setContainerOpen(prevState => !prevState)}>
        <span>{title}</span>
        <img src={chevron} alt="list's icon" />
      </div>
      <ItemsLinkList {...rest} />
    </StyledCategoryListMobileWrapper>
  );
};

export const NewFooterLink: React.FC<{ blocks?: FooterBlocksCollection }> = ({ blocks }) => {
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);
  const isLoggedIn = useIsLoggedIn();
  const isReseller = useIsReseller();
  const isRegisteredNonBuyer = useIsRegisteredNonBuyer();

  if (isMobile)
    return (
      <StyledLinkMobileContainer>
        {blocks?.items?.map((item, index) => {
          if (!isItemsListDisplayedForUser((item?.linksCollection?.items as FooterBlockLinksItem[]) ?? undefined, isReseller, isLoggedIn, isRegisteredNonBuyer))
            return;
          return (
            <MobileLinkContainer
              title={item?.title ?? undefined}
              titleColor={item?.titleColor ?? undefined}
              links={(item?.linksCollection?.items as FooterBlockLinksItem[]) ?? undefined}
              isLoggedIn={isLoggedIn}
              isReseller={isReseller}
              isRegisteredNonBuyer={isRegisteredNonBuyer}
              key={item?.sys?.id || index}
            />
          );
        })}
      </StyledLinkMobileContainer>
    );

  return (
    <StyledLinkContainer>
      {blocks?.items?.map((item, index) => {
        if (!isItemsListDisplayedForUser((item?.linksCollection?.items as FooterBlockLinksItem[]) ?? undefined, isReseller, isLoggedIn, isRegisteredNonBuyer))
          return;
        return (
          <StyledCategoryListWrapper titleColor={getColorFromCode(item?.titleColor, EXA_COLORS.white ?? '#ebebeb')} key={`${item?.title} category`}>
            {index === 0 ? <img src={logo} alt="logo exaprint"></img> : <span>{item?.title}</span>}
            <ItemsLinkList
              title={item?.title ?? undefined}
              titleColor={item?.titleColor ?? undefined}
              links={(item?.linksCollection?.items as FooterBlockLinksItem[]) ?? undefined}
              isLoggedIn={isLoggedIn}
              isReseller={isReseller}
              isRegisteredNonBuyer={isRegisteredNonBuyer}
            />
          </StyledCategoryListWrapper>
        );
      })}
    </StyledLinkContainer>
  );
};

const StyledLinkContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledLinkMobileContainer = styled(StyledLinkContainer)`
  flex-direction: column;
  gap: 1.5rem;
`;

const StyledCategoryListWrapper = styled.div<{ titleColor: string }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > span {
    color: ${({ titleColor }) => titleColor};
    font-family: 'Styrene Medium', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    align-items: end;
    display: flex;
    height: 45px;
  }

  > img {
    height: 45px;
  }

  > ul {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-width: 200px;
    width: 100%;
  }

  & a {
    color: #ebebeb;
    font-family: 'StyreneA', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    transition: color 0.2s ease-in;

    &:hover {
      color: #fff;
    }
  }
`;

const spinOpen = keyframes`
  100% { transform: rotate(180deg); }
`;

const spinClose = keyframes`
  0% { transform: rotate(180deg); }
  100% { transform: rotate(-0deg); }
`;

const StyledCategoryListMobileWrapper = styled(StyledCategoryListWrapper)<{ color?: string; isContainerOpen: boolean }>`
  flex-shrink: 0;
  gap: ${({ isContainerOpen }) => (isContainerOpen ? '1.5rem' : 0)};
  > div {
    align-items: end;
    display: flex;
    justify-content: space-between;
    width: 100%;

    > span {
      color: ${({ color }) => (color ? color : '#ebebeb')};
      font-size: 16px;
      line-height: 20px;
    }

    > img {
      height: 16px;
      width: 16px;
      transform: rotate(0deg);
      animation: ${({ isContainerOpen }) => (isContainerOpen ? spinOpen : spinClose)} 500ms 1 forwards;
    }
  }

  > ul {
    gap: 1rem;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.3s ease-in-out;
    ${({ isContainerOpen }) =>
      isContainerOpen &&
      `
      max-height: 350px;
      opacity: 1;
    `}
  }

  & a {
    font-size: 12px;
    line-height: 17px;
  }
`;
