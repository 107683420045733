import React, { FC } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  size?: number;
}

type StyledProps = Pick<Props, Exclude<keyof Props, 'name'>>;

export const Icon: FC<Props> = props => {
  const { className, name, ...otherProps } = props;
  return <StyledIcon className={classNames(className, `icon-${name}`)} {...otherProps} />;
};

const StyledIcon = styled.div<StyledProps>`
  font-size: ${props => (props.size != undefined ? props.size + 'px' : '40px')};
  display: flex;
  &.icon-user-notifications,
  &.icon-compte-client,
  &.icon-contact-phone,
  &.icon-panier,
  &.icon-mon-suivi,
  &.icon-promos,
  &.icon-products,
  &.icon-avantages,
  &.icon-outils,
  &.icon-services,
  &.icon-idea,
  &.icon-loadingbar {
    font-size: 22px;
    &::before {
      height: 32px;
      min-width: 32px;
      display: flex;
      line-height: 32px;
      align-items: center;
      justify-content: center;

      @media (max-width: 480px) {
        height: 24px;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }

  &.icon-contact-phone {
    margin: auto;
    &:hover {
      cursor: pointer;
    }
  }

  &.icon-mon-suivi,
  &.icon-compte-client,
  &.icon-contact-phone {
    color: #323232;
  }
`;
