import React, { Component } from 'react';
import { ButtonBaseProps } from './ButtonBase';
import styled from 'styled-components';

interface SubmitButtonProps {
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

export interface SubmitButtonState {
  loading: boolean;
}

export class LegacySubmitButton extends Component<ButtonBaseProps, SubmitButtonState> {
  constructor(props: ButtonBaseProps) {
    super(props);
    this.state = {
      loading: false
    };
  }

  public render() {
    const disabled = !!this.props.disabled || this.state.loading;
    return (
      <StyledSubmitButton
        name={this.props.name}
        type="submit"
        className={`button ${this.props.className}`}
        disabled={disabled}
        loading={this.state.loading}
        onClick={this.onClick}
      >
        {this.props.label}
        {this.state.loading && <SmallSpinner style={{ marginLeft: 10 }} />}
      </StyledSubmitButton>
    );
  }

  private onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    // do not submit the form in which the button is used
    e.preventDefault();

    this.setState({ loading: true });
    if (this.props.onClick) {
      await this.props.onClick(e);
      this.setState({ loading: false });
    }
  };
}

const StyledSubmitButton = styled.button`
  &.button {
    border-color: ${(props: SubmitButtonProps) => (props.loading || props.disabled ? 'lightgray' : '#e30059')};
    background-color: ${(props: SubmitButtonProps) => (props.loading || props.disabled ? 'lightgray' : '#e30059')};
    cursor: ${(props: SubmitButtonProps) => props.loading && 'progress'};

    color: #ffffff;
    border-radius: 1px;

    padding-right: 10px;

    &:hover {
      background-color: ${(props: SubmitButtonProps) => (props.loading || props.disabled ? 'lightgray' : '#f42e8a')};
      color: #ffffff;
    }

    &&& {
      &:disabled {
        background-color: lightgray;
      }
    }
  }
`;

export const SmallSpinner = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  width: 20px;
  height: 20px;
  animation: spinAround 0.5s infinite linear;
  border: 2px solid white;
  border-radius: 10px;
  border-color: transparent transparent #fff #fff !important;
`;
