import { gql } from '@apollo/client';

import { CART_PRICE_FRAGMENT } from './cart-price.fragment';
import { PRICE_VALUE_FRAGMENT } from '../../../common/graphql/fragments/price-value.fragment';
import { CART_LINEITEM_VARIANT_FRAGMENT } from './cart-line-item-variant.fragment';

export const CART_LINE_ITEM_FRAGMENT = gql`
  fragment CartLineItem on LineItem {
    id
    name
    isAvailable
    quantity
    productId
    price {
      ...CartPrice
    }
    totalPrice {
      ...PriceValue
    }
    variant {
      ...LineItemVariant
    }
    designerCustomizedProductUrl
    reviewInstructions
    merchandisingProductName
    merchandisingProductCategory
    ruleBased
    previewURL
    mcpSku
    productContainerId
    promisedArrivalDate
    currentStock
    finalSelection
    translatedSelection
  }
  ${CART_PRICE_FRAGMENT}
  ${PRICE_VALUE_FRAGMENT}
  ${CART_LINEITEM_VARIANT_FRAGMENT}
`;
