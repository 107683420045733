import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HeaderDesktop } from './HeaderDesktop';
import { HeaderMobile } from './HeaderMobile';
import { useGetLogin, isLoggedIn, useIsRegisteredNonBuyer } from '../hooks/useLogin';
import { MenuItem } from '../components/menu/MenuUtils';
import { Menu } from '../components/menu/Menu';
import { useIsMounted } from '../hooks/useIsMounted';
import { useIsWindowWidthBelow } from '../hooks/useWindowSize';
import { LOCAL_STORAGE_KEY_POP_IN_REGISTRATION, LOCAL_STORAGE_KEY_PROMO_BAR_DISPLAY_CACHE, MOBILE_SIZE } from '../common/constants';
import { SESSION_STORAGE_CUSTOMER_NUMBER } from '../login/auth';
import { SimpleContent, Maybe } from '../generated/contentful-generated-types';
import { HeaderPromoBar } from './HeaderPromoBar';
import { RegistrationModal } from '../components/RegistrationModal';
//import { CustomerService, ZendeskVisibility } from '../components/CustomerService';

export interface HeaderProps {
  hideNavigation?: boolean;
  isFunnel?: boolean;
  pageType?: string;
  onBurgerClick?: () => void;
  rootMenuItems: MenuItem[];
  topBanners?: Maybe<SimpleContent>[] | undefined;
  isMinimalistHeader: boolean;
}

// to be replaced when rebased on top of PH-689
const gtmPushData = <T extends any>(data: T) => {
  ((window as any).dataLayer = (window as any).dataLayer || []).push(data);
};

export const Header: FunctionComponent<HeaderProps> = ({ ...props }) => {
  const isWindow = typeof window !== 'undefined';
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);

  const { hideNavigation, rootMenuItems, isFunnel, topBanners, isMinimalistHeader } = props;
  const [menuContentVisible, setMenuContentVisible] = useState<boolean>(false);
  const toggleMenu = () => setMenuContentVisible(!menuContentVisible);

  const useLoginResult = useGetLogin();
  const loggedIn = isLoggedIn(useLoginResult);
  const isRegisteredNonBuyer = useIsRegisteredNonBuyer();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (loggedIn) {
      const customerNumber = useLoginResult.data?.login?.customerNumber;
      if (customerNumber) {
        sessionStorage.setItem(SESSION_STORAGE_CUSTOMER_NUMBER, customerNumber);
      }
    } else {
      sessionStorage.removeItem(SESSION_STORAGE_CUSTOMER_NUMBER);
    }
  }, [loggedIn]);

  // Display decision of PromoBar is cached in localStorage to prevent flickering when reloading the page (happen mostly for AMS)
  const shouldDisplayPromoBarAndRegistrationModal = (): { displayPromoBar: boolean; displayRegistrationModal: boolean; displayCustomerService: boolean } => {
    if (!isMounted && process.env.SHARED_COMPONENT_MODE == undefined) {
      // Component is not yet mounted, we display the Promobar until we got more infos
      // We don't do this step for SHARED_COMPONENT_MODE (ams side) as there is no SSR so no react rehydratation issues
      return { displayPromoBar: true, displayRegistrationModal: false, displayCustomerService: false };
    }
    // User infos are still loading, we try to ge the answer from cache
    else if (useLoginResult.loading) {
      return {
        displayPromoBar: localStorage?.getItem(LOCAL_STORAGE_KEY_PROMO_BAR_DISPLAY_CACHE) == 'true' ?? true,
        displayRegistrationModal: false,
        displayCustomerService: false
      };
    } else {
      // User info are now available, we now return the definitive value
      const shouldDisplay = !loggedIn || isRegisteredNonBuyer;
      window?.localStorage.setItem(LOCAL_STORAGE_KEY_PROMO_BAR_DISPLAY_CACHE, shouldDisplay.toString());
      return {
        displayPromoBar: shouldDisplay,
        displayRegistrationModal: window?.localStorage?.getItem(LOCAL_STORAGE_KEY_POP_IN_REGISTRATION) !== 'false' ?? true,
        displayCustomerService: !loggedIn || isRegisteredNonBuyer
      };
    }
  };
  const { displayPromoBar, displayRegistrationModal } = shouldDisplayPromoBarAndRegistrationModal();

  //useEffect(() => ZendeskVisibility(displayCustomerService), [displayCustomerService]);

  return (
    <>
      {displayPromoBar && <HeaderPromoBar topBanners={topBanners} />}
      {!loggedIn && displayRegistrationModal && <RegistrationModal />}
      {/*displayCustomerService && <CustomerService isMobile={isMobile} />*/}
      <StyledHeaderContainer>
        {isMobile || (
          <StyledDesktop>
            <HeaderDesktop
              isFunnel={isFunnel}
              {...props}
              isWindow={isWindow}
              gtmPushData={gtmPushData}
              toggleMenu={toggleMenu}
              isMinimalistHeader={isMinimalistHeader}
            />
          </StyledDesktop>
        )}
        {!isMobile || (
          <StyledMobile>
            <HeaderMobile {...props} isWindow={isWindow} gtmPushData={gtmPushData} toggleMenu={toggleMenu} />
          </StyledMobile>
        )}
        {!hideNavigation && (
          <>
            <Menu
              rootMenuItems={rootMenuItems}
              menuContentVisible={menuContentVisible}
              setMenuContentVisible={setMenuContentVisible}
              isFunnel={isFunnel}
              isMinimalistHeader={isMinimalistHeader}
            />
          </>
        )}
      </StyledHeaderContainer>
    </>
  );
};

const StyledHeaderContainer = styled.div`
  min-height: 117px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: white;
  font-family: StyreneA, sans-serif;
  line-height: 19px;
  color: #000;

  @media (max-width: 1024px) {
    height: auto;
  }
`;

const StyledDesktop = styled.div``;
const StyledMobile = styled.div``;
