import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import burgerSvg from '../../../../assets/icons/burger.svg';

interface Props {
  onClick: React.MouseEventHandler;
}

export const MenuBurgerButton: FunctionComponent<Props> = ({ onClick }) => {
  const { t } = useTranslation('sharedComponents');
  return (
    <Container className="first-step" onClick={onClick}>
      <Icon src={burgerSvg} alt="" />
      <Subtitle>{t('shared.menu.label')}</Subtitle>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 70px;
  align-items: center;

  &:hover {
    filter: invert(100%) sepia(100%) saturate(10000%) hue-rotate(-80deg);
  }
`;

const Subtitle = styled.div`
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 700;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Icon = styled.img`
  width: 31px;
  height: 31px;
`;
