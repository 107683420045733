import React, { ReactNode } from 'react';
import get from 'lodash/get';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document, BLOCKS, INLINES, Block, Inline } from '@contentful/rich-text-types';

import styled from 'styled-components';
import { Link } from 'gatsby';
import { getTypeName, isEmoticons, isExternalLink } from '../../utils/contentful';
import { RichText, EntryHyperLink, EntryEmbedded } from './types';
import EntryLink from '../../components/EntryLink';
import { Asset, LandingPage } from '../../generated/contentful-generated-types';
import { VideoPlayer } from '../../common/components/videoPlayer';
import { getColorFromCode } from '../../common/constants';

export function useRichText(richText: RichText | null | undefined, components: { Paragraph?: React.ComponentType; UnorderedList?: React.ComponentType } = {}) {
  const {
    Paragraph = ({ children }: React.PropsWithChildren<{}>) => <StyledParagraph>{children}</StyledParagraph>,
    UnorderedList = ({ children }: React.PropsWithChildren<{}>) => <StyledUnorderedList>{children}</StyledUnorderedList>
  } = components;
  const descriptionOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => <Paragraph>{children}</Paragraph>,
      [BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => <UnorderedList>{children}</UnorderedList>,
      [BLOCKS.OL_LIST]: (node: Block | Inline, children: ReactNode) => <StyledOl>{children}</StyledOl>,
      [BLOCKS.HEADING_6]: (node: Block | Inline, children: ReactNode) => (
        <MentionParagraph>
          <em>{children}</em>
        </MentionParagraph>
      ),
      [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
        return (
          <a href={get(node, 'data.uri', '#')} rel="noopener" target="_blank">
            {children}
          </a>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
        const entryHyperlinks = (get(richText, 'links.entries.hyperlink') as EntryHyperLink[]) || [];
        const hyperlink = entryHyperlinks.find(entry => !!entry && entry.sys.id === node.data.target.sys.id);
        if (node.nodeType === 'entry-hyperlink') {
          if (hyperlink && getTypeName(hyperlink) === 'LandingPage') {
            return <Link to={`/${(hyperlink as LandingPage).slug}`}>{children}</Link>;
          }
          if (hyperlink && getTypeName(hyperlink) === 'HomePage') {
            return <Link to={'/'}>{children}</Link>;
          }

          return hyperlink && <EntryLink entry={hyperlink}>{children}</EntryLink>;
        } else return null;
      },
      [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline) => {
        const entries = get(richText, 'links.entries.inline') || (get(richText, 'links.inline.hyperlink') as EntryEmbedded[]) || [];
        const results = entries.find((entry: EntryEmbedded) => !!entry && entry.sys.id === node.data.target.sys.id);
        if (results) {
          if (isExternalLink(results) && results.url && results.url?.includes('youtube')) {
            return (
              <CustomContainer>
                <VideoPlayer url={results.url} alt={results.title || ''} />
              </CustomContainer>
            );
          } else if (isEmoticons(results)) {
            return results.emoticonAsset?.url ? (
              <CustomTooltipImg src={results.emoticonAsset?.url} alt={results.emoticonAsset?.description || results.emoticonAsset?.title || ''} />
            ) : null;
          }
        }
      },

      [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
        const { id } = node.data.target.sys;
        const block = (get(richText, 'links.assets.block') as Asset[]) || (get(richText, 'links.assets.hyperlink') as Asset[]) || [];
        const asset = block.find(candidate => !!candidate && candidate.sys?.id === id && !!candidate.url);
        if (asset?.url && asset.contentType && asset.contentType.includes('video')) {
          return (
            <CustomContainer>
              <VideoPlayer url={asset.url} alt={asset.title || asset.description || ''} />
            </CustomContainer>
          );
        }
        return asset && asset.url ? <img src={asset.url} alt={asset.description || asset.title || ''} /> : null;
      }
    },

    renderText: (text: string) => {
      const [head, ...tail] = text.split('\n');
      return [head, tail.map((text, index) => [<br key={`br-${index}`} />, text])];
    }
  };

  return richText && documentToReactComponents(richText.json as Document, descriptionOptions);
}
const CustomContainer = styled.div`
  height: 500px;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    width: 100%;
    height: 380px;
  }
  @media (max-width: 620px) {
    width: 100%;
    height: auto;
  }
`;

const CustomTooltipImg = styled.img`
  position: relative;
  top: 2px;
  margin-left: 2px;
  margin-right: 2px;
  width: 14px;
  height: 14px;
`;
const StyledOl = styled.ol`
  padding-inline-start: 15px;
`;

const StyledUnorderedList = styled.ul`
  padding-left: 35px;
  list-style: inherit;
`;

const StyledParagraph = styled.p`
  line-height: 24px;
  font-size: 14px;
  color: ${getColorFromCode('exa black', '#323232')};
`;

const MentionParagraph = styled.p`
  font-size: 12px;
  line-height: 1.5;
`;
