import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IconWithNotificationContainer, NotificationCircle } from './IconWithNotification';

interface Props {
  src: string;
  notificationsCount?: number;
  onClick?: () => void;
  imageHeight?: number;
  mouseOver?: boolean;
}

export const ImageIcon: FunctionComponent<Props> = ({ src, notificationsCount, onClick, imageHeight, mouseOver }) => {
  const options = mouseOver ? { onMouseOver: onClick } : { onClick: onClick };

  return (
    <IconWithNotificationContainer {...options}>
      <StyledImage imageHeight={imageHeight} src={src} alt="login" />
      {!!notificationsCount && <NotificationCircle className="notification-circle">{notificationsCount}</NotificationCircle>}
    </IconWithNotificationContainer>
  );
};

const StyledImage = styled.img<{ imageHeight: number | undefined }>`
  height: ${props => (props.imageHeight ? props.imageHeight : 24)}px;
  cursor: pointer;
  filter: invert(1) brightness(0.2);
`;
