import { ContentBox, Maybe, Menu, UniversePage } from '../../generated/contentful-generated-types';
import { isCalculatorPage, isCategoryPage, isProductPage } from '../../utils/contentful';

export const extractSlugFromProductParents = (contentBox: ContentBox, slug: string) => {
  // object we will use to store matching slugs
  const slugs: { [key: string]: string } = {
    product: slug
  };

  // Step 1 : find a parent category which is linked to a Universe
  const parentCategories = contentBox.linkedFrom?.menuCollection?.items as Menu[];
  if (!parentCategories || parentCategories.length === 0) {
    return null;
  }
  let universePage: Maybe<UniversePage> | undefined;
  let categoryPageContentBox: Maybe<ContentBox> | undefined;

  // Step 2 : we need to find a Category linked to a Universe Page, AND ensure that this link occured in universePage.categoryPagesCollection
  parentCategories.forEach(category => {
    category?.linkedFrom?.contentBoxCollection?.items.forEach(contentBox => {
      if (universePage && categoryPageContentBox) {
        return;
      }
      universePage = contentBox?.linkedFrom?.universePageCollection?.items.find(universe => {
        // NOTE : we need to check this because the "linkedFrom" list is generated by Contentful with ANY link between these entries.
        // Nevertheless, the only link that really determines if this universe is the parent of the category, is a link within "categoryPages" attribute
        categoryPageContentBox = universe?.categoryPagesCollection?.items.find(item => item?.sys.id === contentBox.sys.id);
        return !!categoryPageContentBox;
      });
    });
    if (universePage && categoryPageContentBox) {
      return;
    }
  });
  if (!categoryPageContentBox?.content?.slug) return null;
  slugs.category = categoryPageContentBox.content.slug;

  if (!universePage?.slug) return null;
  slugs.universe = universePage.slug;

  return `/${slugs.universe}/${slugs.category}/${slugs.product}`;
};

export const extractSlugFromCategoryParents = (contentBox: ContentBox, slug: string) => {
  // object we will use to store matching slugs
  const slugs: { [key: string]: string } = {
    category: slug
  };

  // Step 1 : check if there are universes linked with this category
  const parentUniverses = contentBox.linkedFrom?.universePageCollection?.items as UniversePage[];
  if (!parentUniverses || parentUniverses.length === 0) {
    return null;
  }

  // Now we find the 1st universe where the category is actually linked within the categoryPages
  const universePage = parentUniverses.find(universe => {
    // NOTE : we need to check this because the "linkedFrom" list is generated by Contentful with ANY link between these entries.
    // Nevertheless, the only link that really determines if this universe is the parent of the category, is a link within "categoryPages" attribute
    return !!universe.categoryPagesCollection?.items.find(item => item?.sys.id === contentBox.sys.id);
  });
  if (!universePage?.slug) return null;
  slugs.universe = universePage.slug;

  return `/${slugs.universe}/${slugs.category}`;
};

export const extractSlugFromParents = (contentBox: ContentBox) => {
  if (!contentBox.content || !contentBox.content.slug) return null;
  if (isProductPage(contentBox.content) || isCalculatorPage(contentBox.content)) {
    return extractSlugFromProductParents(contentBox, contentBox.content.slug);
  }
  if (isCategoryPage(contentBox.content)) {
    return extractSlugFromCategoryParents(contentBox, contentBox.content.slug);
  }
};
