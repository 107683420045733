import searchInsights from 'search-insights';

export const initSearchInsights = () =>
  searchInsights('init', {
    appId: process.env.GATSBY_ALGOLIA_APPLICATION_ID,
    apiKey: process.env.GATSBY_ALGOLIA_SEARCH_API_KEY,
    useCookie: false
  });

export const acceptCookieInsights = () => {
  searchInsights('init', {
    partial: true,
    useCookie: true
  });
};

export default searchInsights;
