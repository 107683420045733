import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/gatsby';
import { refreshUserFromAMS, refreshUserFromSSO, SESSION_STORAGE_JWT } from '../../../login/auth';
import { onError } from '@apollo/client/link/error';
import { logQueryStatLink } from '../queriesStats';

/**
 * Create Apollo client with the given middleware URI and locale.
 *
 * @param uri middleware URI
 * @param locale
 * @param ssrMode
 */
export const createApolloClient = (uri: string, locale: string, ssrMode: boolean) => {
  const cache = new InMemoryCache();

  /**
   * Calls the AMS to renew the token. Note: renewToken is memoized, so this only renews the token once.
   * All pending requests will be stalled until the token is renewed.
   */
  let renewToken: boolean = true;

  /**
   * Reinitializes the memoized renewToken function so the next call to renewToken renews the token.
   */
  const setRenewTokenOnNextRequest = async () => {
    renewToken = true;
  };

  const authLink = setContext(async (_, { headers: originalHeaders }) => {
    const isPrintbox = undefined !== window ? 'goodies.printbox-commande.fr' === window.location.hostname : false;
    if (renewToken) {
      renewToken = false;
      if (isPrintbox) {
        await refreshUserFromAMS();
      } else {
        await refreshUserFromSSO();
      }
    }

    // return the headers to the context so httpLink can read them
    const token = isPrintbox ? Cookies.get('jwt') : sessionStorage.getItem(SESSION_STORAGE_JWT);

    return {
      headers: {
        ...originalHeaders,
        'exaprint-locale': locale,
        Authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const addOperationNameLink = setContext(async graphQLRequest => {
    const { operationName } = graphQLRequest;
    // We pass the Graphql operationName as a get parameter in the url to ease readability in Browser's DevTools network tab
    const customUri = operationName ? `${uri}?operation=${operationName}` : uri;
    return {
      uri: customUri
    };
  });

  const errorLink = onError(({ networkError, operation, graphQLErrors, forward }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      let customError = new Error('GraphQL query error');
      const nbErrors = graphQLErrors.length;

      customError.name = `[GraphQLQueryError] ${operation.operationName} - ${nbErrors === 1 ? graphQLErrors[0].message : 'Multiple errors'}`;

      const errorDetails = graphQLErrors.map(graphQLError => ({
        message: graphQLError.message,
        locations: JSON.stringify(graphQLError.locations),
        path: JSON.stringify(graphQLError.path)
      }));
      Sentry.withScope(scope => {
        scope.setExtras({ graphQLErrors: errorDetails });
        scope.setFingerprint(['GraphQLQueryError', customError.name]);
        Sentry.captureException(customError);
      });
    }

    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
      // when getting a 401 response, the API has cleared the token
      // so the next call to needToRenewToken() will return true, and renewToken will be called
      // so we make sure that it is actually renewed
      setRenewTokenOnNextRequest();

      // retry on 401 error

      Sentry.withScope(scope => {
        scope.setFingerprint(['NetworkError', operation.operationName]);
        Sentry.captureException(networkError);
      });

      // if the response included a new JWT cookie, the next request will use it and this should succeed
      // otherwise, the next request will fail, and apollo-link-error states
      // "errors from the new response [...] do not get passed into the error handler again"
      return forward(operation);
    }
  });
  const linkOptions = {
    credentials: 'include',
    fetchOptions: {
      referrerPolicy: 'unsafe-url' // we want that apollo client request transmit full referer header, so we can know where the request was call from.
    }
  };

  const client = new ApolloClient({
    link: from([errorLink, authLink, addOperationNameLink, logQueryStatLink, createUploadLink(linkOptions)]),
    ssrMode,
    cache
  });

  client.onResetStore(setRenewTokenOnNextRequest);

  return client;
};
