import React, { Component } from 'react';
import styled from 'styled-components';
import { ApolloConsumer } from '@apollo/client';
import ApolloClient from '@apollo/client';
import { withTranslation, WithTranslation } from 'react-i18next';
import { authLogout, globalLogout } from './auth';

const logout = require('../../assets/icons/logout.svg');

interface Props extends WithTranslation {
  feature?: string;
}

class LogoutButtonComponent extends Component<Props> {
  public render() {
    const { t, feature } = this.props;
    return (
      <ApolloConsumer>
        {client => (
          <MenuLogout title={t('shared.navigation.logout')} onClick={this.logout(client)} className="navbar-item">
            {t('shared.navigation.logout')} {!(feature && feature === 'inknpaper') && <MenuLogoutIcon />}
          </MenuLogout>
        )}
      </ApolloConsumer>
    );
  }

  private readonly logout = (client: ApolloClient.ApolloClient<object>) => async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    await authLogout();
    await globalLogout();
    await client.resetStore();
  };
}

const MenuLogout = styled.a`
  cursor: pointer;
`;

const MenuLogoutIcon = styled.span`
  display: flex;
  width: 17px;
  height: 17px;
  background: url(${logout});
  background-size: 17px 17px;
  cursor: pointer;
  margin-left: 10px;
`;

const LogoutButton = withTranslation('sharedComponents')(LogoutButtonComponent);
export { LogoutButton };
