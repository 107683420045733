import { navigate } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getColorFromCode } from '../../../../common/constants';
import { SimpleContent } from '../../../../generated/contentful-generated-types';
import { ImageCompressionCDN, ImageFormatAndCompressionCDN, ImageFormatCDN, getImageCDNUri } from '../../../../utils/CDN-fetcher';
import { MenuItem } from '../../MenuUtils';
import Right from './Right';

type MainProps = {
  linksContent: {
    id: string;
    children: MenuItem[];
    href?: string;
    label: string;
    menuHighlight?: SimpleContent;
  }[];
  selectedId: string;
  closeBigMenu: () => void;
};
const customCompression: ImageCompressionCDN = 2;
const formatAndCompression: ImageFormatAndCompressionCDN = { format: ImageFormatCDN.jpg, compression: customCompression };

const Main: FunctionComponent<MainProps> = ({ linksContent, selectedId = '1', closeBigMenu }) => {
  const { t } = useTranslation('sharedComponents');

  const handleClick = (href?: string) => (e: any) => {
    if (!process.env.SHARED_COMPONENT_MODE) {
      e.preventDefault();
      href && navigate(href);
      closeBigMenu();
    } else {
      closeBigMenu();
      return;
    }
  };
  return (
    <>
      {linksContent.map(({ id, label, children, href, menuHighlight }) => (
        <StyledMain key={id} className={selectedId === id ? 'selected' : 'hidden'}>
          <StyledContent>
            <StyledHeader>
              <StyledTitle href={href} onClick={handleClick(href)}>
                {label}
              </StyledTitle>

              <StyledHearderLink href={href} onClick={handleClick(href)}>
                {t('shared.menu.discoverGama')}
              </StyledHearderLink>
            </StyledHeader>
            <StyledCardsContainer>
              {
                <StyledCardsGrid key={id}>
                  {children.map(({ label, img, href }, id) => (
                    <StyledCard key={id} className="menu-card">
                      <StyledImageContainer href={href} onClick={handleClick(href)}>
                        <StyledImage
                          src={getImageCDNUri({
                            url: img,
                            width: 200,
                            //  height: 512,
                            formatAndCompression
                          })}
                          alt=""
                        />
                      </StyledImageContainer>
                      <StyledText>{label}</StyledText>
                    </StyledCard>
                  ))}
                </StyledCardsGrid>
              }
            </StyledCardsContainer>
          </StyledContent>

          {<Right menuHighlight={menuHighlight} closeBigMenu={closeBigMenu} />}
        </StyledMain>
      ))}
    </>
  );
};

export default Main;

const StyledMain = styled.div`
  display: flex;
  padding: 32px;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  border-left: 1px solid var(--Black-100, #ebebeb);
  background: var(--White, #fff);
  max-height: 70vh;
  min-height: 450px;
  overflow-y: auto;

  &.hidden {
    display: none;
  }
  &.selected {
    .menu-card {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const StyledContent = styled.div`
  display: flex;
  width: calc(100% - 260px);
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;
const StyledHeader = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
  align-self: stretch;
  color: ${getColorFromCode('exa-black', '#323232')};
`;
const StyledTitle = styled('a')`
  font-family: 'Styrene Medium', 'StyreneA';
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  color: ${getColorFromCode('exa-black', '#323232')};
  &:hover {
    color: #ff0b75;
  }
`;
const StyledHearderLink = styled('a')`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline !important;
  color: ${getColorFromCode('exa-black', '#323232')};
  &:hover {
    color: #ff0b75;
  }
`;

const StyledCardsContainer = styled.div``;

const StyledCardsGrid = styled.div`
  width: 100%;
  position: relative;
  margin-top: 16px;
  flex-wrap: wrap;
  display: grid;

  grid-template-columns: repeat(6, minmax(0, 1fr));
  column-gap: 24px;
  .menu-card {
    opacity: 0;
    transition: opacity 2s linear;
  }
  @media (max-width: 1600px) {
    row-gap: 16px;
    column-gap: 16px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media (max-width: 1300px) {
    row-gap: 16px;
    column-gap: 16px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media (max-width: 1168px) {
    row-gap: 16px;
    column-gap: 16px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 576px) {
    row-gap: 16px;
    column-gap: 4px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const StyledCard = styled.div`
  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 16px;
  &:hover {
    border-radius: 8.308px;
    background: var(--White, #fff);
    box-shadow: 2.077px 2.077px 10.385px 0px rgba(50, 50, 50, 0.2);
  }
`;

const StyledImageContainer = styled('a')`
  align-self: stretch;

  @media (max-width: 768px) {
  }
  @media (max-width: 576px) {
    height: 117px;
  }
`;

const StyledImage = styled.img`
  object-fit: cover;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--Black-100, #ebebeb);
  max-width: 100%;
`;

const StyledText = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  font-size: 14px;
  color: ${getColorFromCode('exa-black', '#323232')};
`;
