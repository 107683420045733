import { ApolloLink, FetchResult, NextLink, Observable, Operation } from '@apollo/client';
import { OperationDefinitionNode } from 'graphql';

interface QueryStats {
  queryCount: number;
  totalDurationInMs: number;
}
export const queriesStats = new Map<string, QueryStats>();

export const logGraphQLQuery = (operationName: string, durationInMs: number) => {
  const queryStats = queriesStats.get(operationName);
  if (queryStats) {
    queryStats.queryCount++;
    queryStats.totalDurationInMs += durationInMs;
  } else {
    queriesStats.set(operationName, {
      queryCount: 1,
      totalDurationInMs: durationInMs
    });
  }
};

export const showQueriesStats = () => {
  console.log('\n=== Done Graphql Queries ===');
  const entries = [...queriesStats];
  console.table(entries.map(item => ({ operation: item[0], ...item[1] })));
};

export const logQueryStatLink = new ApolloLink((operation: Operation, forward?: NextLink): Observable<FetchResult> | null => {
  const start = new Date().getTime();
  const queryDefinition = operation.query.definitions[0] as OperationDefinitionNode;
  const forwarded = forward ? forward(operation) : null;
  const elapsed = new Date().getTime() - start;
  const selectionsNames = queryDefinition.selectionSet.selections.map(item => (item as any).name?.value).join();
  const name = operation.operationName ?? queryDefinition.name?.value ?? selectionsNames;
  logGraphQLQuery(name ?? '?', elapsed);
  return forwarded;
});
