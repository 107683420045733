import { useEffect } from 'react';

export const useDisableScrolling = (disabled: boolean) => {
  useEffect(() => {
    if (disabled) {
      document.documentElement.style.height = '100vh';
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.height = 'inherit';
      document.documentElement.style.overflowY = 'initial';
    }
  }, [disabled]);
};
