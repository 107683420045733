import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Divider } from '../components/menu/components/BottomToast';

interface Props {
  loginData: any;
  isMobile?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UserActionLinks: FunctionComponent<Props> = ({ loginData, isMobile }) => {
  const { t } = useTranslation('sharedComponents');

  return (
    <>
      <StyledLink href={t('shared.navigation.follow-order-path')}>{t('shared.navigation.follow-order')}</StyledLink>
      <StyledLink href={t('shared.navigation.my-account-path')}>{t('shared.navigation.my-account')}</StyledLink>
      <StyledLink href={t('shared.promoban.dashboard-link')}>{t('shared.promoban.calltoaction-menu')}</StyledLink>
      <StyledLink href={t('shared.navigation.my-quotation-path')}>{t('shared.navigation.my-quotation')}</StyledLink>
      <StyledLink href={t('shared.navigation.exaclub-path')}>{t('shared.navigation.exaclub')}</StyledLink>
      <Divider />
      <StyledLinkRed href={t('shared.navigation.notifications-path')} className="navbar-item">
        <UserMenuNotificationText>{t('shared.navigation.notifications')}</UserMenuNotificationText>
      </StyledLinkRed>
    </>
  );
};

export const StyledLink = styled.a`
  &&& {
    &:hover {
      color: #33d1ff;
      text-decoration: underline;
    }
  }
`;

const StyledLinkRed = styled(StyledLink)`
  &&& {
    color: #e94279;
    &:hover {
      color: #e97ca3;
      text-decoration: underline;
    }
  }
`;

const UserMenuNotificationText = styled.div`
  color: #e94279;
  font-weight: bold;
`;
