import React, { Component } from 'react';
import styled from 'styled-components';

export interface QueryLoadingProps {
  loading: boolean;
}

const LoadingDiv = styled.div`
  padding: 2rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const LoadingButton = styled.a`
  padding-left: 2rem;
  padding-right: 2rem;
`;

export class QueryLoading extends Component<QueryLoadingProps> {
  public render() {
    return (
      <LoadingDiv>
        <LoadingButton className="button is-danger is-loading" />
      </LoadingDiv>
    );
  }
}
