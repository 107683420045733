import React, { FunctionComponent } from 'react';
import { GetLogin } from '../../common/graphql';
import styled from 'styled-components';

interface RoundedInitialsProps {
  loginData?: GetLogin;
}

export const RoundedInitials: FunctionComponent<RoundedInitialsProps> = ({ loginData }) => {
  const firstName = (loginData && loginData.login && loginData.login.firstName) || '';
  const lastName = (loginData && loginData.login && loginData.login.lastName) || '';

  return <InitialContainer data-abtasty="global-code">{`${firstName[0]}${lastName[0]}`.toUpperCase()}</InitialContainer>;
};

const InitialContainer = styled.div`
  background-color: #ffc74a;
  border-radius: 50%;
  font-size: 12px;
  color: #323232;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
