import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import chevronRight from '../../../../../assets/icons/chevron-right.svg';
import { HoverIntent } from './HooverIntent';

type Props = {
  links: { id: string; label: string; selected?: boolean; href?: string }[];
  selectedId: string;
  selectId: (id: string) => void;
  closeBigMenu: () => void;
};

const Left: FunctionComponent<Props> = ({ links, selectedId, selectId /*closeBigMenu*/ }) => {
  const selectedLinkId = (id: string) => () => selectId(id);

  return (
    <StyledLeft>
      {links.map(({ id, label /*href*/ }) => (
        <HoverIntent key={id} enterWait={200} leaveWait={200} enterCallback={() => selectId(id)} leaveCallback={() => selectedLinkId('')}>
          <StyledMenuItem /*to={href} onClick={closeBigMenu}*/ className={selectedId === id ? 'selected' : ''}>
            {label}
            {<StyledChevron src={chevronRight} alt="" />}
          </StyledMenuItem>
        </HoverIntent>
      ))}
    </StyledLeft>
  );
};

export default Left;
const StyledLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: var(--Bg-color, #f1f1f1);
  .hover-item {
    width: 100%;
  }
  max-height: 70vh;
  overflow-y: auto;
`;

const StyledMenuItem = styled.span`
  color: var(--Black-500, #323232);
  font-family: 'Styrene Medium', 'StyreneA';
  font-size: 14px;
  line-height: normal;
  display: flex;
  padding: 14px 16px;
  padding-right: 32px;
  align-items: center;
  gap: 16px;
  align-items: baseline;
  align-self: stretch;
  justify-content: space-between;
  cursor: default;
  transition: color 0.3s;
  position: relative;

  &.selected,
  &:hover {
    background-color: #ffffff;
    img {
      display: flex;
    }
  }
`;

const StyledChevron = styled.img`
  display: none;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  // margin-right: -8px;
  width: 6.175px;
  height: 10px;
`;
