import React, { FunctionComponent, HTMLAttributes } from 'react';
import { ContentBox, ContentBoxContent, HomePage, LandingPage } from '../generated/contentful-generated-types';
import { Link } from 'gatsby';

import { MenuEntry, isExternalLink as isExternalLinkBigMenu } from '../menu';
import { isExternalLink, getEntryHref, isAmsProductPage, isContentBox, getUrlFragment } from '../utils/contentful';
import { extractSlugFromParents } from '../common/graphql/linkedFromFragments';
import { AlgoliaItem } from '../typings/AlgoliaTypes';

export interface EntryLinkProps {
  entry?: ContentBox | ContentBoxContent | LandingPage | HomePage;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  directUrl?: string;
  isDirectUrlInNewWindow?: boolean;
  hit?: AlgoliaItem;
}

type Props = {
  entry: ContentBox;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  hit?: AlgoliaItem;
};

/**
 * This composant must not be used directly as styled(SmartEntryLink) or <SmartEntryLink ...>
 *   Always prefer "EntryLink"
 * Its only purpose is to find the most appropriate href to feed <Link> with,
 *   by digging through Parents to find a Universe and a Category slug when dealing with Content Box.
 * But EntryLink handles many other thing and is long-time used.
 *   If you cannot use EntryLink and are tempted to use SmartEntryLink : don't.
 *   This is a probably a bug or a misconception with EntryLink or SmartEntryLink that sould be fix.
 */
const SmartEntryLink: FunctionComponent<Props & HTMLAttributes<unknown>> = ({ id, entry, className, onClick, children, hit }) => {
  const content = entry?.content;
  if (!content) return null;
  const smartSlug = extractSlugFromParents(entry); // attempt to find a smart slug (universe/category/product)

  const href = smartSlug ?? getEntryHref(content); // use getEntryHref as fallback
  const newlinkHash = getUrlFragment(entry.newlinkHash); // don't forget linkHash if we are handling a contentBox
  let to = href + newlinkHash;
  if (hit?.objectID) {
    to = href + `?objectID=${hit?.objectID}` + newlinkHash;
  }

  if (process.env.SHARED_COMPONENT_MODE) {
    return (
      <a id={id} className={className} href={to} onClick={onClick}>
        {children}
      </a>
    );
  }

  if (entry.isBlank) {
    return (
      <a id={id} className={className} href={to} target="_blank" onClick={onClick}>
        {children}
      </a>
    );
  }

  return (
    <Link id={id} className={className} to={to} onClick={onClick}>
      {children}
    </Link>
  );
};

const EntryLink: FunctionComponent<EntryLinkProps & HTMLAttributes<unknown>> = ({
  entry,
  children,
  className,
  onClick,
  id,
  directUrl,
  isDirectUrlInNewWindow,
  hit
}) => {
  if (directUrl) {
    return (
      <a id={id} className={className} href={directUrl} rel="noopener" target={isDirectUrlInNewWindow ? `_blank` : '_self'} onClick={onClick}>
        {children}
      </a>
    );
  }

  if (!entry) {
    return null;
  }
  const content = 'content' in entry ? entry.content : entry;

  if (!content) {
    return null;
  }

  if (isExternalLink(content) || isAmsProductPage(content)) {
    const isBlank = (isExternalLink(content) && content.isBlank) || (isContentBox(entry) && entry.isBlank) ? '_blank' : '_self';

    return (
      <a id={id} className={className} href={getEntryHref(content)} rel="noopener" target={isBlank} onClick={onClick}>
        {children}
      </a>
    );
  } else {
    if (isContentBox(entry)) {
      return (
        <SmartEntryLink id={id} className={className} entry={entry} onClick={onClick} hit={hit}>
          {children}
        </SmartEntryLink>
      );
    } else {
      // Not sure this fallback is required. Tests were failing even though website seemed ok.
      // Problem is that it's become difficult to mock data containing contentbox due to LinkedFrom fragment
      const to = `${getEntryHref(content)}`;
      return (
        <Link id={id} className={className} to={to} onClick={onClick}>
          {children}
        </Link>
      );
    }
  }
};

export interface EntryLinkBigMenuProps {
  entry: MenuEntry;
  className?: string;
}

export const PrintboxEntryLink: FunctionComponent<EntryLinkBigMenuProps & HTMLAttributes<unknown>> = ({ entry, children, className }) => {
  if (isExternalLinkBigMenu(entry)) {
    return (
      <a className={className} href={entry.url}>
        {children}
      </a>
    );
  } else {
    return (
      <Link className={className} to={`/${entry.slug}`}>
        {children}
      </Link>
    );
  }
};

export default EntryLink;
