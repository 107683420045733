import React, { FunctionComponent, useState } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { HeaderTrustPilot } from '../../../navigation/HeaderTrustPilot';
import { MenuItem } from '../MenuUtils';
import { getColorFromCode } from '../../../common/constants';
import burgerSvgHover from '../../../../assets/icons/burger-menu-hover.svg';
import burgerSvg from '../../../../assets/icons/burger-menu.svg';
import { BigMenu } from './BigMenu/BigMenu';
import { capitalizeFirstLetter } from '../../../utils/string';

interface Props {
  rootMenuItems: MenuItem[];
  openMenu: (selectedItem?: MenuItem) => void;
  closeMenu: () => void;
  isMinimalistHeader: boolean;
}

export const MenuHorizontalBar: FunctionComponent<Props> = ({ rootMenuItems, openMenu, isMinimalistHeader }) => {
  const productIndex = rootMenuItems.findIndex(({ key, href }) => key === 'rootProducts' && !href?.includes('draft'));

  let productEntry = rootMenuItems[productIndex];
  const newItems = [...rootMenuItems];
  newItems.splice(productIndex, 1);

  const minimalistMenuItems = newItems.filter((item: { label: string }) => item.label == 'Produits');
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  const setMenuVisible = (isVisible: boolean) => {
    setIsMenuVisible(isVisible);
  };

  const bigMenuItems = productEntry?.children;
  const toggleMenu = () => setIsMenuVisible(prevState => !prevState);
  const menuItems = isMinimalistHeader ? minimalistMenuItems : newItems;

  return (
    <>
      <GlobalStyle isMenuVisible />
      <StyledMenuContainer>
        <StyledMenuBar className="second-step">
          <StyledItemsContainer>
            <StyledItem className={isMenuVisible ? 'underline' : ''}>
              <StyledSpanLink>
                <StyledIcon src={isMenuVisible ? burgerSvgHover : burgerSvg} alt="" onClick={() => openMenu()} />
                {capitalizeFirstLetter(productEntry.label)}
              </StyledSpanLink>
              <StyledMouseOveHandler onClick={toggleMenu} onMouseEnter={() => setMenuVisible(true)} onMouseLeave={() => setMenuVisible(false)} />{' '}
              <StyledMouseOverMargin onMouseEnter={() => setMenuVisible(true)} />
            </StyledItem>
            {menuItems.map((item: MenuItem, index: React.Key | null | undefined) =>
              item.href ? (
                <StyledItem key={index} className={item.key === 'rootPromo' ? 'promo-item' : ''} onMouseEnter={() => setMenuVisible(false)}>
                  {item.external ? (
                    <Styleda
                      href={item.href}
                      target={item.targetBlank ? '_blank' : undefined}
                      className={typeof window !== 'undefined' && window.location.pathname.includes(item.href) ? 'selected' : ''}
                    >
                      {item.label}
                    </Styleda>
                  ) : (
                    <Styleda
                      href={item.href}
                      className={typeof window !== 'undefined' && window.location.pathname.includes(item.href) ? 'selected' : ''}
                      onMouseEnter={() => setMenuVisible(false)}
                    >
                      {item.label}
                    </Styleda>
                  )}
                </StyledItem>
              ) : (
                <></>
              )
            )}
          </StyledItemsContainer>
          {!isMinimalistHeader && <HeaderTrustPilot />}
        </StyledMenuBar>
      </StyledMenuContainer>
      <StyledBigMenuToggler className={isMenuVisible ? 'visible' : 'hidden'}>
        {bigMenuItems && <BigMenu setMenuVisible={setMenuVisible} menuItems={bigMenuItems} />}
        {isMenuVisible && (
          <>
            <style>{`#___gatsby {  position: fixed;
              overflow: hidden;
            width: 100%; }`}</style>
            <StyledOverlay />
          </>
        )}
      </StyledBigMenuToggler>
    </>
  );
};

const GlobalStyle = createGlobalStyle<{ isMenuVisible: boolean }>`
body.menu-open {
  overflow: hidden;
}

#___gatsby {
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  position: ${({ isMenuVisible }) => (isMenuVisible ? 'fixed' : 'static')};
}
`;

const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const fadeOut = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;

const StyledOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 180px;
  left: 0;
  right: 0;
  background: rgba(50, 50, 50, 0.6);
  z-index: 2;
  cursor: pointer;
`;
const StyledBigMenuToggler = styled.div`
  width: 100%;
  display: flex;
  &.hidden {
    animation: ${fadeOut} 0.5s ease 0s 1 normal forwards;
    transition: margin 0s 0.6s linear;
    opacity: 1;
    margin-left: -150%;
  }
  &.visible {
    opacity: 1;
    margin-left: initial;
    animation: ${fadeIn} 0.5s ease 0s 1 normal forwards;
  }

  position: absolute;
  z-index: 3;
`;

const StyledMenuContainer = styled.div`
  border-bottom: 1px solid var(--Black-100, #ebebeb);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
`;

const StyledMenuBar = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 0 8px;
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: 1168px) {
    padding-right: 0px;
  }
`;

const StyledItemsContainer = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
`;
const StyledMouseOveHandler = styled.div`
  position: absolute;
  width: inherit;
  z-index: 5;
  top: 0px;
  width: 100%;
  height: 100%;
`;
const StyledMouseOverMargin = styled.div`
  position: absolute;
  width: inherit;
  z-index: 6;
  bottom: -10px;
  width: 150%;
  height: 20px;
`;
const StyledItem = styled.li`
  display: inline-block;
  margin: 0px;
  letter-spacing: 0.3px;
  font-size: 16px;
  position: relative;
  font-weight: normal;
  font-family: 'Styrene Medium';
  a {
    font-family: 'Styrene Medium';
  }

  &.promo-item a {
    color: #d62020;
  }

  &.underline {
    span {
      color: #ff0b75;
    }
    border-bottom: 2px solid black;
  }
`;

const Styleda = styled.a`
  padding: 14px 10px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: ${getColorFromCode('exa black', '#323232')};
  transition: color 0.2s ease-in-out;

  font-family: 'Styrene Medium' !important;

  &:hover {
    color: #ff0b75;
    border-bottom: 2px solid black;
  }
  border-bottom: 2px solid transparent;
  &.selected {
    color: #ff0b75;
  }
`;

const StyledSpanLink = styled.span`
  padding: 14px 10px 14px 5px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: ${getColorFromCode('exa black', '#323232')};
  transition: color 0.1s ease-in-out;
  font-family: 'Styrene Medium' !important;
  span {
    vertical-align: text-top;
  }
`;

const StyledIcon = styled.img`
  // vertical-align: top;
  margin-left: -5px;
  margin-right: 8px;
  width: auto;
  height: auto;
`;
