import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export interface QueryErrorProps {
  error?: string;
}

export const QueryError: FunctionComponent<QueryErrorProps> = ({ error }) => {
  const { t } = useTranslation('sharedComponents');
  toast.error(error || t('shared.error.fallbackmessage'), {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
  return null;
};
