import { gql } from '@apollo/client';
import { CART_LINE_ITEM_FRAGMENT } from './cart-line-item.fragment';

export const CART_FRAGMENT = gql`
  fragment Cart on Cart {
    id
    version
    customerId
    lineItems {
      ...CartLineItem
    }
    discount
    discountInfo {
      exaclubDiscount
      customerDiscount
    }

    totalPrice {
      centAmount
      currencyCode
      fractionDigits
    }
  }
  ${CART_LINE_ITEM_FRAGMENT}
`;
