import React, { SFC } from 'react';
import styled from 'styled-components';

interface Props {
  feature?: string;
  isMenu?: boolean;
  className?: string;
}

export const TopArrow: SFC<Props> = props => <StyledTopArrow {...props} />;
export const StyledTopArrow = styled.div<Props>`
  width: ${props => (props.feature === 'inknpaper' ? '100%' : '70px')};
  height: 10px;
  overflow: hidden;
  position: absolute;
  left: ${props => (props.feature === 'inknpaper' ? '0' : '88%')};
  margin-left: ${props => (props.feature === 'inknpaper' ? '0' : '-35px')};
  top: -10px;

  &:after {
    content: '';
    position: absolute;
    left: ${props => (props.feature === 'inknpaper' ? 'calc(50% - (45px / 2))' : '-23px')};
    width: 20px;
    height: 45px;
    box-shadow: 6px 5px 9px -9px black;
    transform: rotate(45deg);
    background: ${props => (props.feature === 'inknpaper' ? '#fff' : '#ddd')};
    border: 2px solid white;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    @media (max-width: 1024px) {
      right: 85px;
      left: auto;
    }
  }
  &.contact:after {
    right: 23px;
  }

  &.tracking:after {
    @media (max-width: 1024px) {
      right: 30px;
    }
    @media (max-width: 768px) {
      right: 30px;
    }
  }
`;
