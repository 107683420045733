import React, { useState, FunctionComponent, ChangeEvent, useEffect, createRef } from 'react';
import styled from 'styled-components';
import { ApolloConsumer } from '@apollo/client';
import ApolloClient from '@apollo/client';
import { MenuLink, MenuLinkProps } from '../navigation/MenuLink';
import { authLogin, getLostPasswordUrl, reloadPageOnSharedComponent } from './auth';
import { gtmPushLoginEvent } from '../common/gtm';
import loginErrorIcon from '../../assets/icons/error.svg';
import Cookies from 'js-cookie';
import uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';
import { useLoginForm } from '../hooks/useLoginForm';
import useOutsideClick from '../hooks/useOutsideClick';

interface Props {
  feature?: string;
}

export const LoginForm: FunctionComponent<Props> = ({ feature }) => {
  const loginForm = useLoginForm();

  const { t } = useTranslation('sharedComponents');
  const isExaprintClient = !!Cookies.get('isExaprintClient');
  const locale = process.env.GATSBY_LOCALE || '';

  const dropDownReference = createRef<HTMLDivElement>();

  const [isLoaded, setLoaded] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [showLoginForm, setShowLoginForm] = useState(isExaprintClient);

  //close the login dropbox when user clicks outside the event target.
  useOutsideClick(dropDownReference, () => loginForm?.hide());

  const handleIdentifierChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    setIdentifier(value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;
    setPassword(value);
  };

  const handleShowLoginForm = () => {
    setShowLoginForm(true);
  };

  const handleRegister = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open(t('shared.navigation.register-path-new'), '_self');
  };

  const handleRemember = () => {
    setRemember(!remember);
  };

  const login = (client: ApolloClient.ApolloClient<object>) => async (): Promise<void> => {
    setLoading(true);
    await authLogin(
      {
        identifiant: identifier,
        mot_de_passe: password
      },
      client,
      async () => {
        gtmPushLoginEvent();
        setLoginError(false);
        reloadPageOnSharedComponent();
      },
      () => {
        setLoginError(true);
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  // Display by default the incitement toolbox only for media from 768px max-width and exaprint clients
  if (showLoginForm || window.matchMedia('(max-width: 768px)').matches) {
    return (
      <LoginFormContainer tabIndex={0} ref={dropDownReference}>
        <FormIntro>{t('shared.loginform.title')}</FormIntro>
        {loginError ? <FormErrors style={{ color: '#ff3860' }}>{t('shared.loginform.inkNPaperError')}</FormErrors> : ''}
        <FormInput
          placeholder={t('shared.loginform.inkNPaperIdentifier')}
          type="email"
          name="identifier"
          value={identifier}
          onChange={handleIdentifierChange}
          autoComplete="username"
        />
        <FormInput
          placeholder={t('shared.loginform.password')}
          autoComplete="current-password"
          id="current-password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
          type="password"
        />
        <FormOptions>
          <Checkbox label={t('shared.loginform.stayloggedin')} value="remember" checked={remember} onChange={handleRemember} />
          <FormForgotPass>
            <LoginDropdownLink href={getLostPasswordUrl()}>{t('shared.loginform.forgotpassword')}</LoginDropdownLink>
          </FormForgotPass>
        </FormOptions>
        <ApolloConsumer>
          {client => (
            <FormConnection
              type="button"
              name="login"
              onClick={login(client)}
              className="not-uniform" // for AMS to prevent uniform Jquery plugin!
            >
              {loading ? <Spinner /> : t('shared.product.login')}
            </FormConnection>
          )}
        </ApolloConsumer>
        <LoginMenuLink feature={feature} hideOnDesktop={false} href={`${t('shared.navigation.register-path-new')}`}>
          {t('shared.navigation.register')}
        </LoginMenuLink>
      </LoginFormContainer>
    );
  } else {
    return (
      <LoginFormContainer tabIndex={0} ref={dropDownReference}>
        <NewClientOffer>
          <OfferInfo>
            <Price dangerouslySetInnerHTML={{ __html: t('shared.loginform.promo.price') }} />
            <OfferText>
              {t('shared.loginform.promo.details')}
              {locale === 'fr' && <strong>{t('shared.loginform.promo.code')}</strong>}
            </OfferText>
          </OfferInfo>
          {locale === 'fr' && (
            <>
              <Plus>{t('shared.loginform.promo.plus')}</Plus>
              <Add>{t('shared.loginform.promo.freesamples')}</Add>
            </>
          )}
        </NewClientOffer>
        <FormConnection onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleRegister(e)}>{t('shared.product.register')}</FormConnection>
        <LoginMenuLink feature={feature} hideOnDesktop={false} onClick={handleShowLoginForm}>
          {t('shared.product.login')}
        </LoginMenuLink>
      </LoginFormContainer>
    );
  }
};

interface CheckBoxProps {
  label: string;
  value: string;
  checked?: boolean;
  onChange?: (value: string | null) => void;
}

export const Checkbox: FunctionComponent<CheckBoxProps> = ({ label, value, checked, onChange }) => {
  // we need to generate unique ids because this component is used from several places (header, mobile toaster)
  const [id] = useState(uniqueId('remember-checkbox-'));

  return (
    <Group>
      <input
        id={id}
        type="checkbox"
        value={value}
        onChange={e => onChange && onChange(e.target.checked ? e.target.value : null)}
        checked={checked}
        className="not-uniform" // for AMS to prevent uniform Jquery plugin!
      />
      <Label htmlFor={id}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeMiterlimit="10" fill="none" d="M22.9 3.7l-15.2 16.6-6.6-7.1"></path>
        </svg>
        {label}
      </Label>
    </Group>
  );
};

const NewClientOffer = styled.div`
  background-color: #fdc744;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const OfferInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Price = styled.span`
  width: 50%;
  font-size: 22px;
  line-height: 22px;
  font-weight: bold;
  text-align: right;
  margin-right: 5px;

  @media (max-width: 760px) {
    font-size: 24px;
    line-height: 24px;
  }
  @media (max-width: 475px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

const OfferText = styled.div`
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  width: 180px;
`;

export const Mention = styled.sup`
  font-size: 10px;
`;

const Plus = styled.span`
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;

  @media (max-width: 475px) {
    font-size: 16px;
    margin: 0 2px;
    line-height: 16px;
  }
`;

const Add = styled.span`
  font-size: 12px;
`;

const LoginDropdownLink = styled.a`
  font-size: 12px;
  font-family: var(--font-styrene);
  color: var(--accent-color);
  outline: none;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: #33d1ff;
  }
`;
const LoginMenuLink = styled(MenuLink)<MenuLinkProps>`
  text-align: center;
  font-size: 12px;
  font-family: var(--font-styrene);
  color: var(--accent-color);
  outline: none;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: #33d1ff;
  }
`;

const LoginFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: stretch;
  :focus {
    outline: none;
  }
`;

export const LoginFormErrors = styled.span`
  margin-top: -0.75rem;
  margin-left: 1rem;
  display: block;
  ::before {
    content: '';
    background: url(${loginErrorIcon});
    background-size: 14px 14px;
    display: inline-block;
    height: 14px;
    width: 14px;
    vertical-align: sub;
    margin-right: 3px;
  }
`;

export const FormContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const FormBackground = styled.div`
  margin: auto;
  margin-top: 70px;
  width: 60%;
  box-sizing: border-box;
  padding: 30px 60px;
  text-align: center;
  font-family: var(--font-styrene);

  @media (max-width: 767px) {
    width: 93%;
    padding: 30px 15px;
  }
`;

const FormIntro = styled.div`
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
  text-align: center;
  color: var(--main-text);
  font-weight: bold;
`;

const FormInput = styled.input`
  margin-bottom: 10px;
  padding: 6px 4%;
  font-size: 12px;
  border: 1px solid #eee;

  &:focus {
    outline: #ccc solid 1px;
  }
`;

const FormOptions = styled.div`
  display: flex;
`;

const FormForgotPass = styled.div`
  text-align: right;
  width: 100%;
`;

const FormErrors = styled.div`
  margin-top: -0.75rem;
  margin-left: 1rem;
  display: block;

  ::before {
    content: '';
    background: url(${loginErrorIcon});
    background-size: 14px 14px;
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    width: 14px;
    vertical-align: sub;
    margin-right: 3px;
  }
`;

const FormConnection = styled.button`
  font-size: 12px;
  text-transform: uppercase;
  background-color: var(--accent-color);
  color: #fff;
  margin: 6px 0;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 0;
  transition: background 0.3s ease-in-out;
  border: none;

  &:hover {
    background-color: var(--accent-color-2);
  }

  &&& {
    &:disabled {
      background-color: lightgray;
      border: none;
      cursor: not-allowed;
    }
  }
`;

const Spinner = styled.div`
  display: block;
  margin: 20px auto;
  width: 25px;
  height: 25px;
  animation: spinAround 0.5s infinite linear;
  border: 2px solid white;
  border-radius: 15px;
  border-color: transparent transparent #fff #fff !important;
`;

const Group = styled.div`
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: normal;
  width: 100%;
  input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  input:checked + label > svg {
    height: 24px;
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }
  input:checked + label > svg {
    stroke: #00c882;
  }
  svg {
    stroke: #323232;
    stroke-width: 4px;
    height: 0;
    width: 15px;
    position: absolute;
    left: 3px;
    top: -6px;
    stroke-dasharray: 33;
    z-index: 2;
  }
  label {
    font-size: 12px;
    cursor: pointer;
    position: relative;
    width: 100%;
    display: block;
    padding-left: 26px;
    box-sizing: border-box;
    &:after {
      box-sizing: border-box;
      content: '';
      height: 16px;
      width: 16px;
      border: 2px solid #ccc;
      border-radius: 2px;
      transition: 0.15s all ease-out;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  label:active::after {
    background-color: #f5f5f5;
  }
  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: -33;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

const Label = styled.label``;
