import { Designer } from '../components/Designers/types/types';

export type CalculatorUrlType = 'leaflet' | 'largeformat' | 'label';
export type UrlType = 'ams' | 'phoenix' | 'amsApi' | 'cart' | 'designer' | 'goodies' | 'auth' | 'static' | 'cdn' | CalculatorUrlType;
export type GatsbyEnv = 'development' | 'staging' | 'production';

export enum VARIABLE_URLS {
  phoenix = 'phoenix'
}

export const urls: { [ENV in GatsbyEnv]: { [V in UrlType]: string } } = {
  development: {
    ams: 'stage',
    amsApi: 'stg-api',
    cart: 'beta-dev-cart',
    designer: 'beta-stg-designer',
    goodies: 'beta-dev',
    phoenix: 'dev-pulco',
    auth: 'stg-auth',
    static: 'stg-static',
    cdn: 'stg-static',
    leaflet: 'stg-calculateur-brochure',
    largeformat: 'stg-cgf-api',
    label: '/uploads/minisite/fr_FR/etiquettes/calculateur_new/automatique.html?new=1&noTabs=1'
  },
  staging: {
    ams: 'stage',
    amsApi: 'stg-api',
    cart: 'beta-stg-cart',
    designer: 'beta-stg-designer',
    goodies: 'beta-stg',
    phoenix: 'stage',
    auth: 'stg-auth',
    static: 'stg-static',
    cdn: 'stg-static',
    leaflet: 'stg-calculateur-brochure',
    largeformat: 'stg-cgf-api',
    label: '/uploads/minisite/fr_FR/etiquettes/calculateur_new/automatique.html?new=1&noTabs=1'
  },
  production: {
    ams: 'www',
    amsApi: 'api',
    cart: 'cart',
    designer: 'designer',
    goodies: 'goodies',
    phoenix: 'www',
    auth: 'auth',
    static: 'static',
    cdn: 'static',
    leaflet: 'calculateur-brochure',
    largeformat: 'cgf-api-new',
    label: '/uploads/minisite/fr_FR/etiquettes/calculateur_new/automatique.html?new=1&noTabs=1'
  }
};

export const prefixes: Record<string, Record<string, string>> = {
  fr: {
    print: 'impression',
    onlinePrinting: 'imprimerie-en-ligne',
    product: 'produit',
    custom: 'sur-mesure'
  },
  es: {
    print: 'impresion',
    onlinePrinting: 'imprenta-online',
    product: 'producto',
    custom: 'a-medida'
  }
};

export const slugs: Record<string, Record<string, string>> = {
  fr: {
    loyalty: 'programme-fidelite-exaclub'
  },
  es: {
    loyalty: 'programa-fidelidad-exaclub'
  }
};

export const getHomePage = () => {
  return getUrlOf('ams');
};

// This MERCHANT_ID is sent with the Exaprint designer url, which will use it as the "tenant" parameter when calling the Cimpress Upload API.
// Using our dedicated MERCHANT_ID will make the uploads kept 1 year, versus 30 days with the "default" MERCHANT_ID
export const DESIGNER_UPLOAD_MERCHANT_ID = 'exagroup-production-uploads';

export const getCustomizeVariantUrl = (
  location: Partial<Location>,
  productId: string,
  variantId: number,
  mcpSku: string,
  category: string,
  name: string,
  variant: string,
  designer?: Designer
) => {
  const url = designer === Designer.printDesigner ? new URL('/apps/printDesign', document.baseURI) : new URL(getUrlOf('designer'));
  // Must do this for printbox-commande sites
  const redirectUrl = location.origin?.includes('exaprint') ? `${location.origin}/apps/designReview` : `${location.origin}/design-review`;
  url.searchParams.set('productId', productId);
  url.searchParams.set('variantId', variantId.toString());
  url.searchParams.set('redirectUrl', redirectUrl);
  url.searchParams.set('mcpSku', mcpSku);
  url.searchParams.set('merchantId', DESIGNER_UPLOAD_MERCHANT_ID);
  url.searchParams.set('productCategory', category);
  url.searchParams.set('productName', name);
  url.searchParams.set('variantName', variant);
  return url.toString();
};

export const getUrlOf = (url: UrlType, forceLocale?: string) => {
  const env = process.env.GATSBY_ENVIRONMENT as undefined | GatsbyEnv;
  const dynamicEnvSubDomain = process.env.GATSBY_DYNAMIC_ENV_SUB_DOMAIN; // exists in Dynamic Environment context only
  if (!env) {
    throw new Error(`[getUrlOf]: options.environment or GATSBY_ENVIRONMENT not defined`);
  }

  let suffix;
  const locale = forceLocale ? forceLocale : process.env.GATSBY_LOCALE;
  if (forceLocale || typeof window === 'undefined') {
    suffix = `exaprint.${locale}`;
  } else {
    suffix = getCurrentDomain();
  }
  if (('development' === env || 'staging' === env) && VARIABLE_URLS.phoenix === url && dynamicEnvSubDomain) {
    return `https://${dynamicEnvSubDomain}.${suffix}`;
  }

  if (url === 'label' && locale) {
    return `https://${urls[env]['ams']}.${suffix}${urls[env][url].replace('fr_FR', locale + '_' + locale.toUpperCase())}`;
  }

  return `https://${urls[env][url]}.${suffix}`;
};

export const getCookieDomain = () => {
  return `.${getCurrentDomain()}`;
};

export const getCurrentDomain = () => {
  const defaultSuffix = 'exaprint.fr';

  // keep the last 2 segments of the origin
  const splitOrigin = typeof window !== 'undefined' ? window.location.origin.split('.') : '';
  const splitOriginContainsSomething = (splitOrigin: '' | Array<String>): splitOrigin is Array<String> => splitOrigin.length > 1;
  return splitOriginContainsSomething(splitOrigin) ? splitOrigin.slice(-2).join('.') : defaultSuffix;
};
