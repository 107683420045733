import styled, { css } from 'styled-components';
import React, { FC } from 'react';
import { isMenu, Menu, MenuEntry } from '../menu';
import get from 'lodash/get';
import { SubCategories } from './SubCategories';

interface Props {
  category: MenuEntry;
  path: string;
  activeCategory?: string;
  link: any;
}

export function getPPAGMenuLabel(menu: { navigationContent?: { menuLabel: string }; title?: string }) {
  return get(menu, 'navigationContent.menuLabel', menu.title);
}

export const Category: FC<Props> = ({ category, path, activeCategory, link }) => {
  const menu = category as Menu;

  const menuLabel = getPPAGMenuLabel(category);

  const selected = !!(menuLabel === activeCategory || (menu && menu.items && menu.items.some(category => getPPAGMenuLabel(category) === activeCategory)));
  const Link = link;
  const categoryPath = `/${path}/${category.slug}/`;
  return (
    <CategoryListItem main={true} selected={selected}>
      <Link
        to={categoryPath}
        activeStyle={{
          borderLeft: selected ? '6px solid #d8235c' : 'none',
          texDecoration: 'none',
          fontSize: '0.9rem'
        }}
      >
        <CategoryLink main={true} selected={selected}>
          {menuLabel}
          {isMenu(category) && <Caret selected={selected} />}
        </CategoryLink>
      </Link>

      {isMenu(category) && <SubCategories activeCategory={activeCategory} category={category} path={categoryPath} link={link} />}
    </CategoryListItem>
  );
};

interface CategoryLinkProps {
  selected?: boolean;
  main?: boolean;
}

export const CategoryLink = styled.span`
  text-decoration: none;
  color: ${(props: CategoryLinkProps) => (props.selected ? '#d8235c' : 'black')};
  font-size: ${(props: CategoryLinkProps) => (props.main ? '0.9rem' : '0.8rem')};
  line-height: 100%;
  :hover {
    color: #e3006a;
  }
  ${(props: CaretProps) =>
    !props.selected &&
    css`
      &:hover ${Caret}:before {
        border-left-color: #f42e8a;
      }
    `};
`;

interface CategoryListItemProps {
  selected?: boolean;
  main?: boolean;
}

const CategoryListItem = styled.li`
  border-bottom: 1px solid #cccccc;
  padding: 0;
  :last-child {
    border-bottom: 0;
  }
  & > ul {
    display: ${(props: CategoryListItemProps) => (props.selected ? 'block' : 'none')};
  }
`;
interface CaretProps {
  selected?: boolean;
}
const Caret = styled.span`
  float: right;
  vertical-align: middle;
  position: relative;
  height: 16px;
  width: 16px;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: '';
    border: 8px solid transparent;
  }
  ${(props: CaretProps) =>
    !props.selected &&
    css`
      margin-top: 10px;
      &:before {
        left: 6px;
        border-left-color: #ccc;
      }
      &:after {
        left: 3px;
        border-left-color: #fff;
      }
    `};

  ${(props: CaretProps) =>
    props.selected &&
    css`
      margin-top: 15px;
      &:before {
        top: 0;
        border-top-color: #d8235c;
      }
      &:after {
        top: -3px;
        border-top-color: #fff;
      }
    `};
`;
