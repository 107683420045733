import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useIsWindowWidthBelow } from '../../hooks/useWindowSize';
import { MOBILE_SIZE } from '../../common/constants';

export const NewFooterNewsletter: React.FC = () => {
  const { t } = useTranslation('sharedComponents');
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);
  const isLocaleFr = (process.env.GATSBY_LOCALE || 'fr') === 'fr';

  useEffect(() => {
    const HubspotScript = document?.createElement('script');
    HubspotScript.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(HubspotScript);

    HubspotScript?.addEventListener('load', () => {
      if (window?.hbspt) {
        window.hbspt?.forms?.create?.({
          region: 'eu1',
          portalId: '143296819',
          formId: isLocaleFr ? 'f2536940-80de-45a6-941c-d70d32f86ebf' : '3feb0752-0853-4c23-9137-1b276a2be843',
          target: '#hubspotForm'
        });
      }
    });
  }, []);

  return (
    <StyledNewFooterNewsletterContainer isMobile={isMobile}>
      <StyledTextWrapper isMobile={isMobile}>
        <h3>{t('shared.footer.newsletter.title')}</h3>
        <span>{t('shared.footer.newsletter.subtitle')}</span>
      </StyledTextWrapper>
      <StyledHubspotNewsletterWrapper isMobile={isMobile} id="hubspotForm" />
    </StyledNewFooterNewsletterContainer>
  );
};

const StyledNewFooterNewsletterContainer = styled.div<{ isMobile: boolean }>`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ isMobile }) =>
    isMobile &&
    `
    flex-direction: column;
    gap: .5rem;
  `}
`;

const StyledTextWrapper = styled.div<{ isMobile: boolean }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-style: normal;
  > h3 {
    color: #fff;
    font-family: 'Styrene Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }
  > span {
    color: #ebebeb;
    font-family: 'StyreneA', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    > h3 {
      font-size: 16px;
      line-height: 20px;
    }
    > span {
      font-size: 12px;
      line-height: 17px;
    }
  `}
`;

const StyledHubspotNewsletterWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  max-width: 766px;
  position: relative;
  width: 100%;
  .hs-form-private {
    display: flex;
    flex-direction: row;
    max-width: 766px;
    position: relative;
    width: 100%;

    & label {
      display: none;
    }
  }

  .input {
    background-color: #323232;
    border: none;
  }

  .hs_email {
    max-width: 766px;
    width: 100%;
  }

  .field:not(:last-child) {
    margin-bottom: 0;
  }

  .hs-input {
    align-items: center;
    background: #ebebeb;
    border: none;
    border-radius: 30px;
    color: #989898;
    font-family: 'StyreneA', sans-serif;
    font-size: ${({ isMobile }) => (isMobile ? 14 : 18)}px;
    font-style: normal;
    font-weight: 400;
    height: 46px;
    max-width: 766px;
    padding: 1rem 0.5rem 1rem 1.5rem;
    transition: background-color 0.2s ease-in;
    width: 100%;

    &:hover {
      background: #c2c2c2;
    }

    &:focus,
    &:active {
      border: 1px solid #c2c2c2;
      outline: none;
    }
  }

  .hs-button {
    align-content: end;
    border: none;
    border-radius: 25px;
    background: #fff;
    cursor: pointer;
    color: #323232;
    display: flex;
    flex-wrap: wrap;
    font-size: 27px;
    height: 30px;
    justify-content: center;
    position: absolute;
    right: 1rem;
    transition: background-color 0.2s ease-in;
    top: 0.2rem;
    width: 30px;

    &:hover {
      background: #ffc846;
    }
  }
`;
