import React, { FunctionComponent } from 'react';
import { navigate } from 'gatsby';
import { GET_CART_QUERY } from '../components/Cart/CartQuery';
import { ImageIcon } from '../common/components/ImageIcon';
import { useQraphql } from '../hooks/useGraphql';
import { Query, QueryCartArgs } from '../generated/phoenix-api-generated-types';

interface getCartQuery extends Pick<Query, 'cart'> {}

const cartIcon = require('../../assets/icons/shopping-cart.svg');

export const MiniCartINP: FunctionComponent = () => {
  const { data } = useQraphql<getCartQuery, QueryCartArgs>(GET_CART_QUERY);
  let count = null;
  if (data && data.cart) {
    count = data.cart.lineItems.length;
  }

  if (count === 0) {
    return null;
  }

  return (
    <ImageIcon
      onClick={() => {
        const path = '/apps/cart';
        if (process.env.SHARED_COMPONENT_MODE) {
          window.location.href = path;
        } else {
          navigate(path);
        }
      }}
      src={cartIcon}
      notificationsCount={count || 0}
    />
  );
};
