import { gql } from '@apollo/client';
import { CART_FRAGMENT } from '../../cart/graphql/fragments/cart.fragment';

export const GET_CART_QUERY = gql`
  query getCartQuery {
    cart {
      ...Cart
    }
  }
  ${CART_FRAGMENT}
`;
