import { DocumentNode } from 'graphql';
import { useQuery, OperationVariables, QueryHookOptions, QueryResult } from '@apollo/client';

/*type UseGraphQLResult<TData> = Partial<Omit<QueryResult<TData>,  'error'>> & {
  error: undefined | string;
};*/

export function useQraphql<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  // As July 2022
  // Working with Apollo client v2 and Gatsby v3,
  // useQuery SSR mode is defective, causing client-side queries to be run for all generated pages,
  // when building site, at the very end, on the last phase of HTML generation.
  // Problem is :
  // - This causes a huge pike of 60k request, making Phoenix-API out of order for about a minute, as backbone cannot handle this huge volume.
  // - The queries results are not even exploited (Gatsby does not event wait for the queries results)
  // To solve this, we disable ssr by default for all queries (right now there is no global param to do that)

  return useQuery<TData, TVariables>(query, { ...options, ssr: false });
}
