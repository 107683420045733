import { getUrlOf } from './urls';

interface ImageCDNUri {
  url: string | null | undefined;
  width?: number;
  height?: number;
  formatAndCompression?: ImageFormatAndCompressionCDN;
  removeParams?: boolean;
}
export const getImageCDNUri = ({ url, width, height, formatAndCompression, removeParams = false }: ImageCDNUri) => {
  if (!url) return '';

  const rootUri = getUrlOf('cdn', undefined)
    // Patch for https://goodies.printbox-commande.fr/ : force the use of Exaprint cdn url,
    // as there is no corresponding cdn for printbox-commande.fr
    .replace('.printbox-commande.fr', '.exaprint.fr');

  let formatedUrl = removeParams ? url.split('?')[0] : url;
  let CDNUri = `${rootUri}/cache?src=${encodeURIComponent(formatedUrl)}`;

  if (width) CDNUri = `${CDNUri}&w=${width}`;
  if (height) CDNUri = `${CDNUri}&h=${height}`;
  if (formatAndCompression) CDNUri = `${CDNUri}&f=${formatAndCompression.format}&c=${formatAndCompression.compression}`;
  else CDNUri = `${CDNUri}&f=jpeg&c=1`; // Without that the images are way too heavy
  return CDNUri;
};

export interface ImageFormatAndCompressionCDN {
  format: ImageFormatCDN;
  compression: ImageCompressionCDN;
}
export type ImageCompressionCDN = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export enum ImageFormatCDN {
  jpg = 'jpg',
  JPG = 'JPG',
  jpeg = 'jpeg',
  JPEG = 'JPEG',
  png = 'png',
  PNG = 'PNG'
}

// w width
// h height
// f format
// c compression
//<?src=image_url>[&w=width][&h=height][&f=<jpg|JPG|jpeg|JPEG|png|PNG>[&c=(0-9)]]
