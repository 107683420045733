import gql from 'graphql-tag';
import { GetLogin } from '../common/graphql';
import { USER_SEGMENT_KEYS } from '../common/constants';
import { useQraphql } from './useGraphql';
import { customerCookiesInformations, setCustomerInformationsCookie } from '../login/cookies';

export const GET_LOGIN = gql`
  query GetLogin {
    login {
      customerNumber
      firstName
      lastName
      exaclubDiscountLevel
      legacyCustomerCreationDate
      companyName
      notifications
    }
    clientContact
    clientContactFull
  }
`;

export function useGetLogin(onCompleted?: (data: GetLogin) => void) {
  return useQraphql<GetLogin>(GET_LOGIN, { onCompleted });
}

export function isLoggedIn(useLoginResult: any) {
  return !useLoginResult.error && !useLoginResult.loading && !!useLoginResult.data && !!useLoginResult.data.login;
}

export const useIsLoggedIn = () => {
  const useLoginResult = useGetLogin();

  const IsLogged = !!useLoginResult?.data?.login;
  const { IDClientContact, Email, Nom, Prenom, TelephoneContact, TelephoneContactPortable, Locale } = useLoginResult?.data?.clientContactFull || {};

  const customerCookiesInformations: customerCookiesInformations = {
    IsLogged,
    IDClientContact,
    Email,
    Nom,
    Prenom,
    TelephoneContact,
    TelephoneContactPortable,
    Locale
  };

  setCustomerInformationsCookie(customerCookiesInformations);

  return isLoggedIn(useLoginResult);
};

export const useIsReseller = () => {
  const login = useGetLogin();
  return (
    login?.data?.clientContact &&
    (process.env.GATSBY_LOCALE === 'fr'
      ? login?.data?.clientContact?.IDSegment === USER_SEGMENT_KEYS.RESELLER
      : login.data.clientContact.IDSegment === USER_SEGMENT_KEYS.ES_RESELLER)
  );
};

export const useUserSegment = () => {
  const login = useGetLogin();
  const loggedIn = login.data && login.data.login;
  return loggedIn ? login.data?.clientContact?.IDSegment : process.env.GATSBY_LOCALE === 'fr' ? USER_SEGMENT_KEYS.ANOME : USER_SEGMENT_KEYS.ES_ANOME;
};

export const useIsRegisteredNonBuyer = () => {
  const login = useGetLogin();
  if (login?.data?.clientContact?.NbCommandes === null) return true;
  return login?.data?.clientContact?.NbCommandes !== undefined && parseInt?.(login.data.clientContact.NbCommandes) <= 0;
};
