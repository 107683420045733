import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { TrustPilotWidget } from '../common/components/TrustPilotWidget';

export const HeaderTrustPilot: FunctionComponent = () => {
  const { t } = useTranslation('sharedComponents');
  return (
    <TrustPilotWidget
      templateId="5419b732fbfb950b10de65e5"
      businessUnitId={t('shared.trustpilot.data-businessunit-id')}
      styleHeight="24px"
      styleWidth="100%"
      theme="light"
      href={t('shared.trustpilot.widget-link')}
    />
  );
};
