import React, { ReactNode, useCallback, useRef, useEffect } from 'react';

export type FCProps<Props> = Props extends { children: any } ? Props : Props & { children?: ReactNode };

interface OwnProps {
  enterCallback: () => unknown;
  leaveCallback: () => unknown;
  enterWait?: number;
  leaveWait?: number;
}

type Props = FCProps<OwnProps>;

export function HoverIntent({ enterCallback, leaveCallback, enterWait = 500, leaveWait = 500, children }: Props) {
  const isEnterInvoked = useRef(false);
  const enterTimer = useRef<NodeJS.Timeout | null>(null);
  const leaveTimer = useRef<NodeJS.Timeout | null>(null);

  const cancelEnterTimer = useCallback(() => {
    if (enterTimer.current) clearTimeout(enterTimer.current);
  }, []);

  const cancelLeaveTimer = useCallback(() => {
    if (leaveTimer.current) clearTimeout(leaveTimer.current);
  }, []);

  const handleMouseEnter = useCallback(() => {
    cancelLeaveTimer();
    enterTimer.current = setTimeout(() => {
      enterCallback();
      isEnterInvoked.current = true;
    }, enterWait);
  }, [cancelLeaveTimer, enterCallback, enterWait]);

  const handleMouseLeave = useCallback(() => {
    cancelEnterTimer();
    leaveTimer.current = setTimeout(() => {
      if (isEnterInvoked.current) {
        leaveCallback();
        isEnterInvoked.current = false;
      }
    }, leaveWait);
  }, [cancelEnterTimer, leaveCallback, leaveWait]);

  useEffect(() => {
    return () => {
      cancelEnterTimer();
      cancelLeaveTimer();
    };
  }, [cancelEnterTimer, cancelLeaveTimer]);

  return (
    <div className="hover-item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
    </div>
  );
}
