import loadable from '@loadable/component';
import React, { FunctionComponent, HTMLAttributes } from 'react';
import { ContentBox, ContentBoxContent } from '../../generated/contentful-generated-types';

import { isAmsProductPage, isExternalLink, getEntryHref } from '../../utils/contentful';

export interface EntryLinkProps {
  entry: ContentBox | ContentBoxContent;
  text?: string | null;
}

// Gatsby Router cannot be used for External links,
// or is not available when this code is run through shared component
const isNotAbleToUseGatsbyRouter = (content: ContentBox | ContentBoxContent) =>
  process.env.SHARED_COMPONENT_MODE || isExternalLink(content) || isAmsProductPage(content);

export const LoadableEntryLink: FunctionComponent<EntryLinkProps & HTMLAttributes<unknown>> = props => {
  const { entry, text: title, id } = props;

  const content = 'content' in entry ? entry.content : entry;
  if (!content) {
    return null;
  }

  if (isNotAbleToUseGatsbyRouter(content)) {
    // Gatsby router is not usable, use <a> markup instead
    const isBlank = isExternalLink(content) ? content.isBlank : false;
    const entryHref = getEntryHref(content);
    const externalLink = `<a id="${id}" href="${entryHref}" target="${isBlank ? `_blank` : `_self`}">${title}</a>`;
    return <span dangerouslySetInnerHTML={{ __html: externalLink }} />;
  } else {
    const LoadableLink = loadable(() => import('../EntryLink'));
    return <LoadableLink {...props}>{title}</LoadableLink>;
  }
};
