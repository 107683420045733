import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useIsWindowWidthBelow } from '../../hooks/useWindowSize';
import { MOBILE_SIZE } from '../../common/constants';

import exaTeamsFR from '../../../assets/img/footer-exa-team.png';
import exaTeamsES from '../../../assets/img/footer-exa-team-es.png';
import phone from '../../../assets/icons/phone.svg';

export const NewFooterContactUs: React.FC = () => {
  const { t } = useTranslation('sharedComponents');
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);
  const isLocaleFr = (process.env.GATSBY_LOCALE || 'fr') === 'fr';

  if (isMobile)
    return (
      <StyledFooterContactUsMobileContainer>
        <StyledTitleAndContactUsMobileWrapper>
          <StyledContactUsTitleMobileWrapper>
            <h2>{t('shared.footer.contactUs.title')}</h2>
            <span>{t('shared.footer.contactUs.subtitle')}</span>
          </StyledContactUsTitleMobileWrapper>
          <StyledContactUsMobileWrapper>
            <StyledContactUsElementMobileWrapper>
              <StyledContactUsInfoMobileWrapper isFirstElement>
                <span>{t('shared.customerService.phone.title')}</span>
                <span>{t('shared.customerService.phone.week')}</span>
                <span>{t('shared.customerService.phone.hour')}</span>
              </StyledContactUsInfoMobileWrapper>
              <StyledContactUsPhoneMobileWrapper>
                <img src={phone} alt="phone's icon" />
                <button onClick={() => window?.open(`tel:${t('shared.customerService.phone.number')}`)}>{t('shared.customerService.phone.number')}</button>
              </StyledContactUsPhoneMobileWrapper>
            </StyledContactUsElementMobileWrapper>
            <StyledContactUsElementMobileWrapper>
              <StyledContactUsInfoMobileWrapper>
                <span>{t('shared.footer.contactUs.webSite')}</span>
                <span>{`${t('shared.customerService.faq.hour')} ${t('shared.customerService.faq.week')}`}</span>
                <StyledLinkMobileWrapper>
                  <a href={t('shared.customerService.email.url')} target="_blank">
                    {t('shared.footer.contactUs.formButton')}
                  </a>
                  <a href={t('shared.customerService.faq.url')} target="_blank">
                    {t('shared.footer.contactUs.faqButton')}
                  </a>
                </StyledLinkMobileWrapper>
              </StyledContactUsInfoMobileWrapper>
            </StyledContactUsElementMobileWrapper>
          </StyledContactUsMobileWrapper>
        </StyledTitleAndContactUsMobileWrapper>
      </StyledFooterContactUsMobileContainer>
    );

  return (
    <StyledFooterContactUsContainer>
      <img src={isLocaleFr ? exaTeamsFR : exaTeamsES} alt="Exaprint team" />
      <StyledTitleAndContactUsWrapper>
        <StyledContactUsTitleWrapper>
          <h2>{t('shared.footer.contactUs.title')}</h2>
          <span>{t('shared.footer.contactUs.subtitle')}</span>
        </StyledContactUsTitleWrapper>
        <StyledContactUsWrapper>
          <StyledContactUsElementWrapper isFirstElement>
            <StyledContactUsInfoWrapper isFirstElement>
              <span>{t('shared.customerService.phone.title')}</span>
              <span>{t('shared.customerService.phone.week')}</span>
              <span>{t('shared.customerService.phone.hour')}</span>
            </StyledContactUsInfoWrapper>
            <StyledContactUsPhoneWrapper>
              <img src={phone} alt="phone's icon" />
              <button>{t('shared.customerService.phone.number')}</button>
            </StyledContactUsPhoneWrapper>
          </StyledContactUsElementWrapper>
          <StyledContactUsElementWrapper>
            <StyledContactUsInfoWrapper>
              <span>{t('shared.footer.contactUs.webSite')}</span>
              <span>{`${t('shared.customerService.faq.hour')} ${t('shared.customerService.faq.week')}`}</span>
            </StyledContactUsInfoWrapper>
            <StyledButtonWrapper>
              <button onClick={() => window?.open(t('shared.customerService.email.url'), '_blank')}>{t('shared.footer.contactUs.formButton')}</button>
              <button onClick={() => window?.open(t('shared.customerService.faq.url'), '_blank')}>{t('shared.footer.contactUs.faqButton')}</button>
            </StyledButtonWrapper>
          </StyledContactUsElementWrapper>
        </StyledContactUsWrapper>
      </StyledTitleAndContactUsWrapper>
    </StyledFooterContactUsContainer>
  );
};

const StyledFooterContactUsContainer = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 2.875rem;

  > img {
    aspect-ratio: 16/9;
    max-width: 524px;
  }
`;

const StyledFooterContactUsMobileContainer = styled(StyledFooterContactUsContainer)`
  flex-direction: column;
  gap: 1rem;
`;

const StyledTitleAndContactUsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
`;

const StyledTitleAndContactUsMobileWrapper = styled(StyledTitleAndContactUsWrapper)`
  gap: 1rem;
`;

const StyledContactUsTitleWrapper = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  font-style: normal;
  gap: 0.5rem;

  > h2 {
    color: #fff;
    font-family: 'Styrene Medium', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }

  > span {
    color: #ebebeb;
    font-family: 'StyreneA', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
`;

const StyledContactUsTitleMobileWrapper = styled(StyledContactUsTitleWrapper)`
  align-items: flex-start;
  text-align: start;
  > h2 {
    font-size: 20px;
    line-height: 26px;
  }

  > span {
    font-size: 12px;
    line-height: 17px;
  }
`;

const StyledContactUsWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
`;

const StyledContactUsMobileWrapper = styled(StyledContactUsWrapper)`
  gap: 1rem;
`;

const StyledContactUsElementWrapper = styled.div<{ isFirstElement?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  gap: ${({ isFirstElement }) => (isFirstElement ? `1rem` : '2.225rem')};
  ${({ isFirstElement }) => isFirstElement && 'width: 200px;'}
`;

const StyledContactUsElementMobileWrapper = styled(StyledContactUsElementWrapper)`
  align-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 0 0;
  gap: 0.5rem;
  padding: 0;
`;

const StyledContactUsPhoneWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 40px;
  gap: 0.5rem;
  padding: 0.75rem 0;

  > img {
    flex-shrink: 0;
    fill: #ebebeb;
    height: 15px;
    width: 15px;
  }

  > button {
    background: #323232;
    border: none;
    color: #ebebeb;
    font-family: 'StyreneA', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
  }
`;

const StyledContactUsPhoneMobileWrapper = styled(StyledContactUsPhoneWrapper)`
  > button {
    font-weight: 400;
    text-decoration-line: underline;
  }
  @media (max-width: 344px) {
    > button {
      padding: 0;
    }
    > img {
      display: none;
    }
  }
`;

const StyledContactUsInfoWrapper = styled.div<{ isFirstElement?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ isFirstElement }) => (isFirstElement ? `.25rem` : '.5rem')};
  > span {
    color: #ebebeb;
    font-family: 'StyreneA', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
  > span:first-of-type {
    color: #fff;
    font-family: 'Styrene Medium', sans-serif;
    font-weight: 500;
  }
`;

const StyledContactUsInfoMobileWrapper = styled(StyledContactUsInfoWrapper)`
  align-items: flex-start;
  > span {
    font-size: 12px;
    line-height: 17px;
  }
  > span:first-of-type {
    line-height: 18px;
  }
`;

const StyledButtonWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  > button {
    align-items: center;
    background: #fff;
    border-radius: 4px;
    border: none;
    box-shadow: 0 6px 4px 0 rgba(36, 90, 113, 0.1), 0 3px 2px 0 rgba(36, 90, 113, 0.08);
    color: #323232;
    cursor: pointer;
    display: flex;
    font-family: 'Styrene Medium', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    gap: 8px;
    height: 40px;
    justify-content: center;
    line-height: 21px;
    padding: 0.75rem 1.875rem;
    transition: background-color 0.25s ease-in;

    &:hover {
      background: #c2c2c2;
    }
  }

  > button:last-of-type {
    width: 143px;
  }
`;

const StyledLinkMobileWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1 0 0;
  flex-direction: row;
  gap: 1.5rem;
  height: 40px;
  padding: 0.54rem 0;

  > a {
    color: #fff;
    font-family: 'StyreneA', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-decoration-line: underline;
  }
`;
