import { useEffect, useState } from 'react';

export const useUrlSearchParams = (terms: string = 'searchTerms') => {
  if (typeof window === 'undefined' || !window.location) {
    return '';
  }

  const [searchTerms, setSearchTerms] = useState<string>('');

  const urlParams = new URLSearchParams(window.location.search).get(terms);

  useEffect(() => {
    setSearchTerms(urlParams || '');
  }, [urlParams]);

  return searchTerms;
};
