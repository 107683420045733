import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TrustPilot: FunctionComponent = () => {
  const { t } = useTranslation('translations');
  return (
    <StyledTrustPilot>
      <iframe
        height="101"
        width="160"
        scrolling="no"
        title="Customer reviews powered by Trustpilot"
        src={t('front.navigation.goodies-header-trustpilot-url')}
      />
    </StyledTrustPilot>
  );
};

const StyledTrustPilot = styled.div`
  position: absolute;
  right: 0;
  top: 4px;
`;

export { TrustPilot };
