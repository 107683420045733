import React from 'react';
import styled from 'styled-components';

interface ContainerProps {
  isInknpaper?: boolean;
  children: React.ReactNode;
}

const Container = ({ isInknpaper, children }: ContainerProps) =>
  isInknpaper ? <InknPaperContainer id="main-container">{children}</InknPaperContainer> : <LegacyContainer id="main-container">{children}</LegacyContainer>;

const LegacyContainer = styled.div`
  margin: 18px 0 0;
  padding: 0;
  background-color: #fefffe;
  box-shadow: 0px 0px 0px 1px #eeeeee;
  overflow: hidden;
  min-height: 0;

  .container {
    @media screen and (min-width: 1700px) {
      max-width: 1152px;
      width: 1152px;
    }
  }
`;

const InknPaperContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  background-color: #fefffe;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 40px;

  @media (min-width: 1500px) {
    max-width: 1400px;
  }

  @media (min-width: 1400px) and (max-width: 1500px) {
    max-width: 1400px;
  }
`;

export default Container;
