import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { detect } from 'detect-browser';

import { createApolloClient } from './src/common/graphql/localState/client';
import { updateGtmOnLogin, gtmPushData } from './src/common/gtm';

import { BrowserBanner } from './src/components/BrowserBanner';
import { RefreshBanner } from './src/components/RefreshBanner';
import { Layout } from './src/components/Layout';

import Cookies from 'js-cookie';
import { getCookieDomain } from './src/utils/urls';
import queryString from 'query-string';

import { DesignerPageType } from './src/common/constants';

// Find the documentation PDF in the ticket : https://exaprint.atlassian.net/browse/MKT-411
// CJ Affilate require to set a cookie, localstorage and sessionstorage corresponding to the 'cjevent' url param
// Push cjevent and pubcid in the datalayer
const storeCjAffiliateInformation = () => {
  const isWindow = typeof window !== 'undefined';
  if (isWindow) {
    const urlParams = queryString.parse(window.location.search.substr(1));
    const { cjevent, pubcid } = urlParams;
    if (cjevent) {
      Cookies.set('cje', cjevent, { domain: `${getCookieDomain()}`, expires: 30, path: '/', secure: false });
      sessionStorage.setItem('cjevent', cjevent);
      localStorage.setItem('cjevent', cjevent);
    }
    if (pubcid) {
      Cookies.set('pubcid', pubcid, { domain: `${getCookieDomain()}`, expires: 30, path: '/', secure: false });
      sessionStorage.setItem('pubcid', pubcid);
      localStorage.setItem('pubcid', pubcid);
    }
  }
};

export const wrapRootElement = ({ element }) => {
  const locale = process.env.GATSBY_LOCALE || 'fr';
  const client = createApolloClient(process.env.GATSBY_MIDDLEWARE_URL, locale, false);

  updateGtmOnLogin(client);

  return <ApolloProvider client={client}>{element}</ApolloProvider>;
};

const SAFARI_MIN_VERSION = 11;

export const wrapPageElement = ({ element, props }) => {
  const browser = detect();

  const { pageContext } = props;
  const { breadcrumbs, pageType } = pageContext;

  const pageTypeMap = {
    InknpaperPage: 'product page',
    home: 'home',
    ProductPage: 'fpv3',
    UniversePage: 'universe page',
    CategoryPage: 'category page old',
    CategoryPageAlgolia: 'category page new merch',
    LandingPage: 'landing page',
    search: 'search page'
  };

  const gtmData = {
    pageType: pageTypeMap[pageType] || 'other'
  };

  const lastBreadcrumb = breadcrumbs?.length && breadcrumbs[breadcrumbs.length - 1];
  const productName = lastBreadcrumb?.label || props.data?.contentful?.productPage?.title;

  if (productName) {
    gtmData['productName'] = productName;
  }
  storeCjAffiliateInformation();

  gtmPushData(gtmData);

  if (Cookies.get('cje')) {
    gtmPushData({ cjevent: Cookies.get('cje') });
  }
  if (Cookies.get('pubcid')) {
    gtmPushData({ pubcid: Cookies.get('pubcid') });
  }

  const version = parseFloat(browser.version, 10);
  let isSupported;
  switch (browser.name) {
    case 'safari':
      isSupported = version >= SAFARI_MIN_VERSION;
      break;
    default:
      isSupported = true;
      break;
  }

  const inner = [DesignerPageType].includes(pageType) ? element : <Layout {...props}>{element}</Layout>;

  if (!isSupported) {
    return <BrowserBanner>{inner}</BrowserBanner>;
  } else if (process.env.NODE_ENV === 'development') {
    return <RefreshBanner>{inner}</RefreshBanner>;
  } else {
    return inner;
  }
};

export const disableCorePrefetching = () => true;
