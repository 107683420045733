import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SimpleContent } from '../../../../generated/contentful-generated-types';
import { ImageCompressionCDN, ImageFormatAndCompressionCDN, ImageFormatCDN, getImageCDNUri } from '../../../../utils/CDN-fetcher';
import EntryLink from '../../../EntryLink';

type RightProps = {
  menuHighlight?: SimpleContent;
  closeBigMenu: () => void;
};
const customCompression: ImageCompressionCDN = 2;
const formatAndCompression: ImageFormatAndCompressionCDN = { format: ImageFormatCDN.jpg, compression: customCompression };

const Right: FunctionComponent<RightProps> = ({ menuHighlight, closeBigMenu }) => {
  const menuHighLightImg = getImageCDNUri({
    url: menuHighlight?.media?.url,
    formatAndCompression
  });

  const linkedContent = menuHighlight?.relatedContent;
  return (
    <>
      {menuHighLightImg && linkedContent && (
        <StyledRightSection entry={linkedContent} onClick={closeBigMenu}>
          <StyledImageWrapper>
            <StyledImage src={menuHighLightImg} alt="Right Image" />
          </StyledImageWrapper>
        </StyledRightSection>
      )}
      {menuHighLightImg && !linkedContent && (
        <StyledImageWrapper>
          <StyledImage src={menuHighLightImg} alt="Right Image" />
        </StyledImageWrapper>
      )}
    </>
  );
};

export default Right;

const StyledRightSection = styled(EntryLink)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  width: 100%;

  flex: 1 0 0;
  align-self: stretch;
`;
const StyledImageWrapper = styled.div`
  width: 242px;
  height: 407px;
`;

const StyledImage = styled.img`
  object-fit: cover;
`;
