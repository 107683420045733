import { MenuLink } from './MenuLink';
import React, { createRef, FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { StyledHeaderItemContent } from './HeaderDesktop';
import { ImageIcon } from '../common/components/ImageIcon';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../hooks/useOutsideClick';
const ordersIcon = require('../../assets/icons/fast-delivery.svg');
import { MenuDropDown, MenuDropDownContainer } from './UserAction';

interface Props {
  mobile?: boolean;
  notificationsCount?: number | null;
}

export const HeaderTracking: FunctionComponent<Props> = ({ mobile, notificationsCount }) => {
  const { t } = useTranslation('sharedComponents');
  const [displayMenuDropDown, setDisplayMenuDropDown] = useState(false);
  const dropDownReference = createRef<HTMLDivElement>();
  useOutsideClick(dropDownReference, () => setDisplayMenuDropDown(false));

  return !mobile ? (
    <StyledMenuDropDownContainer ref={dropDownReference} onClick={() => setDisplayMenuDropDown(prevDisplayMenuDropDown => !prevDisplayMenuDropDown)}>
      <ImageIcon src={ordersIcon} notificationsCount={!!notificationsCount ? notificationsCount : undefined} />
      <StyledMenuDropDown displayMenuDropDown={displayMenuDropDown}>
        <a href={t('shared.navigation.track-your-orders-link')}>{t('shared.navigation.my-tracking')}</a>
        <StyledNotificationsCounter style={{ display: 'flex' }} href={t('shared.navigation.notifications-path')}>
          {!!notificationsCount && <div>{notificationsCount}</div>}
          {t('shared.navigation.tracking-notification')}
        </StyledNotificationsCounter>
      </StyledMenuDropDown>
    </StyledMenuDropDownContainer>
  ) : (
    <div>
      <ImageIcon src={ordersIcon} />
      <DropdownMenu>
        <StyledHeaderItemContent>
          <p>
            <TrackingMenuLink feature="inknpaper" href={t('shared.navigation.track-your-orders-link')}>
              {t('shared.navigation.my-tracking')}
            </TrackingMenuLink>
          </p>
        </StyledHeaderItemContent>
      </DropdownMenu>
    </div>
  );
};

const TrackingMenuLink = styled(MenuLink)`
  &.no-decoration {
    text-decoration: none;

    @media (max-width: 1024px) {
      color: #323232;
      font-weight: bold;
      &:hover {
        color: inherit;
      }
    }
  }
`;

const DropdownMenu = styled.div`
  display: none;
  z-index: 1000;
  background-color: #fff;
  margin-top: 10px;
  box-shadow: 0px 0px 0px 1px '#ddd';
  border: 1px solid #ccc;
  position: absolute;
  right: 0;
  left: auto;
  width: 360px;
  top: 42px;
  min-width: auto;
  padding: 0.5rem;
`;

const StyledMenuDropDown = styled(MenuDropDown)`
  font-weight: 400;
  &&& {
    width: 250px;
    a:first-of-type {
      padding-top: 10px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    a:last-of-type {
      padding-bottom: 10px;
    }
    a {
      &:hover {
        color: #4a4a4a;
        text-decoration: none;
        background-color: #f5f5f5;
      }
    }
  }
`;

const StyledMenuDropDownContainer = styled(MenuDropDownContainer)`
  padding: 10px 0;
  display: flex;
`;

const StyledNotificationsCounter = styled.a`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  > div {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 10px;
    color: white;
    background-color: #fc3b30;
    margin: 0 10px;
  }
  &&& {
    &:hover {
      color: #33d1ff;
      text-decoration: underline;
    }
  }
`;
