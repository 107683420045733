import React, { createRef, RefObject, useEffect, useState } from 'react';

const useOutsideClick = (ref: React.RefObject<HTMLElement>, callback: () => void, onTouchStart: boolean = true) => {
  useEffect(() => {
    const listener: any = (e: CustomEvent<HTMLElement>) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
      return;
    };

    document.addEventListener('mousedown', listener);
    if (onTouchStart) {
      document.addEventListener('touchstart', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
      if (onTouchStart) {
        document.removeEventListener('touchstart', listener);
      }
    };
  }, [ref, callback]);
};

export default useOutsideClick;

// add more HTML..Element if needed
type OutsideClickElement = HTMLDivElement;

export const useRefOutsideClick = (
  isMobile: boolean,
  defaultValue: boolean = false,
  onTouchStart: boolean = true
): [elementRef: RefObject<OutsideClickElement>, displayElement: boolean, setElementDisplay: React.Dispatch<React.SetStateAction<boolean>>] => {
  const elementRef = createRef<OutsideClickElement>();
  const [displayElement, setElementDisplay] = useState(defaultValue);
  useOutsideClick(elementRef, () => !isMobile && setElementDisplay(false), onTouchStart);
  return [elementRef, displayElement, setElementDisplay];
};
