import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { DEBUG_SOUND } from './DebugSound';
import { toast } from 'react-toastify';

export const ENABLE_DEBUG_MODE_KEY = 'DEBUG_MODE';
const konamiCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight'];
/**
 * This component is in charge of enabling / disabling the debug mode
 * When debug mode is activated, the <Debug></Debug> tags in the code display their inner content.
 * The debug mode can be activated by typing the Konami code with the keyboard (see above @konamiCode constant).
 */
export const DebugModeProvider: FunctionComponent<any> = ({ children }) => {
  const [currentCodeSequence, setCurrentCodeSequence] = useState([] as string[]);

  // We use useRef instead of useState because last state is not accessible when modification is done in an event Listeners
  // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const [enableDebugMode, _setEnableDebugMode] = useState(false);
  const enableDebugModeRef = useRef(enableDebugMode);
  const setEnableDebugMode = (value: boolean) => {
    enableDebugModeRef.current = value;
    _setEnableDebugMode(value);
  };

  const playSound = () => {
    const audio = new Audio(DEBUG_SOUND);
    setTimeout(() => {
      audio.play();
    }, 200);
  };
  const setKey = (ev: KeyboardEvent): any => {
    currentCodeSequence.push(ev.key);
    const newSequence = currentCodeSequence.slice(-konamiCode.length);
    setCurrentCodeSequence(newSequence);

    if (newSequence.join() === konamiCode.join()) {
      const newEnableDebugMode = !enableDebugModeRef.current;
      setEnableDebugMode(newEnableDebugMode);

      const msg = `Cheat code detected, will ${newEnableDebugMode ? 'display' : 'hide'} debug infos`;
      toast.info(msg, { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
      playSound();

      if (sessionStorage) {
        sessionStorage.setItem(ENABLE_DEBUG_MODE_KEY, Boolean(newEnableDebugMode).toString());
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', setKey);
    return () => {
      document.removeEventListener('keydown', setKey);
    };
  }, []);

  useEffect(() => {
    if (sessionStorage) {
      setEnableDebugMode(sessionStorage.getItem(ENABLE_DEBUG_MODE_KEY) == Boolean(true).toString());
    }
  }, []);

  return <DebugContext.Provider value={{ displayDebugInfos: enableDebugMode }}>{children}</DebugContext.Provider>;
};

export const DebugContext = React.createContext({
  displayDebugInfos: false
});
