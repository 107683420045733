import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const cart = require('../../assets/icons/cart.svg');
import { navigate } from 'gatsby';
import { GET_CART_QUERY } from '../components/Cart/CartQuery';
import { useTranslation } from 'react-i18next';
import { useQraphql } from '../hooks/useGraphql';
import { Query, QueryCartArgs } from '../generated/phoenix-api-generated-types';

interface getCartQuery extends Pick<Query, 'cart'> {}

export const MiniCart: FunctionComponent = () => {
  const { t } = useTranslation('translations');
  let result = null;
  const { data } = useQraphql<getCartQuery, QueryCartArgs>(GET_CART_QUERY);
  if (data && data.cart) {
    const count = data.cart.lineItems.length;
    result = <CartLine>{t('front.minicart.item', { count })}</CartLine>;
  } else {
    result = <CartLine>{t('front.minicart.loading')}</CartLine>;
  }
  return (
    <Cart className="mini-cart">
      <CartLink onClick={() => navigate('/apps/cart')}>
        <CartIcon />
        <CartContent>
          <CartLine>{t('front.minicart.cart')}</CartLine>
          {result}
        </CartContent>
      </CartLink>
    </Cart>
  );
};

const Cart = styled.li`
  font-size: 11px;
  display: flex;
  align-items: center;
`;

const CartContent = styled.div`
  display: inline-block;
  margin: 0 3px;
`;

const CartLine = styled.div`
  font-weight: 600;
  line-height: 12px;
  min-width: 80px;
`;

const CartLink = styled.a`
  display: block;
  color: white;
  margin: 7px 0 0 15px;
  transition: 300ms;
  min-width: 111px;
  &:hover {
    opacity: 0.5;
    color: white;
  }
`;

const CartIcon = styled.span`
  display: inline-block;
  text-indent: -9999px;
  width: 22px;
  height: 19px;
  background: url(${cart});
  background-size: 22px 22px;
`;
