import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface Props {
  feature?: string;
  className?: string;
}

const Wrapper: FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = props => (
  <>
    <StyledDiv {...props} />
  </>
);

export default Wrapper;

const inknpaper = `
  width: 100%;
  position: relative;
  margin: 0 auto;
  
  /* Override some Bulma useless stuff */
  &&& {
    body, button, input, select, textarea {
      font-family: var(--font-styrene);
    }
    font-family: var(--font-styrene);
    line-height: 1;
    color: #000;
  }
`;

const legacy = ` 
  margin: 0 auto;
  padding: 0 0 30px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  max-width: 1152px;
`;

const StyledDiv = styled.div<Props>`
  ${props => (props.feature && props.feature === 'inknpaper' ? inknpaper : legacy)}
`;
