import React, { ChangeEvent, useState, FunctionComponent } from 'react';
import styled from 'styled-components';
import { LegacySubmitButton } from '../common/components/buttons/LegacySubmitButton';
import { ApolloConsumer } from '@apollo/client';
import ApolloClient from '@apollo/client';
import { amsLogin, getLostPasswordUrl } from './auth';
import { gtmPushLoginEvent } from '../common/gtm';
import { useTranslation } from 'react-i18next';
import { useIsWindowWidthBelow } from '../hooks/useWindowSize';
import { MOBILE_SIZE } from '../common/constants';

interface Props {
  borderWidth?: string | null;
}

const icnLoginError = require('../../assets/icons/error.svg');

export const PrintboxLoginForm: FunctionComponent<Props> = ({ borderWidth }) => {
  const { t } = useTranslation('translations');
  const [identifier, setIdentifier] = useState<any>();
  const [password, setPassword] = useState<any>();
  const [loginError, setLoginError] = useState<any>();
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);

  const submit = (client: ApolloClient.ApolloClient<object>) => async (): Promise<void> => {
    await amsLogin(
      {
        login: identifier,
        password: password
      },
      async () => {
        setLoginError(false);
        gtmPushLoginEvent();
        await client.resetStore();
      },
      () => {
        setLoginError(true);
      }
    );
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: string = e.target.name;
    const value: string = e.target.value;
    if (name === 'identifier') {
      setIdentifier(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  return (
    <LoginFormContainer>
      <LoginFormContent className="dropdown-content is-radiusless" t={t} borderWidth={borderWidth}>
        <LoginFormItem className="dropdown-item ">
          <LoginFormField className="field">
            <LoginFormControl className="control">
              <LoginFormInput
                className={`input is-shadowless ${loginError ? 'is-danger' : ''}`}
                name="identifier"
                value={identifier}
                onChange={handleChange}
                placeholder={t('front.loginform.identifier')}
              />
            </LoginFormControl>
          </LoginFormField>
          <LoginFormField className="field">
            <LoginFormControl className="control">
              <LoginFormInput
                className={`input is-shadowless ${loginError ? 'is-danger' : ''}`}
                name="password"
                type="password"
                placeholder={t('front.loginform.password')}
                value={password}
                onChange={handleChange}
              />
            </LoginFormControl>
          </LoginFormField>
        </LoginFormItem>
        {loginError ? <LoginFormErrors className="has-text-danger">{t('front.loginform.error')}</LoginFormErrors> : ''}
        <LoginFormItem className="dropdown-item  button-holder">
          <LoginFormField className="field ">
            <LoginFormControlRight className="control" isMobile={isMobile}>
              <ApolloConsumer>{client => <LegacySubmitButton name="login" onClick={submit(client)} label={t('front.loginform.login')} />}</ApolloConsumer>
            </LoginFormControlRight>
          </LoginFormField>
        </LoginFormItem>
      </LoginFormContent>
      <LoginFormForgotPwd>
        <ForgotPasswordLink href={getLostPasswordUrl()}>{t('front.loginform.forgotpassword')}</ForgotPasswordLink>
      </LoginFormForgotPwd>
    </LoginFormContainer>
  );
};

const LoginFormContainer = styled.form``;
const LoginFormContent = styled.div.attrs((props: Props) => {
  const borderWidth = props.borderWidth;
  return {
    borderWidth: borderWidth
  };
})<Props>`
  &.dropdown-content {
    box-shadow: none;
    border: ${props => props.borderWidth} solid #d9d9d9;
  }
`;
const LoginFormItem = styled.div`
  &.button-holder .field.column {
    padding: 0 0.75rem;
  }
  &&&.dropdown-item {
    margin-bottom: 0;
    width: 100%;
  }
`;
const LoginFormField = styled.div``;
const LoginFormControl = styled.div``;
const LoginFormControlRight = styled(LoginFormControl)<{ isMobile: boolean }>`
  &.control {
    text-align: right;
    padding-bottom: 30px;
  }
  ${({ isMobile }) =>
    isMobile &&
    `
    & button {
      width: 100%;
    }
  `}
`;
const LoginFormInput = styled.input`
  &&& {
    &:focus {
      border-color: #fec8e8;
    }
    &::placeholder {
      color: #555555;
    }
  }
`;

const LoginFormForgotPwd = styled.div`
  padding: 0 0 0 1rem;
`;
const ForgotPasswordLink = styled.a`
  font-size: 11px;
  color: #e30059;
  outline: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const LoginFormErrors = styled.span`
  margin-top: -0.75rem;
  margin-left: 1rem;
  display: block;

  ::before {
    content: '';
    background: url(${icnLoginError});
    background-size: 14px 14px;
    display: inline-block;
    height: 14px;
    width: 14px;
    vertical-align: sub;
    margin-right: 3px;
  }
`;
