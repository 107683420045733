import React, { FC } from 'react';
import styled from 'styled-components';

import { UserAction, LoginAction } from './UserAction';
import { GetLogin } from '../common/graphql';

interface UserMenuProps {
  loginData?: GetLogin;
  feature?: string;
}

export const UserMenu: FC<UserMenuProps> = ({ loginData, feature }) => {
  return loginData && loginData?.login ? (
    <UserAction feature={feature} loginData={loginData} />
  ) : (
    <HorizontalContainer>
      <LoginAction />
    </HorizontalContainer>
  );
};

const HorizontalContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  @media (max-width: 1024px) {
    align-items: center;
    margin-left: 10px;
  }
`;
