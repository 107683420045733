import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import { Menu } from '../menu';

import { CategoryLink } from './Category';

interface Props {
  category: Menu;
  path: string;
  activeCategory?: string;
  link: any;
}

export const SubCategories: FunctionComponent<Props> = ({ category, path, activeCategory, link }) => {
  const categories = category.items;
  const Link = link;
  return (
    <LeafCategoriesUnorderedList>
      {categories &&
        categories.map((subCategory, index) => {
          if (subCategory) {
            const urlPath = path.endsWith('/') ? `${path}${subCategory.slug}/` : `${path}/${subCategory.slug}/`;
            const menuLabel = (subCategory.navigationContent && subCategory.navigationContent.menuLabel) || subCategory.title;

            return (
              <LeafCategoryListItem key={index}>
                <Link to={urlPath}>
                  <CategoryLink data-cy="category-link" main={false} selected={activeCategory === menuLabel}>
                    {menuLabel}
                  </CategoryLink>
                </Link>
              </LeafCategoryListItem>
            );
          } else {
            return null;
          }
        })}
    </LeafCategoriesUnorderedList>
  );
};

const LeafCategoriesUnorderedList = styled.ul`
  flex: 1;

  display: flex;
  flex-wrap: wrap;
`;

const LeafCategoryListItem = styled.li`
  width: 100%;
`;
