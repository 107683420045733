import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';

function BottomNavigationComponent(props: WithTranslation) {
  const { t } = props;
  return (
    <>
      <RegisterMark>
        <BottomLeft>
          <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
            <rect className="cls-1" y="40" width="33" height="12" />
            <rect className="cls-1" x="40" width="12" height="33" />
          </svg>
        </BottomLeft>
        <BottomRight>
          <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
            <rect className="cls-1" y="40" width="33" height="12" />
            <rect className="cls-1" x="40" width="12" height="33" />
          </svg>
        </BottomRight>
      </RegisterMark>
      <BottomNav className="section">
        <BottomNavHead>{t('front.navigation.legal')}</BottomNavHead>
        <BottomNavFoot>{t('front.navigation.notice')}</BottomNavFoot>
      </BottomNav>
    </>
  );
}

const MenuLinkStyle = `
  font-size: 13px;
  font-weight: bold;
  a {
    color: #FFFFFF;
  }
  a:hover {
    color: #FFFFFF;
    text-decoration: underline;
  }
`;

const BottomNav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0 0 0;
  > div {
    display: flex;
    margin: 0 0 40px 0;
    ${MenuLinkStyle};
  }
  &&& {
    padding: 0;
  }
`;

const BottomNavHead = styled.span`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const BottomNavFoot = styled.span`
  font-size: 14px;
  text-align: center;
`;

const BottomNavigation = withTranslation('translations')(BottomNavigationComponent);

const RegisterMark = styled.div`
  position: relative;
  div {
    position: absolute;
    width: 20px;
    height: 20px;
  }
  div svg {
    width: 20px;
    height: 20px;
  }
`;

const BottomLeft = styled.div`
  left: -20px;
  transform: rotate(-90deg);
  svg {
    fill: #333333;
  }
`;

const BottomRight = styled.div`
  right: -20px;
  transform: rotate(180deg);
  svg {
    fill: #55c3f0;
  }
`;

export { BottomNavigation };
