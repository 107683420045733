import React, { createRef, FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { LogoutButton } from '../login/LogoutButton';
import { LoginDropdown } from '../login/LoginDropdown';
import { GetLogin } from '../common/graphql';
import { TopArrow } from '../common/components/TopArrow';
import { ImageIcon } from '../common/components/ImageIcon';
import { UserActionLinks } from './UserActionLinks';
import { RoundedInitials } from '../components/roundedInitials/RoundedInitials';
import { useLoginForm } from '../hooks/useLoginForm';
import { Divider } from '../components/menu/components/BottomToast';
import useOutsideClick from '../hooks/useOutsideClick';
import closeImg from '../../assets/icons/close.svg';
import { useIsWindowWidthBelow } from '../hooks/useWindowSize';
import { MOBILE_SIZE } from '../common/constants';
import { useTranslation } from 'react-i18next';
const userIcon = require('../../assets/icons/user.svg');

interface UserActionsProps {
  loginData?: GetLogin;
  feature?: string;
  notifications?: number;
}

export const UserAction: FunctionComponent<UserActionsProps> = ({ feature, loginData }) => {
  const firstName = (loginData && loginData.login && loginData.login.firstName) || '';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const lastName = (loginData && loginData.login && loginData.login.lastName) || '';
  const { t } = useTranslation('sharedComponents');
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);
  const [displayMenuDropDown, setDisplayMenuDropDown] = useState(false);
  const dropDownReference = createRef<HTMLDivElement>();
  useOutsideClick(dropDownReference, () => setDisplayMenuDropDown(false));

  return (
    <>
      <MenuDropDownContainer ref={dropDownReference} onClick={() => setDisplayMenuDropDown(prevDisplayMenuDropDown => !prevDisplayMenuDropDown)}>
        <RoundedInitials loginData={loginData} />
        <MenuDropDown displayMenuDropDown={displayMenuDropDown} isMobile={isMobile}>
          {isMobile && (
            <>
              <TopContainer>
                <img src={closeImg} alt="close" />
              </TopContainer>
              <Greating>
                <RoundedInitials loginData={loginData} />
                <MenuName>{`${t('shared.navigation.hello')} ${firstName} !`}</MenuName>
              </Greating>
            </>
          )}
          <UserActionLinks loginData={loginData} />
          <Divider />
          <LogoutButton feature={feature} />
        </MenuDropDown>
      </MenuDropDownContainer>
      {isMobile && <MenuDropDownBlurWrapper displayMenuDropDown={displayMenuDropDown} />}
    </>
  );
};

export const LoginAction: FunctionComponent<UserActionsProps> = () => {
  const loginForm = useLoginForm();

  return (
    <HorizontalContainer>
      <ImageIcon src={userIcon} onClick={() => loginForm?.show()} />
      <VerticalContainer className="loginAction">
        <LoginDropdown />
      </VerticalContainer>
    </HorizontalContainer>
  );
};

const Greating = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 8px;
  height: 56px;
`;

const MenuName = styled.h2`
  @media (min-width: 1025px) {
    display: none;
  }
  text-align: left;
  font-weight: bold;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.userAction {
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &.loginAction > p {
    @media (max-width: 1024px) {
      display: none;
    }
  }
  & p {
    color: #000000;
    line-height: 15px;
  }
`;

const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 1024px) {
    cursor: pointer;
  }
`;

const MenuDropDownBlurWrapper = styled.div<{ displayMenuDropDown: boolean }>`
  display: none;
  ${({ displayMenuDropDown }) =>
    displayMenuDropDown &&
    `
    position: fixed;
    backdrop-filter: blur(3px);
    inset: 0px;
    display: block;
  `}
`;

export const MenuDropDown = styled.div<{ displayMenuDropDown: boolean; isMobile: boolean }>`
  &&& {
    border-radius: 8px;
    box-shadow: 0 8px 16px rgb(0 0 0 / 8%);
    box-sizing: border-box;
    z-index: 100;
    position: absolute;
    top: 42px;
    right: 0;
    width: 330px;
    background: white;
    display: none;

    ${({ displayMenuDropDown, isMobile }) =>
      isMobile &&
      `
      position: fixed;
      height: 115vh;
      top: 0;
      border-radius: 0;
      transition: right 0.8s ease;
      right: ${displayMenuDropDown ? '0' : '-400'}px;
      display: block;
    `}

    @media (min-width: 1024px) {
      ${({ displayMenuDropDown }) => `display: ${displayMenuDropDown ? 'block' : 'none'};`}
    }

    & a {
      color: #4a4a4a;
      text-align: left;
      margin: auto;
      padding: 8px 16px 8px 16px;
      display: block;
      font-size: 15px;
      text-decoration: none;

      &:hover {
        color: #4a4a4a;
        text-decoration: none;
        background-color: #f5f5f5;
      }
    }
    a:first-of-type {
      padding-top: 10px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    a:last-of-type {
      padding-bottom: 10px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    hr {
      background-color: #dbdbdb;
      border: none;
      display: block;
      height: 1px;
      margin: 0;
    }
  }
`;

export const MenuDropDownContainer = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 10;
`;

export const StyledTopArrow = styled(TopArrow)`
  &:after {
    border: 1px solid #fff;
  }
  @media (max-width: 1024px) {
    left: -10px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 52px;
  padding: 10px;

  img {
    height: 20px;
  }
`;
