import React, { FunctionComponent, RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';
import closeImg from '../../../../assets/icons/close.svg';
import { isLoggedIn, useGetLogin } from '../../../hooks/useLogin';
import { LogoutButton } from '../../../login/LogoutButton';
import { UserActionLinks } from '../../../navigation/UserActionLinks';
import { LoginForm } from '../../../login/ExaprintLoginForm';

interface Props {
  loginMenuVisible: boolean;
  closeLoginMenu: () => void;
}

export const MobileLoginMenu: FunctionComponent<Props> = ({ loginMenuVisible, closeLoginMenu }) => {
  const toastContainer: RefObject<HTMLDivElement> = React.createRef();
  const [toastHeight, setToastHeight] = useState(0);
  const useLoginResult = useGetLogin();
  const loggedIn = isLoggedIn(useLoginResult);

  useEffect(() => {
    const clientHeight = toastContainer.current?.clientHeight;
    setToastHeight(clientHeight || 0);
  }, [loggedIn, toastContainer]);

  return (
    <>
      <BottomToastContainer ref={toastContainer} toastHeight={toastHeight} opened={loginMenuVisible}>
        <TopContainer>
          <div></div>
          <img onClick={closeLoginMenu} src={closeImg} alt="" />
        </TopContainer>
        <ToastContent>
          {!loggedIn && <LoginForm />}
          {loggedIn && (
            <>
              <UserActionLinks loginData={useLoginResult.data} isMobile={true} />
              <Divider />
              <LogoutButton feature={'inknpaper'} />
            </>
          )}
        </ToastContent>
      </BottomToastContainer>
      <BottomToastOverlay onClick={closeLoginMenu} opened={loginMenuVisible} />
    </>
  );
};

const BottomToastOverlay = styled.div<{ opened: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.4;
  display: ${props => (props.opened ? 'block' : 'none')};
  z-index: 9999;
`;

const BottomToastContainer = styled.div<{ opened: boolean; toastHeight: number }>`
  position: fixed;
  height: 95%;
  right: 0;
  left: 0;
  bottom: ${props => (props.opened ? '50' : `-${props.toastHeight === 0 ? '600' : props.toastHeight}`)}px;
  background-color: white;
  border-top: 1px solid gray;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  z-index: 99999;

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 100%;

    li {
      font-size: 15px;
    }
  }
`;

export const Divider = styled.hr`
  background-color: #dbdbdb;
  border: none;
  display: block;
  height: 1px;
  margin: 0;
`;

const ToastContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  a {
    text-decoration: none;
    color: black;
    padding: 10px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  img {
    height: 25px;
  }
`;
